<div class="roadmap-landing" id="roadmap">
  <div class="container">
    <div class="row">
      <div class="col-12 my-5">
        <div class="titles">
          <h2>Road map</h2>
          <h3 class="text-center">Road map</h3>
        </div>
      </div>

      <div class="col-12">
        <img src="" alt="" />
      </div>
    </div>
  </div>

  <div class="container-fluid px-4 px-lg-3">
    <div class="row container-line g-0 mb-4 d-none d-xl-flex">
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
        <div class="point"></div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
        <div class="point"></div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
        <div class="point"></div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
        <div class="point"></div>
      </div>
    </div>
    <div class="row g-0">
      <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of data">
        <div class="step-item">
          <div class="step-item-description">
            <h4>{{ item.title }}</h4>

            <p>
              {{ item.text }}
            </p>
          </div>

          <div class="step-item-card {{ item.class }}">
            <div class="step-item-card-header">
              <div class="step-icon">
                <img src="{{ item.icon }}" alt="dolar" />
              </div>

              <h4 class="step-item-card-title">{{ item.year }}</h4>
            </div>

            <div class="step-item-card-text">
              {{ item.textTwo }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
