import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { BsModalService } from 'src/app/services/bs-modal.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, AfterViewInit {

  @Input() mId = "modalUserHistory";

  /** Documento de usuario a mostrar */
  public item: any;

  /** Instancia de la modal */
  private mi: any;
  
  constructor(
    private bsModalSrv: BsModalService,
    private _clipboardSrv: ClipboardService,
    private sweetAlert2Srv: Sweetalert2Service,
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.mi = this.bsModalSrv.buildModal(this.mId);
  }

  public showModal(item: any){
    console.log("item", item);
    this.item = item;
    this.mi.show();
  }

  public hide(){ 
    this.mi.hide();
    // this.item = null;
  }

  copy(id: string) {
    this._clipboardSrv.copy(id);
    this.sweetAlert2Srv.showToast('Copied to clipboard successfully', 'success');
  }

}
