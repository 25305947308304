<div class="row mt-5">
    <div class="col-12">
        <div class="title-r mb-2">
            Your referreals
        </div>
    </div>
</div>

<div class="row">
    <ng-container 
        *ngIf="(members$ | async) as list; else renderLoader" 
        [ngTemplateOutlet]="renderCheck" [ngTemplateOutletContext]="{ list: list }"
    ></ng-container>
</div>

<ng-template #renderList let-list="list">
    <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of list">
        <div class="c-referals mb-5">
            <div class="item-referals">
                <div class="header-r d-flex justify-content-between">
                    <div>{{item.createdAt | date: 'medium'}}</div>
                    <div class="label-t">{{(item.status) ? 'Active': 'Inactive'}}</div>
                </div>
                <div class="img-avatr">
                    <div class="avtar">
                        <img src="assets/img/avatar/avatar-1.png" alt="" />
                    </div>
                </div>
                <div class="c-wallet d-flex justify-content-between align-items-center mb-3">
                    <span class="fw-bold">{{item.addr | truncateWalletAddress}}</span>
                    <div class="icon" (click)="copy(item.addr)">
                        <i class="bi bi-files"></i>
                    </div>
                </div>
                <div class="text-info-r d-flex align-items-center mb-2">
                    <div class="pe-2">Code:</div>
                    <div class="label-txt px-3">{{item.nickname | uppercase}}</div>
                    <div class="flex-fill dec d-flex"></div>
                </div>
                <div class="text-info-r d-flex align-items-center">
                    <div class="pe-2">Sponsor:</div>
                    <div class="label-txt px-3">{{item.referredBy | uppercase}}</div>
                    <div class="flex-fill dec d-flex"></div>
                </div>
                <div class="d-flex w-100 button-c">
                    <button type="button" class="btn-three w-100 fw-bold" (click)="showHistory(item)">
                        Record
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderCheck let-list="list">
    <ng-container 
        *ngIf="(list.length > 0); else renderNoRecords" 
        [ngTemplateOutlet]="renderList" [ngTemplateOutletContext]="{ list: list }"
    ></ng-container>
</ng-template>

<ng-template #renderNoRecords>
    <div class="col-12">
        <div class="mb-5">
            <div class="item-referals">
                <div class="header-r d-flex justify-content-center">
                    <div>
                        No records found
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderLoader>
    <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of [1,1,1]">
        <div class="c-referals mb-5">
            <div class="item-referals">

                <div class="header-r d-flex justify-content-between">
                    <div class="placeholder-glow">
                        <span class="placeholder" style="width: 5rem;"></span>
                    </div>
                    <div class="label-t" style="width: 5rem;">
                        &nbsp;
                    </div>
                </div>

                <div class="img-avatr">
                    <div class="avtar">
                        <img src="assets/img/avatar/avatar-loader.png" alt="" />
                    </div>
                </div>

                <div class="c-wallet d-flex justify-content-between align-items-center mb-3">
                    <span class="fw-bold placeholder-glow">
                        <span class="placeholder" style="width: 10rem;"></span>
                    </span>
                    <div class="icon">
                        <i class="bi bi-files"></i>
                    </div>
                </div>

                <div class="text-info-r d-flex align-items-center mb-2">
                    <div class="pe-2 placeholder-glow">
                        <span class="placeholder" style="width: 5rem;"></span>
                    </div>
                    <div class="label-txt px-3 placeholder-glow">
                        <span class="placeholder" style="width: 5rem;"></span>
                    </div>
                    <div class="flex-fill dec d-flex"></div>
                </div>

                <div class="text-info-r d-flex align-items-center">
                    <div class="pe-2 placeholder-glow">
                        <span class="placeholder" style="width: 5rem;"></span>
                    </div>
                    <div class="label-txt px-3 placeholder-glow">
                        <span class="placeholder" style="width: 5rem;"></span>
                    </div>
                    <div class="flex-fill dec d-flex"></div>
                </div>

                <div class="d-flex w-100 button-c">
                    <button class="btn-three w-100 fw-bold placeholder-glow" disabled>
                        <span class="placeholder" style="width: 5rem;"></span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<app-history #modalUserHistory mId="modalUserHistoryByCustomFilter"></app-history>