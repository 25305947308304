import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, pipe, pluck } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Web3Service } from './contract/web3.service';
import { UserService } from './firebase/user.service';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public thumbLoader = 'assets/img/thumb-loader.png';

  public founderVIP = 'assets/img/founder-vip.png';

  public founderAmbassador = 'assets/img/founder-ambassador.png';

  public inicialPair: any = null;

  public initialPrice: any = null;

  public referedCode: any = null;

  public myCode: any = null;

  public starterList: any[] = [];

  // private list: any[] = [];

  public pair$ = new BehaviorSubject<any>(this.inicialPair);

  public priceToken$= new BehaviorSubject<any>(this.initialPrice);

  public referedCode$= new BehaviorSubject<string>(this.referedCode);

  public stakesList$= new BehaviorSubject<any>(this.starterList);

  public myCode$= new BehaviorSubject<any>(this.myCode);

  public commisions: number = 0;

  public users: number = 0;
  
  // private list$= new BehaviorSubject<any>(this.list);


  decimalList = [
    {
      pairId: 1,
      decimal: 18
    }, {
      pairId: 2,
      decimal: 18
    }, {
      pairId: 3,
      decimal: 18
    },
  ];


  /// provicional ///@dev  smartContract1 hace referencia a la moneda que usuario va a dar
  /// y smartContract2 hace referencia a la moneda que el swap debolvera
  // tokenList = [
  //   {
  //     name: "BUSD",
  //     token1: "BUSD",
  //     smartContract: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false
  //   },
  //   {
  //     name: "USDT",
  //     token1: "USDT",
  //     smartContract: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false
  //   },
  //   {
  //     name: "MITT",
  //     token1: "MITT",
  //     smartContract: '0x723Ff87F901ded88CbA22cc521a7e3f1FDd8c5F8',
  //     isNative: false
  //   }
  // ];

  // pairListProv = [
  //   {
  //     name: "BNB to MITT",
  //     nameToken: "",
  //     token1: "BNB",
  //     token2: "MITT",
  //     smartContract: '0x000000000000000000000000000000000000dead',
  //     isNative: true,
  //     valueOrc: 0,
  //     type: 0,
  //     imgToken1: "assets/img/bnb.png",
  //     imgToken2: "assets/img/mitt.gif",
  //     tokenDmc: 18,
  //   },
  //   {
  //     name: "BUSD to MITT",
  //     token1: "BUSD",
  //     token2: "MITT",
  //     smartContract: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false,
  //     valueOrc: 1,
  //     type: 1,
  //     imgToken1: "assets/img/busd.png",
  //     imgToken2: "assets/img/mitt.gif",
  //     tokenDmc: 18,
  //   },
  //   {
  //     name: "USDT to MITT",
  //     token1: "USDT",
  //     token2: "MITT",
  //     smartContract: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false,
  //     valueOrc: 2,
  //     type: 2,
  //     imgToken1: "assets/img/usdt.png",
  //     imgToken2: "assets/img/mitt.gif",
  //     tokenDmc: 18,
  //   },
  //   {
  //     name: "MITT to BNB",
  //     token1: "MITT",
  //     token2: "BNB",
  //     smartContract: '0x723Ff87F901ded88CbA22cc521a7e3f1FDd8c5F8',
  //     oracle: '0x000000000000000000000000000000000000dead',
  //     isNative: false,
  //     valueOrc: 0,
  //     type: 3,
  //     imgToken1: "assets/img/mitt.gif",
  //     imgToken2: "assets/img/bnb.png",
  //     tokenDmc: 18,
  //   },
  //   {
  //     name: "MITT to BUSD",
  //     token1: "MITT",
  //     token2: "BUSD",
  //     smartContract: '0x723Ff87F901ded88CbA22cc521a7e3f1FDd8c5F8',
  //     oracle: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false,
  //     valueOrc: 1,
  //     type: 4,
  //     imgToken1: "assets/img/mitt.gif",
  //     imgToken2: "assets/img/busd.png",
  //     tokenDmc: 18,
  //   },
  //   {
  //     name: "MITT to USDT",
  //     token1: "MITT",
  //     token2: "USDT",
  //     smartContract: '0x723Ff87F901ded88CbA22cc521a7e3f1FDd8c5F8',
  //     oracle: '0xa07dF101D291386453953a613ABf861F7BCB5D02',
  //     isNative: false,
  //     valueOrc: 2,
  //     type: 6,
  //     imgToken1: "assets/img/mitt.gif",
  //     imgToken2: "assets/img/usdt.png",
  //     tokenDmc: 18,
  //   },
  // ];

  tokenList = environment.tokenList;
  pairListProv = environment.pairListProv;
  baseUrlCode = environment.urlWeb;



  constructor(
    private web3Srv: Web3Service,
    private userService: UserService,
  ) { }


  get selectedPair$():Observable<any>{
    return this.pair$.asObservable();
  }

  get actualPrice$():Observable<any>{
    return this.priceToken$.asObservable();
  }

  // get getList$():Observable<any>{
  //   return this.list$.asObservable();
  // }


  setPair(obj: any){
    this.pair$.next(obj);
  }

  setPrice(price: any){
    this.priceToken$.next(price);
  }

  setMyCode(account: string){
    this.userService.getReferredByWalletPromise(account).then(respose => this.myCode$.next(respose));
  }

  setMyCodeOnRegister(code: string, referred: string){
  const myCode = {
    myCode : code,
    referredBy : referred
  };
  this.myCode$.next(myCode);
  }



 setStakeList() {
  this.stakesList$.next(from(this.web3Srv.stake_hasStakeTokenstake())
  .pipe(
    pluck('stakes'),
    map((data: any[]) => data.map((row, index) => ({ ...row, stakeId: index}))),
    catchError((err) => {
      return of([]);
    })
  ))
 }

 get actualStakigns$():Observable<any>{
  return this.stakesList$.asObservable();
}




  /**
   * 
   * @param name 
   * @returns 
   */
  getParameterByName(name: string) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  /**
   * @name getAddress
   * @param address 
   * @returns 
   */
  getAddress(address: string) {
    if (!address) { return "" }
    return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length)
  }


  /**
   * 
   * @param sourceString 
   * @returns 
   */
  removeSpecialCharacters(sourceString: string) {
    return sourceString
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      .replace(/\s/g, '')
      .toLocaleLowerCase();
  }


  /**
   * 
   * @param name 
   * @returns 
   */
  getPrimeraLetra(name: string) {
    if (!name) { return "" }
    return name.charAt(0).toUpperCase()

  }

  get inCodeReferred$():Observable<any>{
    return this.referedCode$.asObservable();
  }

  get getMyCode$():Observable<any>{
    return this.myCode$.asObservable();
  }

  saveReferedCode(code: any) {
      this.referedCode$.next(code);
  }

  setComision(commision: number){
    this.commisions = commision;
  }

  getComisions(){
   return this.commisions;
  }

  setUsers(users: number){
    this.users = users;
  }

  getUsers(){
    return this.users;
  }

}
