<app-sidebar-dashboard></app-sidebar-dashboard>

<div class="section-template">
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">

          <div class="section section-dashboard">
            <div class="container-fluid">

              <app-navbar-dashboard></app-navbar-dashboard>

              <ng-container 
                *ngIf="(dataStatus$ | async) as data; else noConnected"
                [ngTemplateOutlet]="renderCheckRules" [ngTemplateOutletContext]="{data: data}"
              ></ng-container>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderContent>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #noStaff>
  <div class="row">
    <div class="col-12 d-flex">
      <div class="content-noConnected">
        <div class="card-dashboard flex-column">
          <div class="title fw-bold mb-3">
            {{ "home-dashboard.admin-dash.staff" | translate }}
          </div>
          <button class="btn-three fw-bold p-3 px-5">
            {{ "home-dashboard.admin-dash.staff" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderCheckRules let-data="data">
  <ng-container 
    *ngIf="(data.isAdmin || data.isUser); else noStaff"
    [ngTemplateOutlet]="renderContent" [ngTemplateOutletContext]="{data: data}"
  ></ng-container>
</ng-template>

<ng-template #noConnected>
  <div class="row">
    <div class="col-12 d-flex">
      <div class="content-noConnected">
        <div class="card-dashboard flex-column" (click)="launch()">
          <div class="title fw-bold mb-3">
            {{ "home-dashboard.admin-dash.connect" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>