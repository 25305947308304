<div class="card-custom-dash">
    <!-- <div class="i-title color-four font-bold text-center mb-3">
        TOKEN REMIT
    </div>
    <div class="d-flex justify-content-center">
        <div class="i-icon-2 d-flex justify-content-center align-items-center">

        </div>
    </div> -->
    <div class="i-card mt-3 text-center">
        <div class="i-title fs-3 color-four font-bold">
            Disponible
        </div>
        <div class="i-valor fs-1 color-four font-black">
            {{myItem.amount | web3Utils:'fromWei'}}
        </div>
        <div class="color-four">
            Cantidad a Retirar
        </div>
        <div class="my-3">
            <form [formGroup]="form" novalidate>
                <input type="number" class="form-control" formControlName="amount" placeholder="0 MITT" aria-describedby="amountHelp">
                
                <ng-container *ngIf="submit">
                    <ng-container *ngFor="let vm of vm.amount">
                        <div id="amountHelp" class="form-text text-danger"
                            *ngIf="f['amount'].hasError(vm.type)">
                            {{ vm.message }}
                        </div>
                    </ng-container>
                </ng-container>
            </form>
        </div>
        <div>
            <button class="btn-one" (click)="onSubmit()">Reclamar</button>
        </div>
    </div>
</div>
