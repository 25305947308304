<div class="header-dash">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <!-- Logo con animación -->
      <div class="d-xl-none">
        <ng-lottie class="animation-logo-h logo-hw" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
        <ng-lottie class="animation-logo-h logo-hc" [options]="options1" (animationCreated)="animationCreated($event)"></ng-lottie>
      </div>
    </div>

    <!-- Widgets -->
    <div class="flex-fill  d-none d-xl-block">
      <app-header-tb [account]="account"></app-header-tb>
    </div>


    <div class="d-flex align-items-center">
  
      

      <!-- Precio del token principal -->
      <div class="price">
        <span class="fw-bold">
          {{"home-dashboard.public-layout.price-token" | translate}}
        </span>
        {{ (mainTokenPrice$ | async) | number: '1.2-2' }} USD
      </div>

      <!-- Botón de billetera -->
      <div class="wallet d-flex align-items-center d-none d-xl-block" (click)="logOut()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.04" height="15.776"
          viewBox="0 0 19.04 15.776">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_381" data-name="Rectangle 381" width="19.04" height="15.776" fill="none" />
            </clipPath>
          </defs>
          <g id="Group_955" data-name="Group 955" transform="translate(-1712.47 -51.296)">
            <g id="Group_954" data-name="Group 954" transform="translate(1712.47 51.296)">
              <g id="Group_953" data-name="Group 953" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Path_402" data-name="Path 402"
                  d="M15.548,14.914H3.127A2.628,2.628,0,0,1,.5,12.284V.5H15.55a2.628,2.628,0,0,1,2.629,2.629v9.155a2.628,2.628,0,0,1-2.627,2.63Z"
                  transform="translate(0.181 0.181)" fill="none" stroke="#fff" stroke-width="1" />
                <path id="Path_403" data-name="Path 403"
                  d="M11.562,6.32A1.986,1.986,0,1,1,9.576,4.334,1.986,1.986,0,0,1,11.562,6.32Z"
                  transform="translate(2.747 1.569)" fill="none" stroke="#fff" stroke-width="1" />
              </g>
            </g>
          </g>
        </svg>
      
        <span class="ms-2">
          <ng-container *ngIf="(account); else renderSpinner">
            {{ account | truncateWalletAddress }}
          </ng-container>
        </span>
      </div>
      
      <!-- Switch de idiomas -->
      <div class="btn-languaje languaje" id="languaje">
        <div [ngClass]="{active: currentLanguage == 'es'}" class="option" (click)="changeLanguage('en')">
          <img src="assets/img/languaje-es.png" alt="">
        </div>
        <div [ngClass]="{active: currentLanguage == 'en'}" class="option" (click)="changeLanguage('es')">
          <img src="assets/img/languaje-en.png" alt="">
        </div>
      </div>
      <div class="m-color d-flex d-xl-none justify-content-center align-items-center">
        <app-mode-color></app-mode-color>
      </div>

      <!-- Avatar -->
      <div class="avatar ms-2 ms-xxl-3">
        <img src="assets/img/icon-avatar.png" alt="" />
      </div> 

   
    </div>
    

    

  </div>

</div>

<ng-template #renderSpinner>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span class="visually-hidden">Loading...</span>
</ng-template>