<div class="modal fade modalRef" id="modalRef2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="modal-body">
        <div class="item-referals pb-3">
          <ng-container *ngIf="obj as item">
            <div class="header-r d-flex justify-content-between">
              <div>{{item.timestamp | web3Utils : "dateFromBlock"}}</div>
              <div class="label-t">{{ item.active }}</div>
            </div>
            <div class="img-avatr">
              <div class="avtar">
                <img src="assets/img/avatar/avatar-1.png" alt="" />
              </div>
            </div>
            <div class="c-wallet d-flex justify-content-between align-items-center mb-3">
              <span class="fw-bold">{{ item._id | truncateWalletAddress }}</span>
              <div class="icon" (click)="copy(item._id)">
                <i class="bi bi-files"></i>
              </div>

            </div>
            <div class="text-info-r d-flex align-items-center mb-2">
              <div class="pe-2" [innerHTML]="'home-dashboard.referrals.code' | translate">
                Code:
              </div>
              <div class="label-txt px-3">{{ item.myCode }}</div>
              <div class="flex-fill dec d-flex"></div>
            </div>
            <div class="text-info-r d-flex align-items-center">
              <div class="pe-2" [innerHTML]="'home-dashboard.team.sponsor' | translate">Sponsor:</div>
              <div class="label-txt px-3">{{ item.referredBy }}</div>
              <div class="flex-fill dec d-flex"></div>
            </div>
            <div class="c-amount">
              <div class="label">
                {{ "home-dashboard.history.amount" | translate}}
              </div>

              <ng-container *ngIf="(codeBalance$ | async) as amount; else noPurchases">
                <div class="fs-1 fw-bold">${{ amount }}</div>
              </ng-container>

              <!-- <ng-container *ngIf="amount as amount">
              <ng-container *ngIf="amount >= 0; else noPurchases">
                <div class="fs-1 fw-bold">${{ amount }}</div>
              </ng-container>
            </ng-container> -->

            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchases>
  <div class="fs-1 fw-bold">$0</div>
</ng-template>