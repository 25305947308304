// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  
  API_KEY_BSC: "4S2UBP9NC7GS85BUV4HIJ76HNV73AY8YNH",

  firebase: {
    projectId: "remitt-5fd4c",
    appId: "1:1037443479790:web:0eab9bc16c360d88759bff",
    storageBucket: "remitt-5fd4c.appspot.com",
    apiKey: "AIzaSyDplB-EyErgCIyBwhx6J5IC5EAxIX9Q0eM",
    authDomain: "remitt-5fd4c.firebaseapp.com",
    messagingSenderId: "1037443479790",
    measurementId: "G-Z51GZFBWTQ",
  },

  production: false,
  projectName: "REMITT",
  API_URL: "https://2-dot-remitt-5fd4c.uc.r.appspot.com/",
  // API_URL: "http://localhost:4000",
  urlWeb: "https://dev.remitt.io",
  configUrlAbi: "/assets/abi/erc721s.json",
  contract: {
    swapUser: "0xdBc3A1CC7Ff362eA691632D30177969f0cA1035b",
    swapSaleDistribution: "0x806A4077e5Ef69a90d3EF2257d0f78095239ed80",
    swapPartnerDistribution: "0x72385836618Ac7c44944134003c640AF53B00532",
    swapNew: "0x3A0f49f6beA4Cc0c1404D3c90934526791fCe753",
    addressdead: "0x000000000000000000000000000000000000dEaD",
    swap: "0xB16914fA789AEee61459E426016D0A7d9D8C969E",
    // stake: "0x85908281b881cd7Ad5Da74489b1751755488478D",
    // stake: "0x51A363B2e6B93D1bfA42476e007dEFc9daB91cCC",
    stake: "0xE11846887414bE621ce078b657153416da87caC8",
    partnerDistribution: "0x14392729f86400f8257288d661760aea6729e595",
  },
  
  urlTokenLogo: "#",
  prefix: "dev",
  GENESIS_CODE: "code0",
  mainToken: {
    // contract: "0x794176AE28F6619C9c0b8353D7727124be7D1759",
    // contract: "0x51457A73d97e9CD53e33f370511c43DE2561D5AA",
    contract: "0x2f8300c87f4dd273ce24919ddee7d698e5b196f6",
    name: "REMITT",
    symbol: "MITT",
    decimals: 18,
  },
  chain: {
    walletConnectID: "dd1386ecf49de3a4481dd7d8d1f976cf",

    infuraId: "",

    // Testnet
    chainId: 97,
    chainIdMetamask: "0x61",
    chainName: "BNB Smart Chain Testnet",
    rpc: "https://data-seed-prebsc-1-s3.binance.org:8545",
    rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],

     // Testnet mumbai
    // chainId: 80001,
    // chainIdMetamask: "0x13881",
    // chainName: "Mumbai poligon",
    // rpc: "https://rpc-mumbai.maticvigil.com",
    // rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    // blockExplorerUrls: ["https://mumbai.polygonscan.com/"],

    // Mainnnet
    // chainId: 56,
    // chainIdMetamask: "0X38",
    // chainName: "BNB Smart Chain Mainnet",
    // rpc: "https://rpc-bsc.bnb48.club/",
    // rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
    // blockExplorerUrls: ["https://bscscan.com/"],
    // scan: "https://bscscan.com/tx/",
    // scanNft: "https://bscscan.com/token/",

    nativeCurrency: {
      web3ModalNetwork: "BNB Smart Chain Testnet",
      network: "BNB Smart Chain Testnet",
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
  },
  /// provicional ///@dev  smartContract1 hace referencia a la moneda que usuario va a dar
  /// y smartContract2 hace referencia a la moneda que el swap debolvera
  tokenList: [
    {
      name: "BUSD",
      token1: "BUSD",
      smartContract: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
    },
    {
      name: "USDT",
      token1: "USDT",
      smartContract: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
    },
    {
      name: "MITT",
      token1: "MITT",
      smartContract: "0x794176AE28F6619C9c0b8353D7727124be7D1759",
      isNative: false,
    },
  ],

  pairListProv: [
    {
      name: "BNB to MITT",
      nameToken: "",
      token1: "BNB",
      token2: "MITT",
      smartContract: "0x000000000000000000000000000000000000dEaD",
      isNative: true,
      valueOrc: 0,
      type: 0,
      imgToken1: "assets/img/bnb.png",
      imgToken2: "assets/img/mitt.gif",
      tokenDmc: 18,
    },
    {
      name: "BUSD to MITT",
      token1: "BUSD",
      token2: "MITT",
      smartContract: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
      valueOrc: 1,
      type: 1,
      imgToken1: "assets/img/busd.png",
      imgToken2: "assets/img/mitt.gif",
      tokenDmc: 18,
    },
    {
      name: "USDT to MITT",
      token1: "USDT",
      token2: "MITT",
      smartContract: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
      valueOrc: 2,
      type: 2,
      imgToken1: "assets/img/usdt.png",
      imgToken2: "assets/img/mitt.gif",
      tokenDmc: 18,
    },
    {
      name: "MITT to BNB",
      token1: "MITT",
      token2: "BNB",
      smartContract: "0x794176AE28F6619C9c0b8353D7727124be7D1759",
      oracle: "0x000000000000000000000000000000000000dEaD",
      isNative: false,
      valueOrc: 0,
      type: 3,
      imgToken1: "assets/img/mitt.gif",
      imgToken2: "assets/img/bnb.png",
      tokenDmc: 18,
    },
    {
      name: "MITT to BUSD",
      token1: "MITT",
      token2: "BUSD",
      smartContract: "0x794176AE28F6619C9c0b8353D7727124be7D1759",
      oracle: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
      valueOrc: 1,
      type: 4,
      imgToken1: "assets/img/mitt.gif",
      imgToken2: "assets/img/busd.png",
      tokenDmc: 18,
    },
    {
      name: "MITT to USDT",
      token1: "MITT",
      token2: "USDT",
      smartContract: "0x794176AE28F6619C9c0b8353D7727124be7D1759",
      oracle: "0x457fB0988fd16357D330151bccA4b1083e3F259E",
      isNative: false,
      valueOrc: 2,
      type: 6,
      imgToken1: "assets/img/mitt.gif",
      imgToken2: "assets/img/usdt.png",
      tokenDmc: 18,
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
