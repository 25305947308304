import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-view-card',
  templateUrl: './loading-view-card.component.html',
  styleUrls: ['./loading-view-card.component.css']
})
export class LoadingViewCardComponent {

}
