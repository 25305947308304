import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportService } from 'src/app/services/api/report.service';

@Component({
  selector: 'app-button-download-access-roles',
  templateUrl: './button-download-access-roles.component.html',
  styleUrls: ['./button-download-access-roles.component.css']
})
export class ButtonDownloadAccessRolesComponent implements OnInit, OnChanges {

  @Input() contract: string = '';

  constructor(
    private reportSrv: ReportService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { contract } = changes;

    if (contract && contract.currentValue) { this.contract = contract.currentValue; }
  }

  async downloadCSV() {
    await this.reportSrv.getContractAccessRoles(this.contract);
  }

}
