import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-landing',
  templateUrl: './products-landing.component.html',
  styleUrls: ['./products-landing.component.css']
})
export class ProductsLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
