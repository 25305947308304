<div class="start-landing">
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center"
      >
        <h3>Start in seconds.</h3>
        <p>Connect your cripto wallet tot start using the app in seconds.</p>
        <p>no registration needed.</p>
        <button class="btn-white">Connect Wallet</button>
      </div>
    </div>
  </div>
</div>
