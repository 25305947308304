
/**
 * @dev Read the language of the browser
 * @param dft               Default language
 * @returns                 Browser language
 */
export function readNavegatorLanguage(dft = 'en-US'): any {
    const browserLanguage = navigator.language || dft;
    const language = browserLanguage.split('-').shift();
    return language;
}