import { Component, OnInit } from '@angular/core';
import { Web3Service } from 'src/app/services/contract/web3.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  public referredLvl1: any[] = [];
  public referredLvl2: any[] = [];
  public referredLvl3: any[] = [];
  public referredLvl4: any[] = [];
  public referredLvl5: any[] = [];
  public referredLvl6: any[] = [];

  constructor(
    private web3Srv: Web3Service,
  ) { }

  ngOnInit(): void {
    this.getListRefered();
    console.log("nivel1", this.referredLvl1);
    console.log("nivel2", this.referredLvl2);
    console.log("nivel3", this.referredLvl3);
    console.log("nivel4", this.referredLvl4);
    console.log("nivel5", this.referredLvl5);
    console.log("nivel6", this.referredLvl6);
  }
  async getListRefered () {
    const count = await this.web3Srv.swap_listUsersCount();
    await this.getListOfAllUsers(count);
  }

  async getListOfAllUsers(total: number) {
    const users = await this.web3Srv.swap_getUserListPaginated(0, total);
    const leve1 = this.getReferred1(users, 'code0');
    
  }

  getReferred1(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl1.push({
        active: level[index].active,
        index: level[index].index,
        myCode: level[index].myCode,
        referredBy: level[index].referredBy,
        userReferrer: level[index].userReferrer,
        level: 1
      });
      this.getReferred2(users, level[index].myCode)
    }
  }

  getReferred2(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl2.push(
        {
          active: level[index].active,
          index: level[index].index,
          myCode: level[index].myCode,
          referredBy: level[index].referredBy,
          userReferrer: level[index].userReferrer,
          level: 2
        }
      );
      this.getReferred3(users, level[index].myCode);
    }
  }

  getReferred3(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl3.push(
        {
          active: level[index].active,
          index: level[index].index,
          myCode: level[index].myCode,
          referredBy: level[index].referredBy,
          userReferrer: level[index].userReferrer,
          level: 3
        }
      );
      this.getReferred4(users, level[index].myCode);
    }
  }

  getReferred4(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl4.push(
        {
          active: level[index].active,
          index: level[index].index,
          myCode: level[index].myCode,
          referredBy: level[index].referredBy,
          userReferrer: level[index].userReferrer,
          level: 4
        }
      );
      this.getReferred5(users, level[index].myCode);
    }


  }

  getReferred5(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl5.push(
        {
          active: level[index].active,
          index: level[index].index,
          myCode: level[index].myCode,
          referredBy: level[index].referredBy,
          userReferrer: level[index].userReferrer,
          level: 5
        }
      );
      this.getReferred6(users, level[index].myCode);
    }

    
  }

  getReferred6(users: any, code: string) {
    const level = users.filter(user => user.referredBy == code);
    for (let index = 0; index < level.length; index++) {
      this.referredLvl6.push(
        {
          active: level[index].active,
          index: level[index].index,
          myCode: level[index].myCode,
          referredBy: level[index].referredBy,
          userReferrer: level[index].userReferrer,
          level: 6
        }
      );
    }

    
  }

}
