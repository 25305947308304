import { Pipe, PipeTransform } from '@angular/core';
import { UsersManagementService } from '../services/firebase/users-management.service';


@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  private users: any[] = [];
  constructor(
    private usersManagementService: UsersManagementService,
  ){}

  transform(value: any, arg: any): any {
    this.users = this.usersManagementService.getAllUsers();
   
    const collectionResult: any[] = [];
    
    for (const itemTovalidate of this.users){
      if(itemTovalidate.seedUpArr.includes(arg)){
        if(itemTovalidate.myCode.toLowerCase().indexOf(value.toLowerCase()) > -1){
          collectionResult.push(itemTovalidate)
        }
        if(itemTovalidate._id.toLowerCase().indexOf(value.toLowerCase()) > -1){
          collectionResult.push(itemTovalidate)
        }
      }
    }
    return collectionResult;
  }

}

