<div class="item-stakelist">

    <div class="header-is d-flex justify-content-between align-items-center">

        <div class="text-end d-flex aling-items-center lh-1">
            <span class="title fw-bold">
                REMITT
                <br>
                <span class="text-small">
                    {{"Stake and Earn" | translate}}
                </span>
            </span>
        </div>
        <div>
            <span class="h1">
                {{ days }}
            </span>
        </div>

        <div class="icon-l">
            <div class="logo logo-white align-items-center">
                <app-lottie-theme-logo theme="light"></app-lottie-theme-logo>
            </div>
            <div class="logo logo-dark align-items-center">
                <app-lottie-theme-logo theme="dark"></app-lottie-theme-logo>
            </div>
        </div>

    </div>

    <div class="body-is">
        <div class="text-center fw-bold">APY</div>
        <div class="labelPercent">
            {{ rewardRate | number:'1.0-2' }}%
        </div>
        <div class="text-small mt-3">
            <div class="d-flex justify-content-between">
                <div class="fw-bold">
                    {{"Total Stake"| translate}}:
                </div>
                <div>
                    {{ totalStake | number:'1.0-2' }}
                </div>
            </div>
    
            <div class="d-flex justify-content-between">
                <div class="fw-bold">
                    {{"home-dashboard.stake-card.stakeTo" | translate}}:
                </div>
                <div>
                    {{ days }} {{"home-dashboard.stake-card.day" | translate}}
                </div>
            </div>
    
            <div class="d-flex justify-content-between">
                <div class="fw-bold">
                    {{"profit" | translate | titlecase}}:
                </div>
                <div>
                    {{profit}}
                </div>
            </div>
    
            <div class="d-flex mt-3 align-items-center justify-content-between">
                <div class="add-metamask" (click)="addMainToken()">
                    <img src="assets/img/MetaMask.png" alt=""> {{"Add on Metamask" | translate}}
                </div>
    
                <ng-content select="[formInput]"></ng-content>
            </div>
    
            <ng-content select="[formVM]"></ng-content>
    
        </div>
        <div class="sprd-neon"></div>

        <div class="text-small">
            STAKE <span class="fw-bold">MITT</span>
        </div>

        <div class="text-small">
            <span class="fw-bold">Balance:</span> {{currentBalance | customNumber:2}} {{mainTokenName | uppercase}}
        </div>

        <ng-content select="[action]"></ng-content>
    </div>

  

    

</div>