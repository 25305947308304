<div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalStakeAddLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header flex-column">
        <div class="" id="modalStakeAddLabel">
          <div class="modal-title font-bold">
            {{ "home-dashboard.register-modal.registrate" | translate }}
          </div>  
        </div>
        <div class="text-center text my-3">{{ "home-dashboard.register-modal.text" | translate }}</div>
        <button type="button" class="btn-close" *ngIf="showCloseButton" (click)="hide()"></button>
      </div>

      <div class="modal-body">
        <div class="mb-3">

          

          <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

            <!-- Nickname Input -->
            <div class="campo-m mb-3">
              <input type="text" formControlName="code" class="form-control" placeholder="{{'home-dashboard.refers.code' | translate}}" />
            </div>
            <ng-container [ngTemplateOutlet]="renderFormVM" [ngTemplateOutletContext]="{field: 'code'}"></ng-container>

            <!-- Referred Code Input -->
            <div class="campo-m mb-3" *ngIf="f['referedBy'].value != GENESIS_CODE">
              <label class="mb-2">{{ "home-dashboard.register-modal.codigo-referido" | translate }}</label>
              <input type="text" class="form-control" formControlName="referedBy" class="form-control" placeholder="Referred by code" readonly/>
            </div>

            <!-- User Wallet Input -->
            <div class="campo-m mb-3 d-flex align-items-center">
              <label class="px-2">{{ "home-dashboard.register-modal.wallet" | translate }}</label>
              <input type="text" class="form-control" formControlName="wallet" placeholder="Wallet Address" readonly/>
            </div>
            <ng-container [ngTemplateOutlet]="renderFormVM" [ngTemplateOutletContext]="{field: 'wallet'}"></ng-container>

            <!-- Submit Button -->
            <div class="d-flex justify-content-center mt-4">
              <ng-container *ngIf="(showSubmitLoaderButton); then renderSubmitLoaderButton; else renderSubmitButton"></ng-container>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderSubmitButton>
  <button type="submit" class="btn-three fw-bold px-5">
    {{ "home-dashboard.register-modal.registrarse" | translate }}
  </button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
  <button type="button" class="btn-three fw-bold px-5" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{"Loading" | translate}}...
  </button>
</ng-template>

<ng-template #renderFormVM let-field="field">
  <ng-container *ngIf="submitted">
    <ng-container *ngFor="let item of vm[field]">
      <div class="form-text text-danger mb-2" *ngIf="f[field].hasError(item.type)">
        {{item.message | translate}}
      </div>
    </ng-container>
  </ng-container>
</ng-template>