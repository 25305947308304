import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MetamaskAddTokenService } from 'src/app/services/metamask-add-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-stake-option-item',
  templateUrl: './card-stake-option-item.component.html',
  styleUrls: ['./card-stake-option-item.component.css']
})
export class CardStakeOptionItemComponent implements OnInit, OnChanges {

  @Input() days: number = 0;

  @Input() rewardRate: number = 0;

  @Input() totalStake: number = 0;

  @Input() currentBalance: number = 0;

  @Input() mainTokenName: string = '';

  @Input() profit: number = 0;

  public mainToken = environment.mainToken;

  constructor(
    private metamaskAddTokenSrv: MetamaskAddTokenService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { days, rewardRate, totalStake, profit } = changes;

    if (days && days.currentValue) { this.days = days.currentValue; }

    if (rewardRate && rewardRate.currentValue) { this.rewardRate = rewardRate.currentValue; }

    if (totalStake && totalStake.currentValue) { this.totalStake = totalStake.currentValue; }

    if (profit && profit.currentValue) { this.profit = profit.currentValue; }
  }

  async addMainToken() {
    try {
      await this.metamaskAddTokenSrv.addToken(
        this.mainToken.contract,
        this.mainToken.symbol,
        this.mainToken.decimals,
        '#',
      );
    } catch (err) {
      console.log('Error on CardStakeOptionItemComponent.addMainToken()', err);
      throw err;
    }
  }

}
