import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';

@Injectable({
  providedIn: 'root',
})
export class Sweetalert2Service {
  
  public title = environment.projectName;

  constructor(
    private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService
  ) { }


  showError(err: string) {
    return Swal.fire(this.title, err, 'error');
  }

  showSuccess(message: any) {
    return Swal.fire(this.title, message, 'success');
  }

  showInfo(message: any) {
    return Swal.fire(this.title, message, 'info');
  }

  showWarning(message: any) {
    return Swal.fire(this.title, message, 'warning');
  }


  /**
   *
   * @param err
   */
  showErrorMetaMask(err: string,) {
    try {
      let mess = JSON.parse(err.substring(err.search('{'), err.length)).message
      console.log('mess', mess)
      Swal.fire(this.title, mess, 'error');
    } catch (error) {
      console.log('error', error);
      Swal.fire(this.title, 'error', 'error');
    }
  }

  /**
   *
   * @param err
   * @param type
   * @returns
   */
  showQuestion(err: any, type: any) {
    try {
      let mess;
      if (err.message) {
        mess = err.message;
      } else {
        mess =
          type == 1
            ? JSON.parse(err.substring(err.search('{'), err.length)).message
            : err;
      }
      Swal.fire(this.title, mess, 'error');
      return;
    } catch (error) {
      console.log('error', error);
      Swal.fire(this.title, 'error', 'error');
    }
  }



  /**
   *
   * @param message
   * @returns
   */
  async askConfirm(message: string) {
    const messageYes = await this.translatePipe.transform('home-dashboard.global.yes');
    const messageNo =  await this.translatePipe.transform('home-dashboard.global.no');

    const { isConfirmed } = await Swal.fire({
      icon: 'info',
      title: this.title,
      text: message,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: messageYes,
      cancelButtonText: messageNo,
    });

    return isConfirmed;
  }

  /**
   * Launche alert like toast
   * @param message
   * @param type
   * @returns
   */
  public showToast(message: string, type: any = 'success') {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
      customClass: {
        htmlContainer: 'applef sw2FixHtmlContainer',
        icon: 'sw2FixIcon',
      },
    });

    return Toast.fire({ icon: 'success', title: message });
  }

  async showAlertWithTxHash(opts: AlertWithTxHashParams) {
    const {
      seeTransaction = 'See transaction',
      transactionHash,
      icon = 'success',
      confirmButtonText = 'OK',
    } = opts;

    return await Swal.fire({
      title: this.title,
      icon: icon,
      html:
        "<a style='color: #e5e61d !important;' href='" +
        environment.chain.blockExplorerUrls +
        'tx/' +
        transactionHash +
        "' target='_blank'>" + seeTransaction + "</a>",
      confirmButtonText: confirmButtonText,
    });
  }

}

export interface AlertWithTxHashParams {
  seeTransaction?: string;
  transactionHash: string;
  icon?: any;
  confirmButtonText?: string;
}