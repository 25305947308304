<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-5">
        <div class="logo-container">
          <div class="logos">
            <img class="img-fluid" src="assets/img/logo-white-l.png" alt="" />
            <ng-lottie
              class="animation-logo"
              [options]="options"
              (animationCreated)="animationCreated($event)"
            ></ng-lottie>
          </div>
          <p>
            Lorem ipsum dolor sit, amet adipisicing elit. Nulla, deleniti dicta
            sed modi alias numquam eaque odio cupiditate omnis.
          </p>
          <div class="redes-social">
            <ul>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-facebook.png"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-telegram.png"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-twitter.png"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-instagram.png"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-linkedin.png"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-youtube.png"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    class="img-fluid"
                    src="assets/img/icon-tiktok.png"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 col-lg-7 mt-sm-5 mt-0">
        <div class="row">
          <div
            class="col-12 col-xs-6 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center flex-column align-items-center"
          >
            <h4>About</h4>
            <ul class="list-item">
              <li>About</li>
              <li>Sotfware</li>
              <li>Story</li>
            </ul>
          </div>

          <div
            class="col-12 col-xs-6 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center flex-column align-items-center"
          >
            <h4>Remitt</h4>
            <ul class="list-item">
              <li>About</li>
              <li>Sotfware</li>
              <li>Story</li>
            </ul>
          </div>
          <div
            class="col-12 col-xs-6 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center flex-column align-items-center"
          >
            <h4>Remitt</h4>
            <ul class="list-item">
              <li>About</li>
              <li>Sotfware</li>
              <li>Story</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6"></div>
    </div>
  </div>
</div>
<div class="copyright">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <p>© Remitt 2023. Todos los derechos reservados.</p>
      </div>
      <div
        class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end"
      >
        <a href=""> Privacy Policy </a>
        <a href="" style="margin-left: 1.5rem"> Terms of Service </a>
      </div>
    </div>
  </div>
</div>
