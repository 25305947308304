import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {lastValueFrom} from 'rxjs'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportPurchasesService {

  constructor(
    private http: HttpClient
  ) { }

  async getMyTotalPurchase(addr: string, code: string | null = null){
    try {

      const params = new HttpParams()
        .set('addr', addr);

      if(code){ params.set('filter', code); }

      const url = environment.API_URL + '/api/v1/report-purchases/my-total-purchases'

      const snapshot: any = await lastValueFrom(
        this.http.get(url, {params})
      );

      return snapshot?.results;
      
    } catch (err) {
      console.log('Error on ReportPurchasesService.getMyTotalPurchase');
      throw err;
    }
  }

  async getMyTotalComission(addr: string){
    try {

      const params = new HttpParams()
        .set('addr', addr);

      const url = environment.API_URL + '/api/v1/report-purchases/my-total-commission'

      const snapshot = await lastValueFrom(
        this.http.get(url, {params})
      );

      return snapshot;
      
    } catch (err) {
      console.log('Error on ReportPurchasesService.getMyTotalComission');
      throw err;
    }
  }
}
