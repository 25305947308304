import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { Observable, Subscription, catchError, distinctUntilChanged, from, interval, map, of, startWith, switchMap } from 'rxjs';
import { fromWei } from 'src/app/helpers/utils';
import { SwapContractService } from 'src/app/services/contract/swap-contract.service';
import { SwapUserContractService } from 'src/app/services/contract/swap-user-contract.service';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';

@Component({
  selector: 'app-navbar-dashboard',
  templateUrl: './navbar-dashboard.component.html',
  styleUrls: ['./navbar-dashboard.component.css']
})
export class NavbarDashboardComponent implements OnInit, OnDestroy {

  /** Cuenta del usuario */
  public account: any;

  /** Precio del token principal */
  public mainTokenPrice$!: Observable<any>;

  public isUserRed$!: Observable<any>;

  /** Idioma actual */
  public currentLanguage: any;

  public options: AnimationOptions = {
    path: "/assets/img/lottie/Loaderblanco.json",
  };
  public options1: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };

  private sub$!: Subscription;

  constructor(
    public translateSrv: CustomTranslateService,
    private web3Srv: Web3Service,
    private swapContractSrv: SwapContractService,
    private swapUserContractSrv: SwapUserContractService,
  ) { }

  ngOnInit(): void {
    /** Obtiene el estado de la cuenta */
    this.sub$ = this.web3Srv.accountStatus$
    .pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
    )
    .subscribe((data: any) => {
      this.account = data;
    });

    /** Capturar idioma actual */
    this.currentLanguage = this.translateSrv.currentLanguage;
    // console.log('currentLanguage', this.currentLanguage);

    /** Obtener precio del token principal */
    this.mainTokenPrice$ = interval(1000)
    .pipe(
      startWith(0),
      switchMap((value) => from(this.swapContractSrv.mainToken_getPriceToken_OFFCHAIN()) ),
      map((data: any) => fromWei(data)),
      distinctUntilChanged(),
      catchError((err) => of(0))
    );
    
    /** Verificar si es un usuario de la red */
    this.isUserRed$ = this.web3Srv.accountStatus$
    .pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (data: any) => {
        if(!data) {
          return {isUserRed: false};
        }
        // return from(this.swapUserContractSrv.user_userWalletExist_OFFCHAIN(data));
        const find = await this.swapUserContractSrv.user_userWalletExist_OFFCHAIN(data);

        return {isUserRed: find};
      }),
      catchError((err) => of({isUserRed: false}))
    )
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }

  /**
   * @dev Cambiar idioma
   * @param language                Idioma a cambiar
   */
  changeLanguage(language: string) {
    let list: any = document.getElementById("languaje");
    list.classList.toggle("show");

    this.translateSrv.changeLanguage(language);
    this.currentLanguage = language;
  }

  /**
   * @dev Cerrar sesión
   * @returns 
   */
  async logOut() {
    this.web3Srv.logout(false);
    window.location.href = '/landing';
    return;
  }
}
