import { Component, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, catchError, combineLatest, from, interval, map, of, startWith, switchMap, takeWhile, tap } from 'rxjs';
import { calculateStakeCurrentReward } from 'src/app/helpers/calculateStakeCurrentReward.helper';
import { fromBasicPoint, fromWei } from 'src/app/helpers/utils';
import { AlertStepsService } from 'src/app/services/contract/alert-steps.service';
import { StakeContractService } from 'src/app/services/contract/stake-contract.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: '[app-my-stakes-list-item]',
  templateUrl: './my-stakes-list-item.component.html',
  styleUrls: ['./my-stakes-list-item.component.css']
})
export class MyStakesListItemComponent implements OnInit {

  @Input() index: number = 0;

  @Input() item: any;

  /** Evento al reclamar recompensa */
  @Output() onClaim = new Subject<any>();

  /** Cuenta regresiva para reclamar stake */
  public countdown$!: Observable<any>;

  /** Token princila */
  public mainToken = environment.mainToken;

  /** Documento de opción de stake */
  public stakeOptionDocument: any;

  /** Timer para calcular ganancias obtenidas */
  public earnTimer$!: Observable<any>;

  public toolTipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  private myTooltip: any;

  constructor(
    private alertStepSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service,
    private stakeContractSrv: StakeContractService,
  ) { }

  ngOnInit(): void {
    // console.log('item', this.item);
    /** Construir conteo regresivo */
    this.buildCountdown();

    /** Obtener información de la opción del stake */
    this.loadStakeOptionDocument();

    /** Construir timer para calcular ganancias obtenidas */
    this.earnTimer$ =  from(this.stakeContractSrv.stakeable_calculateStakeRecordReward_OFFCHAIN(this.item.idx, this.item.user))
    .pipe(
      // tap((data: any) => console.log('earnTimer$', data)),
      switchMap((data: any) => {
        const [tr, records] = Object.values(data) as any[];
        const latestEndTime = Math.max(...records.map((row: any) => row.endTime));

        return combineLatest([
          of(records),
          interval(60000).pipe( startWith(0) ), // cada minuto
        ])
        .pipe(
          takeWhile(() => moment().unix() <= latestEndTime, true) // Continúa mientras la fecha actual sea menor o igual a la fecha tope más reciente
        );
      }),
      // tap((data: any) => console.log('earnTimer$', data)),
      map(([records, interval]: any) => {
        const reward = calculateStakeCurrentReward(records);
        return {reward};
      }),

      catchError((err) => of({ reward: 0 })),
    );
  }

  buildCountdown() {
    const current = moment().unix();
    const expired =  moment.unix(this.item.untilBlock).isBefore(moment.unix(current));

    if(expired) {
      this.countdown$ = of({
        untilBlock: this.item.untilBlock,
        canClaim: true,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      });


    } else {
      this.countdown$ = interval(1000)
      .pipe(
        map((val: number) => {
          const timer = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  
          const endAt = moment.unix(this.item.untilBlock);
          const current = moment().unix();
  
          const canClaim = endAt.isBefore(moment.unix(current));
  
          if(!canClaim) {
            const duration = moment.duration(endAt.diff(moment.unix(current)));
            timer.days = Math.floor(duration.asDays());
            timer.hours = Math.floor(duration.asHours()) - Math.floor(duration.asDays()) * 24;
            timer.minutes = Math.floor(duration.asMinutes()) - Math.floor(duration.asHours()) * 60;
            timer.seconds = Math.floor(duration.asSeconds()) - Math.floor(duration.asMinutes()) * 60;
          }
  
          return {
            untilBlock: this.item.untilBlock,
            canClaim: canClaim,
            ...timer
          }
        }),
        // take until can claim is true
        takeWhile((val: any) => !val.canClaim)
      );
    }

    return;
  }

  /**
   * @dev Cargar información de opción de stake a la que pertenece este registro
   */
  async loadStakeOptionDocument() {
    try {
      /** Contract Document - Stake option */
      const cd = await this.stakeContractSrv.staked_getStakeOption_OFFCHAIN(this.item.stakeId);

      /** Establecer valor */
      this.stakeOptionDocument = {
        createdAt: moment.unix(cd.createdAt).valueOf(),
        day: Number(cd.day),
        initRewardRate: fromBasicPoint(cd.initRewardRate),
        rewardRate: fromBasicPoint(cd.rewardRate),
        status: cd.status,
      };
      return;
      
    } catch (err) {
      console.log('Error on MyStakesListItemComponent.loadStakeOptionDocument', err);
      return;
    }
  }

  async onSubmit() {
    try {
      const result = await this.alertStepSrv.showStepsGeneral({
        service: this.stakeContractSrv,
        askMessage: `Are you sure you want to claim your rewards?`,
        method: 'stake_withdrawStake',
        params: [this.item.idx]
      })

      if(!result.status){
        this.sweetAlert2Srv.showError(result.data.message);
        return;
      }

      this.sweetAlert2Srv.showAlertWithTxHash({ transactionHash: result.data.transactionHash });
      this.onClaim.next(true);
      return;
      
    } catch (err) {
      console.log('Error on MyStakesListItemComponent.onSubmit', err);
      return;
    }
  }

}
