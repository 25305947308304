import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { SidebarDashboardComponent } from "./sidebar-dashboard/sidebar-dashboard.component";
import { HeaderDashboardComponent } from "./header-dashboard/header-dashboard.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { PipesModule } from "../pipes/pipes.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MyStakesComponent } from "./my-stakes/my-stakes.component";
import { MyStakesCardsComponent } from "./my-stakes-cards/my-stakes-cards.component";
import { SwapModal1Component } from "./swap-modal1/swap-modal1.component";
import { SwapModal2Component } from "./swap-modal2/swap-modal2.component";
import { PublicLayoutComponent } from "./public-layout/public-layout.component";
import { RouterModule } from "@angular/router";
import { RegisterModalComponent } from "./register-modal/register-modal.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { QRCodeModule } from "angularx-qrcode";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import { SupportComponent } from "./support/support.component";
import { TranslatePipe } from "@ngx-translate/core";
import { SupportEmailComponent } from "./support-email/support-email.component";
import { SliderComponent } from "./slider/slider.component";
import { MyTeamComponent } from "./my-team/my-team.component";
import { HistoryComponent } from "./history/history.component";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { HeaderTbComponent } from "./header-tb/header-tb.component";
import { LandingComponent } from "../pages-old/landing/landing.component";
import { FeaturesLandingComponent } from "../pages-old/landing/components/features-landing/features-landing.component";
import { CountersLandingComponent } from "../pages-old/landing/components/counters-landing/counters-landing.component";
import { ProductsLandingComponent } from "../pages-old/landing/components/products-landing/products-landing.component";
import { RoadmapLandingComponent } from "../pages-old/landing/components/roadmap-landing/roadmap-landing.component";
import { NewsLandingComponent } from "../pages-old/landing/components/news-landing/news-landing.component";
import { StartLandingComponent } from "../pages-old/landing/components/start-landing/start-landing.component";
import { FooterLandingComponent } from "../pages-old/landing/components/footer-landing/footer-landing.component";
import { HeaderLandingComponent } from "../pages-old/landing/components/header-landing/header-landing.component";
import { EcosystemLandingComponent } from "../pages-old/landing/components/ecosystem-landing/ecosystem-landing.component";
import { HistoryFromTeamComponent } from "./history-from-team/history-from-team.component";
import { ModeColorComponent } from "./mode-color/mode-color.component";
import { BtnConnectComponent } from './btn-connect/btn-connect.component';
import { TabsHomeComponent } from './tabs-home/tabs-home.component';
import { CountdownComponent } from './countdown/countdown.component';
import { PagesLayoutComponent } from './pages-layout/pages-layout.component';
import { NavbarDashboardComponent } from './navbar-dashboard/navbar-dashboard.component';
import { LoadingViewCardComponent } from "./loading-view-card/loading-view-card.component";
import { MainTokenAccessCardComponent } from './main-token-access-card/main-token-access-card.component';
import { MyStakesListItemComponent } from './my-stakes-list-item/my-stakes-list-item.component';
import { MyTeamByCustomFilterComponent } from './my-team-by-custom-filter/my-team-by-custom-filter.component';
import { ButtonDownloadAccessRolesComponent } from './button-download-access-roles/button-download-access-roles.component';
import { LottieThemeLogoComponent } from "./lottie-theme-logo/lottie-theme-logo.component";
import { CardStakeOptionItemComponent } from './card-stake-option-item/card-stake-option-item.component';
import { ButtonThreeComponent } from './button-three/button-three.component';
import { CardStakeOptionItemLoaderComponent } from './card-stake-option-item-loader/card-stake-option-item-loader.component';
import { TableListExampleComponent } from './table-list-example/table-list-example.component';
import { ButtonPurpleComponent } from './button-purple/button-purple.component';
import { CardAdminDashboardComponent } from './card-admin-dashboard/card-admin-dashboard.component';

export function playerFactory() {
  return player;
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarDashboardComponent,
    HeaderDashboardComponent,
    AdminLayoutComponent,
    MyStakesComponent,
    MyStakesListItemComponent,
    MyStakesCardsComponent,
    SwapModal1Component,
    SwapModal2Component,
    PublicLayoutComponent,
    RegisterModalComponent,
    QrCodeComponent,
    SupportComponent,
    SupportEmailComponent,
    SliderComponent,
    MyTeamComponent,
    HistoryComponent,
    HeaderTbComponent,
    LandingComponent,
    FeaturesLandingComponent,
    CountersLandingComponent,
    ProductsLandingComponent,
    RoadmapLandingComponent,
    NewsLandingComponent,
    StartLandingComponent,
    FooterLandingComponent,
    HeaderLandingComponent,
    EcosystemLandingComponent,
    HistoryFromTeamComponent,
    ModeColorComponent,
    BtnConnectComponent,
    TabsHomeComponent,
    CountdownComponent,
    PagesLayoutComponent,
    NavbarDashboardComponent,
    LoadingViewCardComponent,
    MainTokenAccessCardComponent,
    MyTeamByCustomFilterComponent,
    ButtonDownloadAccessRolesComponent,
    LottieThemeLogoComponent,
    CardStakeOptionItemComponent,
    CardStakeOptionItemLoaderComponent,
    ButtonThreeComponent,
    TableListExampleComponent,
    ButtonPurpleComponent,
    CardAdminDashboardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    QRCodeModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],

  exports: [
    CommonModule,
    NgxSpinnerModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    HeaderComponent,
    SidebarDashboardComponent,
    HeaderDashboardComponent,
    AdminLayoutComponent,
    MyStakesComponent,
    MyStakesListItemComponent,
    SwapModal1Component,
    SwapModal2Component,
    PublicLayoutComponent,
    RegisterModalComponent,
    QrCodeComponent,
    SliderComponent,
    MyTeamComponent,
    HistoryComponent,
    HistoryFromTeamComponent,
    TabsHomeComponent,
    BtnConnectComponent,
    CountdownComponent,
    PagesLayoutComponent,
    NavbarDashboardComponent,
    LoadingViewCardComponent,
    MainTokenAccessCardComponent,
    MyTeamByCustomFilterComponent,
    ButtonDownloadAccessRolesComponent,
    LottieThemeLogoComponent,
    CardStakeOptionItemComponent,
    CardStakeOptionItemLoaderComponent,
    ButtonThreeComponent,
    TableListExampleComponent,
    ButtonPurpleComponent,
    CardAdminDashboardComponent,
  ],
  providers: [TranslatePipe],
})
export class SharedModule {}
