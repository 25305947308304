import { Component, OnInit } from "@angular/core";
import { map, Observable } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { CustomTranslateService } from "src/app/services/custom-translate.service";
import { ActivatedRoute, Router } from "@angular/router";

import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.css"],
})
export class PublicLayoutComponent implements OnInit {

  // lengSelected = "es";
  options: AnimationOptions = {
    path: "/assets/img/lottie/Loaderblanco.json",
  };
  options1: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }

  public wallet: any;

  public dataStatus$!: Observable<any>;

  public priceTokens: any;

  public currentLanguage: any;

  public isUserRed: boolean = false;
  public isAdmin: boolean = false;
  public referedCode: any;
  public myCode: any;

  constructor(
    private web3Srv: Web3Service,
    private commonSrv: CommonService,
    public translateSrv: CustomTranslateService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.currentLanguage = 'en';
    // console.log(this.currentLanguage);

    if (this.currentLanguage) {
      this.translateSrv.changeLanguage(this.currentLanguage);
    }
  }

  ngOnInit(): void {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      // tap((data) => console.log('data', data)),
      map((data: any[]) => (data.length > 0 ? data[0] : null))
    );
    try {
      this.wallet = this.web3Srv.accounts[0];
    } catch (error) {
      
    }

    this.commonSrv.actualPrice$.subscribe(
      (price: any) => (this.priceTokens = price)
    );

    this.commonSrv.getMyCode$.subscribe(
      (myCode: any) => (this.myCode = myCode)
    );

    this.web3Srv.userRed$.subscribe((is: boolean) => (this.isUserRed = is));

    this.web3Srv.swapAdm$.subscribe((is: boolean) => (this.isAdmin = is));
    // this.commonSrv.inCodeReferred$.subscribe((code:string)=> this.referedCode = code);

    // this.redirect();
  }

  // redirect(){
  //   if(this.isUserRed === true || this.isAdmin === true){
  //     this.router.navigate([`/dashboard`])
  //   } else if (this.referedCode == "" || this.referedCode == null) {
  //     this.router.navigate([`/referidos`])
  //   }
  // }

  async logOut() {
    return this.web3Srv.logout(true);
  }

  changeLanguage(language: string) {
    let list: any = document.getElementById("languaje");
    list.classList.toggle("show");
    try {
      this.translateSrv.changeLanguage(language);
      this.currentLanguage = language;
      localStorage.setItem("language", language);
      // this.lengSelected = language;
      // console.log(this.currentLanguage);
    } catch (error) {
      console.log(error);
    }
  }

  toggleButtonLenguage(event: any) {
    let list: any = document.getElementById("dropdown-menu");
    list.classList.toggle("show");
  }
}
