<div class="bg-general" id="home">
  <div class="loading">
    <ng-lottie
      class="animation-logo"
      [options]="options"
      (animationCreated)="animationCreated($event)"
    ></ng-lottie>
  </div>

  <section class="landing">
    <app-header-dashboard></app-header-dashboard>
    <!-- <app-header-dashboard></app-header-dashboard> -->
    <video
      class="video-bg"
      src="assets/img/video/landing-video.mp4"
      autoplay
      muted
    ></video>
    <video
      class="video-bg"
      src="assets/img/video/landing-video.mp4"
      type="video/mp4"
      poster="poster-video-landing.png"
      width="1920"
      height="1080"
      loop
      autoplay
    ></video>
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center"></div>
        <div class="col-12 d-flex justify-content-center">
          <div class="c-video-2">
            <div class="video-2">
              <video
                width="600px"
                class="videoIntro"
                src="{{ 'home-dashboard.global.videoIntro' | translate }}"
                controls
                autoplay
              >
                <source
                  src="{{ 'home-dashboard.global.videoIntro' | translate }}"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-center my-5">
          <img
            width="30px"
            class="img-fluid mt-5"
            src="assets/img/arrow-turquesa.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <section class="what-remitt-section" id="about">
    <div class="what-remitt-container">
      <div class="container">
        <div class="row pt-5 justify-content-center g-0">
          <div class="col-12">
            <div
              class="row justify-content-center g-0 position-relative what-container"
            >
              <div class="col-12 col-lg-4 col-xl-4 what-remitt">
                <h1>¿What is Remitt?</h1>
                <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur, adipisci velit, sed quia non numquam eius
                  modi tempora incidunt ut labore et dolore magnam aliquam
                  quaerat voluptatem. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </p>

                <button class="btn-deg">See More</button>
              </div>

              <div class="col-12 col-lg-4">
                <div class="square"></div>
                <div class="square two"></div>
                <div class="animation-b">
                  <ng-lottie
                    class="animation-bl"
                    [options]="options2"
                    (animationCreated)="animationCreated($event)"
                  ></ng-lottie>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-features-landing></app-features-landing>
  <app-counters-landing></app-counters-landing>
  <app-ecosystem-landing></app-ecosystem-landing>
  <app-products-landing></app-products-landing>
  <div class="bg-grey">
    <app-roadmap-landing></app-roadmap-landing>
    <app-news-landing></app-news-landing>
  </div>
  <app-start-landing></app-start-landing>

  <app-footer-landing></app-footer-landing>
</div>
