import { saveAs } from 'file-saver';
import * as moment from 'moment';

/**
 * @dev Helper para guardar archivos excel
 * @param buffer                    Blob
 * @param fileName                  Nombre del archivo
 * @param options                   Opciones
 */
export function saveBlobAsExcelFile(buffer: any, fileName: string, options: any = {}): void {
    const {
        EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        EXCEL_EXTENSION = '.xlsx'
    } = options;

    const _date = moment().valueOf();
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, fileName + '_export_' + _date + EXCEL_EXTENSION);
}