import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalService } from 'src/app/services/bs-modal.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { CommonService } from 'src/app/services/common.service';
import { MetamaskAddTokenService } from 'src/app/services/metamask-add-token.service';



@Component({
  selector: 'app-swap-modal1',
  templateUrl: './swap-modal1.component.html',
  styleUrls: ['./swap-modal1.component.css']
})
export class SwapModal1Component implements OnInit, AfterViewInit {

  public mId = "selectPair";

  private mi: any;

  public tokenList: any[] = [];

  public pair: any;

  constructor(
    private bsModalSrv: BsModalService,
    private commonService: CommonService,
    private metaAdd: MetamaskAddTokenService
  ) { }

  ngOnInit(): void {
    this.tokenList = this.commonService.pairListProv;
  }

  ngAfterViewInit(): void {
    this.mi = this.bsModalSrv.buildModal(this.mId)
  }

  async show(){
    this.mi.show();
  }

  hide(){
    this.mi.hide();
  }

  setPair(obj: any) {
    this.commonService.setPair(obj);
    this.hide();

  }

  async addtoken(obj: any)
  {
    await this.metaAdd.addToken(obj.smartContract, obj.token1, obj.tokenDmc, obj.imgToken1)
  }

}
