<ng-container *ngIf="dataStatus$ | async as dataStatus; else noConnected">
  <app-sidebar-dashboard></app-sidebar-dashboard>

  <div class="section-template">
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="header-dash">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-xl-none">
                  <ng-lottie class="animation-logo-h logo-hw" [options]="options"
                    (animationCreated)="animationCreated($event)"></ng-lottie>
                  <ng-lottie class="animation-logo-h logo-hc" [options]="options1"
                    (animationCreated)="animationCreated($event)"></ng-lottie>
                </div>
                <div class="flex-fill">
                  <app-header-tb [myWallet]="dataStatus"></app-header-tb>
                </div>
                <div class="price">
                  <span class="fw-bold">
                    {{"home-dashboard.public-layout.price-token" | translate}}
                  </span>
                  {{ priceTokens | web3Utils : "fromWei" }} USD
                </div>
                <div class="btn-languaje languaje" id="languaje">
                  <div [ngClass]="{active: currentLanguage == 'es'}" class="option" (click)="changeLanguage('en')">
                    <img src="assets/img/languaje-es.png" alt="">
                  </div>
                  <div [ngClass]="{active: currentLanguage == 'en'}" class="option" (click)="changeLanguage('es')">
                    <img src="assets/img/languaje-en.png" alt="">
                  </div>
                </div>
                <div class="wallet d-flex align-items-center" (click)="logOut()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.04"
                    height="15.776" viewBox="0 0 19.04 15.776">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_381" data-name="Rectangle 381" width="19.04" height="15.776" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Group_955" data-name="Group 955" transform="translate(-1712.47 -51.296)">
                      <g id="Group_954" data-name="Group 954" transform="translate(1712.47 51.296)">
                        <g id="Group_953" data-name="Group 953" transform="translate(0 0)" clip-path="url(#clip-path)">
                          <path id="Path_402" data-name="Path 402"
                            d="M15.548,14.914H3.127A2.628,2.628,0,0,1,.5,12.284V.5H15.55a2.628,2.628,0,0,1,2.629,2.629v9.155a2.628,2.628,0,0,1-2.627,2.63Z"
                            transform="translate(0.181 0.181)" fill="none" stroke="#fff" stroke-width="1" />
                          <path id="Path_403" data-name="Path 403"
                            d="M11.562,6.32A1.986,1.986,0,1,1,9.576,4.334,1.986,1.986,0,0,1,11.562,6.32Z"
                            transform="translate(2.747 1.569)" fill="none" stroke="#fff" stroke-width="1" />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-2">
                    {{ dataStatus | truncateWalletAddress }}
                  </span>
                </div>
                <div class="avatar ms-2 ms-xxl-3">
                  <img src="assets/img/avatar/avatar.png" alt="" />
                </div>
              </div>

            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #noConnected>
  <section class="landing home-dash">
    <app-header-dashboard></app-header-dashboard>

    <app-countdown></app-countdown>

    <div class="container-fluid content-l">
      <div class="row">
        <div class="col-12 my-3">
          <div class="title">{{ "landing.msjwelcome" | translate }}</div>
          <div class="subtitle">{{ "landing.conectwallemsj" | translate }}</div>
        </div>

        <app-btn-connect class="d-lg-none"></app-btn-connect>

        <div class="col-12 d-flex justify-content-center position-relative">
          <div class="reds d-none d-md-block">
            <div class="item-red p-2">
              <a href="https://t.me/remitt_io" target="_blank"><img src="assets/img/icon-telegram.png" alt=""></a>
            </div>
            <div class="item-red my-2">
              <a href="https://www.tiktok.com/@remitt.io" target="_blank"><img src="assets/img/icon-tiktok.png"
                  alt=""></a>
            </div>
            <div class="item-red my-2">
              <a href="https://www.youtube.com/@remitt" target="_blank"><img src="assets/img/icon-youtube.png"
                  alt=""></a>
            </div>
            <div class="item-red my-2">
              <a href="https://www.facebook.com/FintechRemitt" target="_blank"><img src="assets/img/icon-facebook.png"
                  alt=""></a>
            </div>
            <div class="item-red my-2">
              <a href="https://twitter.com/Remitt_io" target="_blank"><img src="assets/img/x-twitter.svg" alt=""></a>
            </div>
            <div class="item-red">
              <a href="https://www.instagram.com/remitt.io/" target="_blank"><img src="assets/img/instagram.svg"
                  alt=""></a>
            </div>
          </div>
          <div class="c-video-2 my-5">
            <div class="video-2">
              <video width="600px" class="videoIntro" src="{{ 'home-dashboard.global.videoIntro' | translate }}"
                controls autoplay>
                <source src="{{ 'home-dashboard.global.videoIntro' | translate }}" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <app-tabs-home></app-tabs-home>
        </div>
      </div>
    </div>
  </section>

</ng-template>

<!-- Modal -->
<div class="modal fade modalTerms" id="Terms" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 fw-bold" id="exampleModalLabel">Remitt Terms of services</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <p>
          These Terms of Service (the “Agreement”) explains the terms and conditions by
          which you may access and use https://remitt.io and any subdomains associated with
          the Website. You must read this Agreement carefully as it governs your use of the
          Website. By accessing or using the Website, you signify that you have read,
          understand, and agree to be bound by this Agreement in its entirety. If you do not
          agree, you are not authorized to access or use the Website and should not use the
          Website.
        </p>
        <p>
          <strong>NOTICE:</strong><br> This Agreement contains important information, including a binding
          arbitration provision and a class action waiver, both of which impact your rights as to
          how disputes are resolved. The Website is only available to you — and you should
          only access the Website — if you agree completely with these terms.
        </p>

        <p>
          <strong>Introduction</strong><br>
          The Website provides access to the REMITT blockchain ecosystem running on the
          BNB Chain public blockchain, which allows users to exchange certain compatible
          digital assets, among other services. The Website is one of the means of access to
          the ecosystem, although not the only one.
          To access the Website, you must use non-custodian wallet software, which allows
          you to interact with public blockchains. Your relationship with that non-custodial
          wallet provider is governed by that third party's applicable terms of service, not this
          Agreement. The wallets are not operated, maintained or affiliated with us, and we
          have no custody or control over the contents of your wallet and no ability to retrieve
          or transfer its contents. By connecting your wallet to our Website, you agree to be
          bound by this Agreement and all terms incorporated herein by reference.
        </p>
        <p>
          <strong>Modification of this Agreement</strong><br>
          We reserve the right, at our sole discretion, to modify this Agreement as often as
          necessary.
          Modifications will be effective upon posting, and your continued access or use of the
          Website will serve as confirmation of your acceptance of such modifications. If you
          do not agree to any changes to this Agreement, you must immediately cease
          accessing and using the Website.
          Description of the services provided through the Website.
          The Website provides a means of accessing the REMITT web-based or mobile
          ecosystem.
        </p>
        <p>
          <strong>Ecosystem access website</strong><br>
          The Website is distinct from the ecosystem and is one, but not the only, means of
          accessing the ecosystem. The Ecosystem comprises self-executing smart contracts
          that are deployed on the public blockchain BNB Chain. Remitt does not control or
          operate any version of the Ecosystem on any other blockchain network. By using the
          Website, you understand and agree to the foregoing.
        </p>
        <p>
          <strong>Requirements</strong><br>
          To access or use the Website, you must be able to enter into a legally binding
          contract with us. Accordingly, you represent that you are of legal age in your
          jurisdiction and that you have full right, power and authority to enter into and perform
          the terms and conditions of this Agreement on behalf of yourself and any company
          or legal entity for which you may access or use the Website.
        </p>
        <p>
          <strong>Intellectual Property Rights</strong><br>
          Remitt owns all intellectual property and other rights in the Website and most of its
          content, including (but not limited to) software, text, images, copyrights, patents,
          designs, and its "look and feel".
        </p>
        <p>
          <strong>Additional Rights</strong><br>
          We reserve the following rights, which do not constitute obligations of ours: (a) with
          or without notice to you, to modify, substitute, eliminate or add to the Website; (b) to
          review, modify, filter, disable, delete and remove any and all content and information
          from the Website; and (c) to cooperate with any law enforcement, court or
          government investigation or order or third party requesting or directing that we
          disclose information or content or information that you provide.
        </p>
        <p>
          <strong>Prohibited Activity</strong><br>
          You agree not to engage in, or attempt to engage in, any of the following categories
          of prohibited activity in relation to your access and use of the Website:
          Intellectual Property Infringement. Activity that infringes on or violates any
          copyright, trademark, service mark, patent, right of publicity, right of privacy, or other
          proprietary or intellectual property rights under the law.
        </p>

        <ul>
          <li>
            Cyberattack. Activity that seeks to interfere with or compromise the integrity,
            security, or proper functioning of any computer, server, network, personal
            device, or other information technology system, including (but not limited to)
            the deployment of viruses and denial of service attacks.
          </li>
          <li>
            Fraud and Misrepresentation. Activity that seeks to defraud us or any other
            person or entity, including (but not limited to) providing any false, inaccurate,
            or misleading information in order to unlawfully obtain the property of another.
          </li>
          <li>
            Market Manipulation. Activity that violates any applicable law, rule, or
            regulation concerning the integrity of trading markets, including (but not
            limited to) the manipulative tactics commonly known as “rug pulls”, pumping
            and dumping, and wash trading.
          </li>
          <li>
            Securities and Derivatives Violations. Activity that violates any applicable
            law, rule, or regulation concerning the trading of securities or derivatives,
            including (but not limited to) the unregistered offering of securities and the
            offering of leveraged and margined commodity products to retail customers in
            the United States.
          </li>
          <li>
            Sale of Stolen Property. Buying, selling, or transferring of stolen items,
            fraudulently obtained items, items taken without authorization, and/or any
            other illegally obtained items.
          </li>
          <li>
            Data Mining or Scraping. Activity that involves data mining, robots, scraping,
            or similar data gathering or extraction methods of content or information from
            the Website.
          </li>
          <li>
            Objectionable Content. Activity that involves soliciting information from
            anyone under the age of 18 or that is otherwise harmful, threatening, abusive,
            harassing, tortious, excessively violent, defamatory, vulgar, obscene,
            pornographic, libelous, invasive of another’s privacy, hateful, discriminatory, or
            otherwise objectionable.
          </li>
          <li>
            Any Other Unlawful Conduct. Activity that violates any applicable law, rule,
            or regulation of the United States or another relevant jurisdiction, including
            (but not limited to) the restrictions and regulatory requirements imposed by
            U.S. law.
          </li>
        </ul>


        <p>
          <strong>Staking offert</strong><br>
          You represent that you are not a user from the following countries or regions when
          participating in our Initial Farm Offerings:
          Belarus, Cuba, Crimea Region, Democratic Republic of Congo, Iran, Iraq, New
          Zealand, North Korea, South Sudan, Sudan, Syria, United States of America and its
          territories (American Samoa, Guam, Puerto Rico, the Northern Mariana Islands, and
          the U.S. Virgin Islands), Zimbabwe.
        </p>
        <p>
          <strong>Not Registered with the SEC or Any Other Agency</strong><br>
          We are not registered with the U.S. Securities and Exchange Commission as a
          national securities exchange or in any other capacity. You understand and
          acknowledge that we do not broker trading orders on your behalf. We also do not
          facilitate the execution or settlement of your trades, which occur entirely on the
          public blockchains.
        </p>
        <p>
          <strong>Non-Solicitation; No Investment Advice</strong><br>
          You agree and understand that: (a) all trades you submit through the Website are
          considered unsolicited, which means that they are solely initiated by you; (b) you
          have not received any investment advice from us in connection with any trades,
          including those you place via our Smart contract API; and (c) we do not conduct a
          suitability review of any trades you submit.
          All such information provided by the Website is for informational purposes only and
          should not be construed as investment advice or a recommendation that a particular
          token is a safe or sound investment. You should not take, or refrain from taking, any
          action based on any information contained in the Website. You alone are responsible
          for determining whether any investment, investment strategy or related transaction is
          appropriate for you based on your personal investment objectives, financial
          circumstances, and risk tolerance.
        </p>
        <p>
          <strong>Non-Custodial and No Fiduciary Duties</strong><br>
          The Website is a purely non-custodial application, meaning we do not ever have
          custody, possession, or control of your digital assets at any time. It further means
          you are solely responsible for the custody of the cryptographic private keys to the
          digital asset wallets you hold and you should never share your wallet credentials or
          seed phrase with anyone. We accept no responsibility for, or liability to you, in
          connection with your use of a wallet and make no representations or warranties
          regarding how the Website will operate with any specific wallet. Likewise, you are
          solely responsible for any associated wallet and we are not liable for any acts or
          omissions by you in connection with or as a result of your wallet being compromised.
          This Agreement is not intended to, and does not, create or impose any fiduciary
          duties on us. To the fullest extent permitted by law, you acknowledge and agree that
          we owe no fiduciary duties or liabilities to you or any other party, and that to the
          extent any such duties or liabilities may exist at law or in equity, those duties and
          liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further
          agree that the only duties and obligations that we owe you are those set out
          expressly in this Agreement.
        </p>
        <p>
          <strong> Tax Compliance and Tax Obligations</strong><br>
          The Web Site may not be available or appropriate for use in your jurisdiction. By
          accessing or using the Website, you agree that you are solely and fully responsible
          for compliance with all laws and regulations that may apply to you.
          Specifically, your use of the Website or the Protocol may have various tax
          consequences, such as income or capital gains tax, value added tax, goods and
          services tax or sales tax in certain jurisdictions. It is your responsibility to determine
          whether tax applies to any transaction you initiate or receive and, if so, to report
          and/or remit the correct tax to the appropriate tax authority.
        </p>
        <p>
          <strong>Assumption of Risk</strong><br>
          By accessing and using the Website, you represent that you are financially and
          technically sophisticated enough to understand the inherent risks associated with the
          use of cryptographic and blockchain-based systems, and that you have a working
          knowledge of the use and complexities of digital assets.
          You further understand that smart contract transactions are automatically executed
          and settled, and that blockchain-based transactions are irreversible once confirmed.
          You acknowledge and agree that the cost and speed of cryptographic and
          blockchain-based transactions are variable and can increase dramatically at any
          time.
        </p>
        <p>
          <strong>Release of Claims</strong><br>
          You expressly agree that you assume all risks associated with your access to and
          use of the Web Site. You further expressly disclaim any and all liability, claims,
          causes of action or damages arising out of or in any way connected with your use of
          the Web Site. If you are a California resident, you waive the benefits and protections
          of California Civil Code § 1542, which provides: "[a] general release does not extend
          to claims that the creditor or releasing party does not know or suspect to exist in his
          or her favor at the time of executing the release and that, if known by him or her,
          would have materially affected his or her settlement with the debtor or released
          party."
        </p>
        <p>
          <strong>Indemnity</strong><br>
          You agree to hold harmless, release, defend, and indemnify us and our officers,
          directors, employees, contractors, agents, affiliates, and subsidiaries from and
          against all claims, damages, obligations, losses, liabilities, costs, and expenses
          arising from: (a) your access and use of the Website; (b) your violation of any term or
          condition of this Agreement, the right of any third party, or any other applicable law,
          rule, or regulation; and (c) any other party's access and use of the Website with your
          assistance or using any device or account that you own or control.
        </p>
        <p>
          <strong> No Warranties</strong><br>
          The Website is provided on an "AS IS" and "AS AVAILABLE" basis. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ANY
          REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS,
          IMPLIED, OR STATUTORY, INCLUDING (BUT NOT LIMITED TO) THE
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
          PURPOSE. You acknowledge and agree that your use of the Website is at your own
          risk. We do not represent or warrant that access to the Website will be continuous,
          uninterrupted, timely, or secure; that the information contained in the Website will be
          accurate, reliable, complete, or current; or that the Website will be free from errors,
          defects, viruses, or other harmful elements. No advice, information, or statement that
          we make should be treated as creating any warranty concerning the Website. We do
          not endorse, guarantee, or assume responsibility for any advertisements, offers, or
          statements made by third parties concerning the Website.
        </p>

        <p>
          <strong>Limitation of Liability</strong><br>
          UNDER NO CIRCUMSTANCES SHALL WE OR ANY OF OUR OFFICERS,
          DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, AFFILIATES, OR
          SUBSIDIARIES BE LIABLE TO YOU FOR ANY INDIRECT, PUNITIVE,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
          INCLUDING (BUT NOT LIMITED TO) DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA, OR OTHER INTANGIBLE PROPERTY, ARISING OUT OF
          OR RELATING TO ANY ACCESS OR USE OF THE INTERFACE, NOR WILL WE
          BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR INJURY RESULTING FROM
          HACKING, TAMPERING, OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
          INTERFACE OR THE INFORMATION CONTAINED WITHIN IT. WE ASSUME NO
          LIABILITY OR RESPONSIBILITY FOR ANY: (A) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT; (B) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM ANY ACCESS
          OR USE OF THE INTERFACE; (C) UNAUTHORIZED ACCESS OR USE OF ANY
          SECURE SERVER OR DATABASE IN OUR CONTROL, OR THE USE OF ANY
          INFORMATION OR DATA STORED THEREIN; (D) INTERRUPTION OR
          CESSATION OF FUNCTION RELATED TO THE INTERFACE; (E) BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO
          OR THROUGH THE INTERFACE; (F) ERRORS OR OMISSIONS IN, OR LOSS OR
          DAMAGE INCURRED AS A RESULT OF THE USE OF, ANY CONTENT MADE
          AVAILABLE THROUGH THE INTERFACE; AND (G) THE DEFAMATORY,
          OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
        </p>
        <p>
          <strong>Dispute Resolution</strong><br>
          We will make every effort to resolve any potential dispute through informal, good
          faith negotiations. If a potential dispute arises, you should contact us by emailing us
          at support@remitt.io so that we can attempt to resolve it without resorting to formal
          dispute resolution.
        </p>
        <p>
          <strong>Entire Agreement</strong><br>
          These Terms constitute the entire agreement between you and us with respect to the
          subject matter hereof. This Agreement supersedes any and all prior or
          contemporaneous agreements, communications and other understandings, both
          written and oral (if any), relating to the subject matter of the terms.
          Gas Fees.
          Blockchain transactions require the payment of transaction fees to the applicable
          network ("Gas Fees"). Except as otherwise expressly set forth in the terms of
          another Remitt offering, you will be solely responsible for paying the Gas Fees for
          any transaction you initiate.
        </p>
      </div>
    </div>
  </div>
</div>