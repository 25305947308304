import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Web3Service, checkCodevailableEmail } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService } from 'src/app/services/bs-modal.service';
import { CommonService } from 'src/app/services/common.service';
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';


@Component({
  selector: 'app-support-email',
  templateUrl: './support-email.component.html',
  styleUrls: ['./support-email.component.css']
})
export class SupportEmailComponent implements OnInit {

 







  public form: FormGroup;
  submitted = false;
  private mi: any;

  public myWallet: string= this.web3Srv.accounts[0];

  public vm = {
    email: [
      { type: "required", message: 'home-dashboard.refers.code-obligatorio' },
      { type: 'pattern', message: 'home-dashboard.refers.wallet-emailValid' },
    ],
    wallet: [
      { type: "required", message: 'home-dashboard.refers.referedBy-obligatorio' },
    ], 
    hash: [
    ],
    code: [
      { type: "required", message: 'home-dashboard.refers.code-obligatorio' },
      { type: 'userCodeExist', message: 'home-dashboard.refers.code-exist' },
      { type: 'minLength', message: 'home-dashboard.refers.code-min' },
      { type: 'pattern', message: 'home-dashboard.refers.code-pattern' },
    ],
    subject: [
      { type: 'required', message: 'home-dashboard.refers.wallet-obligatorio' },
      { type: 'minLength', message: 'home-dashboard.refers.code-min' },
    ],
    description: [
      { type: 'required', message: 'home-dashboard.refers.wallet-obligatorio' },
      { type: 'minLength', message: 'home-dashboard.refers.code-min' },
    ],
  };

  public referedCode: any;

  constructor(
    public fb: FormBuilder,
    public web3Srv: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
    private spinner: NgxSpinnerService,
    private bsModalSrv: BsModalService,
    private commonSrv: CommonService,
    private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService
  ) {

    
    

    this.form = fb.group({
      email: [ "",        
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      wallet: ["", [
        Validators.required,
      ]],
      hash: [""],
      code: ["", [
        Validators.minLength(3),
        Validators.pattern(/^[a-z0-9]+$/)
      ],
      // [checkCodevailableEmail(this.web3Srv)]
      ],
      subject: [ "",        
      [
        Validators.required,
        Validators.minLength(3),
      ],
    ],
    description: [ "",        
    [
      Validators.required,
      Validators.minLength(3),
    ],
  ],
    });
  }

  ngOnInit(): void { 
    this.buildModal();
    this.translateSrv.loadLocalLanguage();
  }

  get f() { return this.form.controls; }



  async onSubmit() {

   
    try {

      let _data = this.form.value; 
    
      this.submitted = true;

      this.spinner.show();
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.spinner.hide();
        return;
      }

      console.log("info to send", _data);
  
      this.spinner.hide();

      this.hide();

    } catch (err) {
      console.log('Error on SupportEmailComponent', err);
      alert('Error on SupportEmailComponent');
      return;
    } 
  }


  async buildModal(){
    this.mi = this.bsModalSrv.buildModal("supportModal", {backdrop: "static"});   
  }

  async show(){
    this.mi.show();
    
  }

  hide(){
    this.mi.hide();
    this.form.reset();
    this.submitted = false;
  }

}