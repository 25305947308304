<div class="title-staking fw-bold">
  My stakes
</div>
<div class="table-staking">
  <div class="row">

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">{{"Option" | translate}}</th>
            <!-- <th scope="col" class="text-center">{{"Wallet" | translate}}</th> -->
            <th scope="col" class="text-center">{{"Amount" | translate}}</th>
            <th scope="col" class="text-center">{{"Earn" | translate}}</th>
            <th scope="col" class="text-center">{{"Start" | translate}}</th>
            <th scope="col" class="text-center">{{"End" | translate}}</th>
            <th scope="col" class="text-center">{{"Claim" | translate}}</th>
          </tr>
        </thead>
      
        <tbody>
          <ng-container 
            *ngIf="(stakeList$ | async) as list; else renderLoader" 
            [ngTemplateOutlet]="renderCheck" [ngTemplateOutletContext]="{ list: list }"
          ></ng-container>
        </tbody>
      
      </table>
    </div>

  </div>
</div>

<ng-template #renderList let-list="list">
  <tr 
    *ngFor="let item of list; index as idx"
    app-my-stakes-list-item 
    [index]="(idx + 1)" [item]="item"
    (onClaim)="onClaimReward($event)"
  ></tr>
</ng-template>

<ng-template #renderNoRecords>
  <tr>
    <td colspan="10">
      <h5 class="text-center">
        {{"No records found" | translate}}
      </h5>
    </td>
  </tr>
</ng-template>

<ng-template #renderCheck let-list="list">
  <ng-container 
    *ngIf="list.length > 0; else renderNoRecords" 
    [ngTemplateOutlet]="renderList" [ngTemplateOutletContext]="{ list: list }"
  ></ng-container>
</ng-template>

<ng-template #renderLoader>
  <tr *ngFor="let item of [1,1,1,1]">
    <td colspan="10" class="text-center placeholder-glow">
      <span class="placeholder col-7"></span>
    </td>
  </tr>
</ng-template>