import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-theme-logo',
  templateUrl: './lottie-theme-logo.component.html',
  styleUrls: ['./lottie-theme-logo.component.css']
})
export class LottieThemeLogoComponent implements OnInit, OnChanges {

  @Input() theme: string = 'light';

  public themeOptions: any = {
    light: { path: '/assets/img/lottie/LoaderColor.json' },
    dark: { path: '/assets/img/lottie/Loaderblanco.json' }
  };

  public currentTheme: string = this.themeOptions[this.theme] || this.themeOptions['light'] ;

  public options: AnimationOptions = this.themeOptions[this.theme];

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { theme } = changes;

    if(theme && theme.currentValue){
      this.currentTheme = this.themeOptions[theme.currentValue] || this.themeOptions['light'];
      this.options = this.themeOptions[theme.currentValue];
    }
  }

}



