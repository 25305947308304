<ng-container 
    *ngIf="(roles$ | async) as roles; else renderLoaderView"
    [ngTemplateOutlet]="renderCheck" [ngTemplateOutletContext]="{roles: roles}"
></ng-container>


<ng-template #renderAlreadyAccess>
    <div class="alert alert-success" role="alert">
        <h4 class="alert-heading fw-bold">
            {{"admin.Authorized" | translate}}
        </h4>
        <p>
            {{"admin.The address" | translate}} <strong>{{contract | truncateWalletAddress}}</strong> {{"admin.has access to Main Token." | translate}}
        </p>
    </div>
</ng-template>

<ng-template #renderNoAccess>
    <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">
            {{"admin.No Authorized" | translate}}
        </h4>
        <hr>
        <p>
            {{"admin.The address" | translate}} <strong>{{contract | truncateWalletAddress}}</strong> {{"admin.does not have access to Main Token" | translate}}.
            <br>
           {{"admin.Please click" | translate}} <span class="badge bg-secondary" (click)="onSubmit()"><i class="bi bi-key-fill"></i> {{"admin.here" | translate}}</span> {{"admin.to give permissions to the contract on the main token" | translate}}
        </p>
    </div>
</ng-template>

<ng-template #renderCheck let-roles="roles">
    <ng-container *ngIf="(roles.isUser); then renderAlreadyAccess else renderNoAccess"></ng-container>
</ng-template>

<ng-template #renderLoaderView>
    <app-loading-view-card></app-loading-view-card>
</ng-template>