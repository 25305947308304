import { Inject, Injectable } from "@angular/core";
import { WEB3 } from "../../core/web3";
import { Subject, from, map, Observable, of, tap, BehaviorSubject } from "rxjs";
import Web3 from "web3";
// import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";

import { provider } from "web3-core";
import { AbiService } from "src/app/services/contract/abi.service";
import { environment } from "src/environments/environment";
import { customArrayNumber, toGwei, toWei } from "src/app/helpers/utils";

import Swal from "sweetalert2";
import BigNumber from "bignumber.js";

import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { CustomTranslateService } from "../custom-translate.service";
import { TranslatePipe } from "@ngx-translate/core";

/// @dev walletconnect
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { Router } from "@angular/router";

import { BscGasService } from "./bsc-gas.service"
// import Web3 from 'web3';
declare let window: any;
const METHOD_CONNECT = '__eth_connect';
const WALLET_CONNECT = '__eth_wallet';


@Injectable({
  providedIn: "root",
})

export class Web3Service {
  public accountsObservable = new Subject<string[]>();

  public accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  public web3Modal: any;
  public web3js: any;

  /** Instancia del provider */
  public provider: provider | any;

  /** Tipo de provider utilizado para conectar */
  private providerType: any;

  ethereumProvider: any;

  public accounts: any | undefined;
  public balance: string | undefined;
  public uToken: any;

  public erc20ABI = "/assets/abi/erc20.json";
  public erc721ABI = "/assets/abi/erc721.json";
  public swapABI = "/assets/abi/Swap.json";

  /** Stake ABI */
  public mainTokenABI = "/assets/abi/REMITT.json";

  /** Stake ABI */
  public stakeABI = "/assets/abi/Stake.json";

  /** Vendor V2 ABI */
  public vendorV2ABI = "/assets/abi/VendorV2.json";

  /** Partner Distribution ABI */
  public partnerDistributionABI = "/assets/abi/PartnetDistribution.json";

  /** Chainlink Oracle ABI */
  public oracleABI = "/assets/abi/OracleABI.json";

  public priceTokens: any;

  public isAdminSwap: boolean = false;
  public isUserRed: boolean = false;
  public isUserAdm: boolean = false;
  public isAdminSwap$ = new BehaviorSubject<any>(this.isAdminSwap);
  public isUserRed$ = new BehaviorSubject<any>(this.isUserRed);
  public isUserAdm$ = new BehaviorSubject<any>(this.isUserAdm);

  public isMetamask: boolean = false;
  public isWalletConnect: boolean = false;


  constructor(
    @Inject(WEB3) private web3: Web3,
    public abiService: AbiService,
    private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService,
    private router: Router,
    private bscGasSrv: BscGasService
  ) { }

  /**
   * Preguntar tipo de conexión a establecer
   */
  async launchAskConnectionType() {
    const messageA = await this.translatePipe.transform(
      "home-dashboard.global.cancel2"
    );
    const swalPromise = new Promise((resolve, reject) => {

      Swal.fire({
        title: 'Connect With',
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        html:
          '<div class="d-grid gap-2">' +
          '<button id="metamask-button" class="swal2-confirm swal2-styled">' +
          // '<img src="assets/img/metamask_logo.png" alt="Metamask" style="max-height: 16px;">' +
          'Metamask' +
          '</button>' +
          '<button id="walletconnect-button" class="swal2-confirm swal2-styled">' +
          // '<img src="assets/img/walletconnect_logo.png" alt="WalletConnect" style="max-height: 16px;">' +
          'walletConnect' +
          '</button>' +
          '<button id="cancel-button" class="swal2-cancel swal2-styled"><i class="bi bi-x-lg"></i>' +
          messageA +
          ' </button>' +
          '</div>',
        didOpen: () => {
          document.getElementById('metamask-button').addEventListener('click', () => {
            Swal.close();
            resolve('metamask');
          });

          document.getElementById('walletconnect-button').addEventListener('click', () => {
            Swal.close();
            resolve('walletconnect');
          });

          document.getElementById('cancel-button').addEventListener('click', () => {
            Swal.close();
            resolve('cancel');
          });
        },
      });
    });

    const selectedOption = await swalPromise;
    // console.log('selectedOption', selectedOption);
    switch (selectedOption) {
      case 'metamask': return this.connectAccountMetaMask();
      case 'walletconnect': return this.connectAccountWalletConnect();
      default:
        // console.log('cancel');
        return
    }
  }

  /**
   * Verificar si existe una sesión activa
   */
  checkAlreadyConnected() {
    const method = localStorage.getItem(METHOD_CONNECT);

    switch (method) {
      case "1":
        this.connectAccountWalletConnect();
        break;

      case "2":
        this.connectAccountMetaMask();
        break;

      default:
        console.log("No session found");
        break;
    }
  }

  /**
   * @dev conectar con metamask
   */
  connectAccountMetaMask() {
    console.log('connectAccountMetaMask....');
    return new Promise(async (resolve, reject) => {
      try {
        /// @dev cargar provider in web3
        this.web3js = new Web3(window.ethereum);

        /// @dev check if metamask is installed
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        /// @dev get accounts
        this.accounts = await this.web3js.eth.getAccounts();
        console.warn('this.accounts', this.accounts);

        /// @dev cuando se conecta con metamask send accounts
        this.accountStatusSource.next(this.accounts);

        /// @dev check network
        await this.checkNetworkLocal();

        /// @dev initialize events
        this.initializeEventsMetamask();

        /// @dev guardar metodo de conexion
        localStorage.setItem(WALLET_CONNECT, this.accounts[0]);

        /// @dev guardar metodo de conexion
        localStorage.setItem(METHOD_CONNECT, "2");

        resolve(this.accounts);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  /**
   * @dev conectar con wallet connect
   */
  async connectAccountWalletConnect() {
    console.log('connectAccount  wallet conect....');
    return new Promise(async (resolve, reject) => {
      try {
        // @dev inicializar provider
        this.ethereumProvider = await this.ethereumProviderInit()

        // @sesion de wallet conectada
        console.log('this.ethereumProvider', this.ethereumProvider.signer);

        // @dev enable provider
        await this.ethereumProvider.enable();

        /// @dev cargar provider in web3
        this.web3js = new Web3(this.ethereumProvider);

        this.accounts = await this.web3js.eth.getAccounts();

        console.log('this.accounts', this.accounts);
        this.accountStatusSource.next(this.accounts);

        this.initializeEventsWalletconnect();

        /// @dev guardar metodo de conexion
        localStorage.setItem(METHOD_CONNECT, "1");

        /// @dev 
        localStorage.setItem(WALLET_CONNECT, this.accounts[0]);

        resolve(this.accounts);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  /**
   * @dev Initialize walletconnect events
   */
  private async initializeEventsWalletconnect() {
    this.ethereumProvider.on("connect", async () => {
      console.log('connect');
    });

    // @dev cuando se desconecta
    this.ethereumProvider.on("disconnect", async () => {
      console.log('disconnect');
      window.location.reload();
    });


    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on("session_update", async (error: any, payload: any) => {
      console.log('session_update');
    });

    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on("accountsChanged", async (accounts: any) => {
      console.log('accountsChanged');
      // window.location.reload();
    });


    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on("chainChanged", async (chainId: any) => {
      console.log('chainChanged');
    });
  }

  /**
   * @dev Initialize metamask events
   */
  private initializeEventsMetamask() {
    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      console.log('Accounts changed:', accounts);
      // Implement logic for handling account changes
      window.location.reload();
    });

    window.ethereum.on('chainChanged', (chainId: string) => {
      console.log('Chain changed:', chainId);
      // Implement logic for handling chain changes
      window.location.reload();
    });

    window.ethereum.on('connect', (connectInfo: { chainId: string }) => {
      console.log('Connected:', connectInfo.chainId);
      // Implement logic for handling connection
      window.location.reload();
    });

    window.ethereum.on('disconnect', (error: { code: number, message: string }) => {
      console.log('Disconnected:', error.message);
      // Implement logic for handling disconnection
      window.location.reload();
    });
  }

  /**
   * @dev Initialize ethereum provider
   */
  async ethereumProviderInit() {
    const result = await EthereumProvider.init({
      projectId: environment.chain.walletConnectID,
      showQrModal: true,
      qrModalOptions: {
        themeVariables: {
          '--wcm-accent-color': '#48e120',
          '--wcm-accent-fill-color': 'white',
          '--wcm-background-color': '#48e120'
        }
      },
      chains: [environment.chain.chainId],
      rpcMap: { [environment.chain.chainId]: environment.chain.rpc },
      methods: ["eth_sendTransaction", "personal_sign"],
      events: ["chainChanged", "accountsChanged"],
      metadata: {
        name: "Remitt",
        description: "Swap remitt",
        url: "https://dev-remitt.web.app/",
        icons: [""],
      },
    });

    return result;
  }

  /**
   * @dev Logout from web3 provider
   * @param reload              Reload page
   */
  async logout(reload = true) {
    const method = localStorage.getItem(METHOD_CONNECT);
    if (method == "1") {
      await this.ethereumProvider.disconnect();
    }

    window.localStorage.clear();
    this.accounts = null
    this.provider = null
    this.accountStatusSource.next(null);

    if (reload) { window.location.reload(); }
  }


  /**
   * TODO: verificar antes de eliminar
   * @returns 
   */
  async logoutRedirect() {
    await this.router.navigate([`/`]);
    return true;
  }


  /**
   * TODO: verificar antes de eliminar
   */
  async getInfoTypeUser() {
    const isUserRed = await this.swap_userWalletExist(this.accounts[0]);

    this.setIsUser(isUserRed);

    const isAdmin = await this.swap_security_isAdmin(this.accounts[0]);

    this.setIsAdmin(isAdmin);

    const isUserAdm = await this.swap_security_isUser(this.accounts[0]);

    this.setIsUserAdm(isUserAdm);
  }

  async accountInfo(account: any[]) {
    const initialvalue = await this.web3js.eth.getBalance(account);
    this.balance = this.web3js.utils.fromWei(initialvalue, "ether");
    return this.balance;
  }

  /**
   * Validar Conexion de WalletConnect
   * - Validar que la red sea la correcta
   * @returns
   */
  async checkWalletConnectProviderConnection() {
    /** Validar si corresponde al provider */
    if (this.providerType !== 'walletconnect') return true;

    /** Validar si es la red correcta */
    const providerChainId = await this.web3js.eth.net.getId();
    if (providerChainId == environment.chain.chainId) return true;

    await this.web3Modal.clearCachedProvider();
    await this.provider.close();
    this.provider = null;

    const messageD = await this.translatePipe.transform("home-dashboard.web3-service.messageD");

    Swal.fire({
      title: "Error",
      icon: "error",
      text: `${messageD} ${environment.chain.chainName}`,
    });

    return false;
  }

  /**
   * @dev Válidar conexión de metamask
   * - Validar que la red sea la correcta
   */
  async checkNetworkLocal() {
    const chainId = await this.web3js.eth.net.getId();

    const [
      messageB,
      messageC,
      buttonC
    ] = await Promise.all([
      this.translatePipe.transform("home-dashboard.web3-service.messageB"),
      this.translatePipe.transform("home-dashboard.web3-service.messageC"),
      this.translatePipe.transform("home-dashboard.web3-service.buttonC")
    ]);

    if (chainId != environment.chain.chainId) {
      const modalChangeChain = await Swal.fire({
        title: environment.projectName,
        icon: "warning",
        text: `${messageB} ${environment.chain.chainName}, ${messageC}`,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `${buttonC}`,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const runChange = await this.changeChainIdOrAdd();
          } catch (err: any) {
            // console.log('modal error', err);
            Swal.showValidationMessage(`${err.message}`);
          }
        },
      });

      // console.log({modalChangeChain});
    }
  }

  /**
   * @dev Añaadir red a metamask
   * @returns 
   */
  async addChainId() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: environment.chain.chainIdMetamask,
            rpcUrls: environment.chain.rpcUrls,
            chainName: environment.chain.chainName,
            nativeCurrency: {
              name: environment.chain.nativeCurrency.name,
              symbol: environment.chain.nativeCurrency.symbol,
              decimals: environment.chain.nativeCurrency.decimals
            },
            blockExplorerUrls: environment.chain.blockExplorerUrls,
          }
        ],
      });

      // console.log({wasAdded});
      return wasAdded;
    } catch (err: any) {
      alert("Please connect to Binance Smart Chain");
    }
  }

  /**
   * Disparar método para cambiar de red o añadirla
   * @returns
   */
  async changeChainIdOrAdd() {
    try {
      const tryChange = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: environment.chain.chainIdMetamask }],
      });

      // console.log({tryChange});

      return tryChange;
    } catch (err: any) {
      /** Si no tiene la red registrada */
      if (err.code === 4902) {
        return await this.addChainId();
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === 4001) {
        err.message = await this.translatePipe.transform(
          "home-dashboard.web3-service.messageE"
        );
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === -32002) {
        err.message = await this.translatePipe.transform(
          "home-dashboard.web3-service.messageF"
        );
      }

      throw err;
    }
  }

  /**
   * @name eventsAll
   */
  eventsAll() {
    // Subscribe to accounts change
    this.provider.on("accountsChanged", (accounts: string[]) => {
      console.warn("accountsChanged", accounts);
      // this.connectAccount();
      // this.checkNetworkLocal().then(() => {
      //   this.getInfoTypeUser();
      // });
      window.location.reload();
    });

    // Subscribe to chainId change
    this.provider.on("chainChanged", (chainId: number) => {
      console.log("chainChanged", chainId);

      this.checkNetworkLocal().then(() => {
        this.getInfoTypeUser();
      });
      // .finally(()=> { window.location.reload()});

      // this.reInitializating()
    });

    // Subscribe to provider connection
    this.provider.on("connect", (info: { chainId: number }) => {
      // this.reInitializating()
    });

    // Subscribe to provider disconnection
    this.provider.on(
      "disconnect",
      (error: { code: number; message: string }) => {
        console.log("disconnect", error);
        window.location.reload()

      }
    );
  }

  /**
   * TODO: revisar antes de eliminar
   * Metjodos para cambiar de red en wc
   */
  async checkNetworkLocalWc() {
    const chainId = await this.ethereumProvider.request({
      method: "eth_chainId"
    })
    console.log("chain on checkNetworkLocalWc", chainId);
    console.log("chain //////////////", environment.chain.chainId);

    const messageB = await this.translatePipe.transform(
      "home-dashboard.web3-service.messageB"
    );
    const messageC = await this.translatePipe.transform(
      "home-dashboard.web3-service.messageC"
    );
    const buttonC = await this.translatePipe.transform(
      "home-dashboard.web3-service.buttonC"
    );

    if (chainId != environment.chain.chainId) {
      const modalChangeChain = await Swal.fire({
        title: environment.projectName,
        icon: "warning",
        text: `${messageB} ${environment.chain.chainName}, ${messageC}`,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `${buttonC}`,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const runChange = await this.changeChainIdOrAddWc();
          } catch (err: any) {
            // console.log('modal error', err);
            Swal.showValidationMessage(`${err.message}`);
          }
        },
      });

      // console.log({modalChangeChain});
    }
  }

  /**
   * Disparar método para cambiar de red o añadirla
   * TODO: revisar antes de eliminar
   * @returns
   */
  async changeChainIdOrAddWc() {
    console.log("llega hasta changeChainIdOrAddWc");
    try {
      const tryChange = await this.ethereumProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: environment.chain.chainIdMetamask }],
      });
      console.log("-------------------------------", this.ethereumProvider);

      console.log("/////////////////////////////////////////", tryChange);

      return tryChange;
    } catch (err: any) {
      /** Si no tiene la red registrada */
      if (err.code === 4902) {
        return await this.addChainId();
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === 4001) {
        err.message = await this.translatePipe.transform(
          "home-dashboard.web3-service.messageE"
        );
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === -32002) {
        err.message = await this.translatePipe.transform(
          "home-dashboard.web3-service.messageF"
        );
      }

      throw err;
    }
  }

  /**
   * TODO: revisar antes de eliminar
   */
  async refreshSuscription() {
    // this.accountStatusSource.next(this.accounts);
    // await this.getInfoTypeUser();
    // return this.accounts[0];
  }


  /* =======================================================
   *                      NATIVE
   * ===================================================== */

  /**
   * Obtiene el balance de token nativo
   * @param account
   * @returns
   */
  async balanceOfNative(account: string) {
    return new Promise((resolve, reject) => {
      this.web3js.eth.getBalance(account, (err: any, res: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  /**
   * Válida balance de usuario en token nativo
   * @param amount
   * @returns
   */
  async checkUserBalanceNative(amount: string) {
    const balance = await this.balanceOfNative(this.accounts[0]);
    const balanceParse = new BigNumber(`${balance}`);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }

  /* =======================================================
   *                      ERC20
   * ===================================================== */

  /**
   *
   * @param erc20Contract
   * @param contractAddress
   * @param amount
   * @returns
   */
  async approve2(
    erc20Contract: string,
    contractAddress: string,
    amount: string
  ) {
    const [account] = this.accounts;
    return await this.calculateAndCallCustomABI({
      contractAddress: erc20Contract,
      method: "approve",
      params: [contractAddress, amount],
      callType: "send",
      optionals: { from: account },
      urlABI: this.erc20ABI,
    });
  }

  /**
   * Consultar balance de token ERC20
   * @param contractAddress               Dirección del contrato
   * @param account                       Dirección de la wallet
   * @returns
   */
  async balanceOfERC20(contractAddress: string, account: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: contractAddress,
      method: "balanceOf",
      params: [account],
      callType: "call",
      urlABI: this.erc20ABI,
    });
  }

  /**
   * Verificar balance de usuario
   * @param contractAddress               Dirección del contrato
   * @param amount                        Cantidad a transferir
   * @returns
   */
  async checkUserBalanceERC20(contractAddress: string, amount: any) {
    const [account] = this.accounts;
    const balance = await this.balanceOfERC20(contractAddress, account);
    const balanceParse = new BigNumber(balance);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }

  /* =======================================================
   *                         SWAP
   * ===================================================== */

  async swap_getOracle(index: any) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getOracle",
      params: [index],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_getPriceToken() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getPriceToken",
      params: [],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /**
   *
   * @param _addr
   * @returns
   */
  async getUSDPrice(_addr: any) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getUSDPrice",
      params: [_addr],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /**
   *
   * @param oracle
   * @param decimals
   * @returns
   */
  async swap_getLatestPrice(oracle: string, decimals: any) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getLatestPrice",
      params: [oracle, decimals],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_buy(amount: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "buy",
      callType: "call",
      urlABI: this.swapABI,
      optionals: { value: amount },
    });
  }

  async swap_countPartners() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "countPartners",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_partners(index: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "partners",
      callType: "call",
      params: [index],
      urlABI: this.swapABI,
    });
  }

  async swap_maxLevel() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "maxLevel",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_networkBonds(index: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "networkBonds",
      callType: "call",
      params: [index],
      urlABI: this.swapABI,
    });
  }

  async swap_userCodeExist(code: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "userCodeExist",
      params: [code],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /**
   * Válidar si una billetera ya se encuentra registrada en la plataforma
   * @param addr Dirección de billetera
   * @returns
   */
  async swap_userWalletExist(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "userWalletExist",
      params: [addr],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /**
   * Obtener usuario a través del código de referido
   * @param code
   * @returns
   */
  async swap_getUserByCode(code: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getUserByCode",
      params: [code],
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /* =======================================================
   *                     ERC20 Methods
   * ===================================================== */

  async erc20_approve(
    erc20Contract: string,
    contractAddress: string,
    amount: string
  ) {
    const [account] = this.accounts;
    return await this.calculateAndCallCustomABI({
      contractAddress: erc20Contract,
      method: "approve",
      params: [contractAddress, amount],
      callType: "send",
      optionals: { from: account },
      urlABI: this.erc20ABI,
    });
  }

  /**
   * Consultar balance de token ERC20
   * @param contractAddress               Dirección del contrato
   * @param account                       Dirección de la wallet
   * @returns
   */
  async erc20_balanceOf(contractAddress: string, account: string) {
    // return await this.calculateAndCallCustomABI({
    //   contractAddress: contractAddress,
    //   method: "balanceOf",
    //   params: [account],
    //   callType: "call",
    //   urlABI: this.erc20ABI,
    // });
    return await this.callDinamyContractOffLine({
      contractAddress: contractAddress,
      method: "balanceOf",
      callType: "call",
      params: [account],
      urlABI: this.erc20ABI,
    });
  }

  /**
   *
   * @param account
   * @returns
   */
  getBalanceEther(account: string) {
    return new Promise((resolve, reject) => {
      this.web3js.eth.getBalance(account, (err: any, res: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  /**
   * Verificar balance de usuario
   * @param contractAddress               Dirección del contrato
   * @param amount                        Cantidad a transferir en WEI
   * @returns
   */
  async erc20_checkUserBalance(contractAddress: string, amount: any) {
    const [account] = this.accounts;
    const balance = await this.erc20_balanceOf(contractAddress, account);
    const balanceParse = new BigNumber(balance);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }

  /* =======================================================
   *                        STAKE
   * ===================================================== */

  // async stake_stakeList() {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "stakeList",
  //     callType: "call",
  //     urlABI: this.stakeABI,
  //   });
  // }

  async stake_registerStake(
    _rewardRate: any,
    _rewardPerMonth: any,
    _day: any,
    _status: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "registerStake",
      params: [_rewardRate, _rewardPerMonth, _day, _status],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  // async stake_changeMinStaked(_minStaked: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "changeMinStaked",
  //     params: [_minStaked],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }


  // async stake_editStaking(type: number, id: any, uint: string, bool: boolean) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "editStaking",
  //     params: [type, id, uint, bool],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  // async stake_security_addAdmin(wallet: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "addAdmin",
  //     params: [wallet],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  // async stake_security_addUser(wallet: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "addUser",
  //     params: [wallet],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  // async stake_security_removeUser(wallet: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "removeUser",
  //     params: [wallet],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  // async stake_security_renounceAdmin(wallet: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "renounceAdmin",
  //     params: [wallet],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  async stake_enableStake(sId: any) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "enableStake",
      params: [sId],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   *
   * @param amount
   * @param sId
   * @returns
   */
  // async stake_stake(amount: string, sId: string) {
  //   console.log(amount, sId);
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "stake",
  //     params: [amount, sId.toString()],
  //     callType: "send",
  //     urlABI: this.stakeABI,
  //   });
  // }

  /**
   *
   * @returns
   */
  async stake_hasStakeTokenstake() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "hasStake",
      params: [this.accounts[0]],
      callType: "call",
      urlABI: this.stakeABI,
    });
  }

  async stake_claim(amount: string, index: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawStake",
      params: [amount, index],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  async stake_withdraw(amount: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdraw",
      params: [amount],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  // async stake_changeLimit(newLimit: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.stake,
  //     method: "changeLimit",
  //     callType: "send",
  //     params: [newLimit],
  //     urlABI: this.stakeABI,
  //   });
  // }

  async stake_getMintCounter() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "getMintCounter",
      callType: "call",
      urlABI: this.stakeABI,
    });
  }

  async stake_getMintLimit() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "getMintLimit",
      callType: "call",
      urlABI: this.stakeABI,
    });
  }

  async stake_security_isAdmin(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.stakeABI,
    });
  }

  async stake_security_isUser(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.stakeABI,
    });
  }

  /** ===============================================================
   *               Méthodo para swap
   * ================================================================
   */

  async swap_buyToken(
    type: string,
    isNative: boolean,
    amount: string,
    code: string
  ) {
    const [account] = this.accounts;

    const val = isNative ? amount : 0;

    console.log("swap_buyToken", account, type, isNative, amount, code, val);

    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "buyToken",
      params: [type, isNative, amount, code],
      callType: "send",
      optionals: { from: account, value: val },
      urlABI: this.swapABI,
    });
  }

  /**
   *
   * @returns
   */
  async swap_priceTokens() {
    const [account] = this.accounts;
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "priceTokens",
      params: [],
      callType: "call",
      optionals: { from: account },
      urlABI: this.swapABI,
    });
  }

  // async swap_distributionPercentage(type: number, newValue: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "distributionPercentage",
  //     params: [type, newValue],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_setReserveWallet(address: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "setReserveWallet",
  //     params: [address],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_setDevWallet(address: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "setReserveDev",
  //     params: [address],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_addToken(
  //   address: string,
  //   orc: string,
  //   decimals: number,
  //   active: boolean,
  //   native: boolean
  // ) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addToken",
  //     params: [address, orc, decimals, active, native],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_updateToken(
  //   id: number,
  //   type: number,
  //   address: string,
  //   dcm: number,
  //   bool: boolean
  // ) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "updateToken",
  //     params: [id, type, address, dcm, bool],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_updateUserAsAdmin(
  //   id: number,
  //   type: number,
  //   num: number,
  //   bool: boolean
  // ) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "updateUserAsAdmin",
  //     params: [id, type, num, bool],
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  async swap_security_isAdmin(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.swapABI,
    });
  }

  async swap_security_isUser(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.swapABI,
    });
  }

  // async swap_security_addAdmin(addr: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addAdmin",
  //     callType: "send",
  //     params: [addr],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_security_addUser(addr: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addUser",
  //     callType: "send",
  //     params: [addr],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_security_removeUser(addr: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "removeUser",
  //     callType: "send",
  //     params: [addr],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_addPartner(partner: string, percentage: string, active: boolean) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addPartner",
  //     callType: "send",
  //     params: [partner, percentage, active],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_editPartner(
  //   index: number,
  //   partner: string,
  //   percentage: string,
  //   active: boolean
  // ) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "editPartner",
  //     callType: "send",
  //     params: [index, partner, percentage, active],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_addUserRedAsAdmin(
  //   code: string,
  //   wallet: string,
  //   referredBy: string
  // ) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addUserRedAsAdmin",
  //     callType: "send",
  //     params: [code.toLowerCase(), wallet, referredBy],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_addUserRed(code: string, referredBy: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "addUserRed",
  //     callType: "send",
  //     params: [code.toLowerCase(), referredBy],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_security_renounceAdmin() {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "renounceAdmin",
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_whitdrawal_withdraw(amount: any) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "withdrawOwner",
  //     callType: "send",
  //     params: [amount],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_whitdrawal_withdrawToken(erc20: any, amount: any) {
  //   const [addr] = this.accounts;
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "withdrawToken",
  //     callType: "send",
  //     params: [erc20, amount],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_auxWithdrawOwner() {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "auxWithdrawOwner",
  //     callType: "send",
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_auxWithdrawToken(erc20: any) {
  //   const [addr] = this.accounts;
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "auxWithdrawToken",
  //     callType: "send",
  //     params: [erc20],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_setPriceTokens(amount: any) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "setPriceTokens",
  //     callType: "send",
  //     params: [amount],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_setNetworkBonds(index: number, percentage: number) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "setNetworkBonds",
  //     callType: "send",
  //     params: [index, percentage],
  //     urlABI: this.swapABI,
  //   });
  // }

  // async swap_setMaxLevel(level: number) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "setMaxLevel",
  //     callType: "send",
  //     params: [level],
  //     urlABI: this.swapABI,
  //   });
  // }
  async swap_oracle_parseUSDtoToken(
    _amount: string,
    _token: string,
    _isNative: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "parseUSDtoToken",
      callType: "call",
      params: [_amount, _token, _isNative],
      urlABI: this.swapABI,
    });
  }

  async swap_listUsersCount() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "listUsersCount",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_countSale() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "countSale",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_countSales(code: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "countSales",
      callType: "call",
      params: [code],
      urlABI: this.swapABI,
    });
  }

  async swap_getSale(type: number, user: string, pos: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getSale",
      callType: "call",
      params: [type, user, pos],
      urlABI: this.swapABI,
    });
  }

  async swap_reservePercentage() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "reservePercentage",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_reserveDevPercentage() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "reserveDevPercentage",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_referralBonusPercentage() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "referralBonusPercentage",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_partnerPercentage() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "partnerPercentage",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_getUserListPaginated(from: number, to: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getUserListPaginated",
      callType: "call",
      params: [from, to],
      urlABI: this.swapABI,
    });
  }

  // async swap_changeLimit(newLimit: string) {
  //   return await this.calculateAndCallCustomABI({
  //     contractAddress: environment.contract.swap,
  //     method: "changeLimit",
  //     callType: "send",
  //     params: [newLimit],
  //     urlABI: this.swapABI,
  //   });
  // }

  async swap_getMintCounter() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getMintCounter",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  async swap_getMintLimit() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "getMintLimit",
      callType: "call",
      urlABI: this.swapABI,
    });
  }

  /** ===============================================================
   *      Observables para tranportar informacion de la bolletera
   * ================================================================
   **/

  get userRed$(): Observable<any> {
    return this.isUserRed$.asObservable();
  }

  setIsUser(is: boolean) {
    this.isUserRed$.next(is);
  }

  get swapAdm$(): Observable<any> {
    return this.isAdminSwap$.asObservable();
  }

  setIsAdmin(is: boolean) {
    this.isAdminSwap$.next(is);
  }

  get swapUserAdm$(): Observable<any> {
    return this.isUserAdm$.asObservable();
  }

  setIsUserAdm(is: boolean) {
    this.isUserAdm$.next(is);
  }




  /** ====================================================================
   *                 MAIN TOKEN Administered METHODS
   ===================================================================== */

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como usuario administrativo
   * en la plataforma de manera OFFLINE. 
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async mainToken_administered_isUser_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.mainToken.contract,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.mainTokenABI,
    });
  }

  /**
   * @dev Establecer una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async mainToken_administered_addUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.mainToken.contract,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.mainTokenABI,
    });
  }

  /**
   * @dev Remover una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async mainToken_administered_removeUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.mainToken.contract,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.mainTokenABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como administrador
   * en la plataforma de manera OFFLINE.
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async mainToken_administered_isAdmin_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.mainToken.contract,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.mainTokenABI,
    });
  }

  /**
   * @dev Establecer una billetera como administrador en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async mainToken_administered_addAdmin(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.mainToken.contract,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.mainTokenABI,
    });
  }

  /**
   * @dev Remover una billetera como administrador en la plataforma
   * 
   * @returns 
   */
  async mainToken_administered_renounceAdmin() {
    const [addr] = this.accounts;
    return this.calculateAndCallCustomABI({
      contractAddress: environment.mainToken.contract,
      method: "renounceAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.mainTokenABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Administered METHODS
   ===================================================================== */

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como usuario administrativo
   * en la plataforma de manera OFFLINE. 
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async vendorV2_administered_isUser_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Establecer una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async vendorV2_administered_addUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Remover una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async vendorV2_administered_removeUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como administrador
   * en la plataforma de manera OFFLINE.
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async vendorV2_administered_isAdmin_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Establecer una billetera como administrador en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async vendorV2_administered_addAdmin(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Remover una billetera como administrador en la plataforma
   * 
   * @returns 
   */
  async vendorV2_administered_renounceAdmin() {
    const [addr] = this.accounts;
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "renounceAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Users METHODS
   ===================================================================== */

  /**
   * @dev Obtener el número de usuarios registrados en la plataforma
   * 
   * @returns {Promise<number>}
   */
  async vendorV2_users_listUsersCount_OFFCHAIN(): Promise<number> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "listUsersCount",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada 
   * en la plataforma como usuario de manera OFFLINE.
   * @param addr                              Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async vendorV2_users_userWalletExist_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "userWalletExist",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Verificar si un código de usuario ya se encuentra registrado
   * @param code                              Código de usuario
   * 
   * @returns {Promise<boolean>}
   */
  async vendorV2_users_userCodeExist_OFFCHAIN(code: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "userCodeExist",
      callType: "call",
      params: [code],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener usuario a través del nickname
   * @param code                              Código de usuario
   * @returns 
   */
  async vendorV2_users_getUserByCode_OFFCHAIN(code: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getUserByCode",
      callType: "call",
      params: [code],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener usuario a través de la dirección de billetera
   * @param addr                              Dirección de billetera
   * @returns 
   */
  async vendorV2_users_getUserByWallet_OFFCHAIN(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getUserByWallet",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener lista de usuarios de manera paginada
   * @param f                 Desde
   * @param t                 Hasta
   * 
   * @returns 
   */
  async vendorV2_users_getUserListPaginated_OFFCHAIN(f: number, t: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getUserListPaginated",
      callType: "call",
      params: [f, t],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener metadata de un usuario a través del código de usuario
   * @param code                Código de usuario
   * 
   * @returns 
   */
  async vendorV2_users_listUsersCode_OFFCHAIN(code: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "listUsersCode",
      callType: "call",
      params: [code],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener metadata de un usuario a través de la dirección de billetera
   * @param addr                  Dirección de billetera
   * 
   * @returns 
   */
  async vendorV2_users_listUserWallet_OFFCHAIN(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "listUserWallet",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener struct de un usuario a través del índice
   * @param idx                 Índice
   * 
   * @returns 
   */
  async vendorV2_users_listUsers_OFFCHAIN(idx: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "listUsers",
      callType: "call",
      params: [idx],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Registrar usuario en la plataforma
   * @param code                      Nickname del usuario
   * @param referredBy                Código de referido
   * 
   * Requerimientos:
   * - El código de referido debe existir en la plataforma
   * - La billetera no debe estar registrada en la plataforma
   * - El nickname no debe estar registrado en la plataforma
   * 
   * @returns 
   */
  async vendorV2_users_addUserRed(code: string, referredBy: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addUserRed",
      params: [code, referredBy],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Registrar usuario en la plataforma de manera administrativa
   * @param code                      Nickname del usuario
   * @param addr                      Dirección de billetera del usuario
   * @param referredBy                Código de referido
   * 
   * Requerimientos:
   * - El código de referido debe existir en la plataforma
   * - La billetera no debe estar registrada en la plataforma
   * - El nickname no debe estar registrado en la plataforma
   * - Solo un administrador puede registrar usuarios
   * 
   * @returns 
   */
  async vendorV2_users_addUserRedAsAdmin(code: string, addr: string, referredBy: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addUserRedAsAdmin",
      params: [code, addr, referredBy],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Actualizar datos de un usuario en la plataforma de manera administrativa
   * @param idx                         Índice del usuario
   * @param ot                          Tipo de operación
   * @param value                       Nuevo valor
   * @param bool                        Nuevo valor booleano
   * 
   * 
   * Requerimientos:
   * - Solo un administrador puede actualizar datos de usuarios
   * 
   * @returns 
   */
  async vendorV2_users_updateUserAsAdmin(idx: number, ot: number, value: number, bool: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "updateUserAsAdmin",
      params: [idx, ot, value, bool],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Partner METHODS
   ===================================================================== */

  async vendorV2_partner_countPartners_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "countPartners",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_maxLevel_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "maxLevel",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setMaxLevel(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setMaxLevel",
      params: [val],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_referralBonusPercentage_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "referralBonusPg",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }
  
  async vendorV2_partner_partnerPercentage_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "partnerPg",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }
  
  async vendorV2_partner_reservePercentage_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reservePg",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_reserveDevPercentage_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reserveDevPg",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }
  
  async vendorV2_partner_partnerDistributionContract_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "partnerDistributionContract",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_checkPartnersPercentage_OFFCHAIN(idx: number, val: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "checkPartnersPercentage",
      callType: "call",
      params: [idx, val],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Actualizar porcentajes de distribuciones
   * - 0 `referralBonusPercentage`
   * - 1 `partnerPercentage`
   * - 2 `reservePercentage`
   * - 3 `reserveDevPercentage`
   * @param ot                                Tipo de operación
   * @param val                               Nuevo valor
   * 
   * @returns 
   */
  async vendorV2_partner_distributionPercentage(ot: number, val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setDistributionPercentage",
      params: [ot, val],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_reserveWallet_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reserveWallet",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_reserveLimit_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reserveLimit",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setReserveWallet(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setReserveWallet",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setReserveLimit(value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setReserveLimit",
      params: [value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_reserveDev_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reserveDevWallet",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_reserveDevLimit_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "reserveDevLimit",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setReserveDev(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setReserveDev",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setReserveDevLimit(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setReserveDevLimit",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_setPartnerDistributionContract(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setPartnerDistributionContract",
      params: [addr],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_checkNetworkBonds_OFFCHAIN(val: string, idx: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "checkNetworkBonds",
      callType: "call",
      params: [val, idx],
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_networkBonds_OFFCHAIN(idx: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "networkBonds",
      callType: "call",
      params: [idx],
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_get_networkBonds() {
    const total = await this.vendorV2_partner_maxLevel_OFFCHAIN();
    const bonds = customArrayNumber(0, total);
    const snapshot = await Promise.all(
      bonds.map(async (idx: number) => {
        const bond = await this.vendorV2_partner_networkBonds_OFFCHAIN(idx);
        return { idx, bond };
      })
    );
    return snapshot.sort((a: any, b: any) => a.idx - b.idx);
  }

  async vendorV2_partner_setNetworkBonds(idx: number, val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setNetworkBonds",
      params: [idx, val],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_partners_OFFCHAIN(idx: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "partners",
      callType: "call",
      params: [idx],
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_get_partners() {
    const total = await this.vendorV2_partner_countPartners_OFFCHAIN();
    const partners = customArrayNumber(0, total);
    const snapshot = await Promise.all(
      partners.map(async (idx: number) => {
        const partner = await this.vendorV2_partner_partners_OFFCHAIN(idx);
        return { idx, ...partner };
      })
    );
    return snapshot.sort((a: any, b: any) => a.idx - b.idx);
  }

  async vendorV2_partner_addPartner(addr: string, pg: string, act: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addPartner",
      params: [addr, pg, act],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_partner_editPartner(idx: number, addr: string, pg: string, act: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "editPartner",
      params: [idx, addr, pg, act],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /** ====================================================================
   *                   VENDOR V2 Oracle METHODS
   ===================================================================== */

  async vendorV2_oracle_priceTokens_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "priceTokens",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_oracle_setPriceTokens(value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setPriceTokens",
      params: [value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener monto a pagar en tokens por una cantidad de USD
   * @param value                         Cantidad de USD (WEI)
   * @param addr                          Dirección del token
   * @param isNative                      Es token nativo
   * 
   * @returns 
   */
  async vendorV2_oracle_parseUSDtoToken_OFFCHAIN(value: string, addr: string, isNative: boolean) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "parseUSDtoToken",
      callType: "call",
      params: [value, addr, isNative],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener precio de un token en USD desde el oráculo
   * @param addr                            Dirección del token
   * @returns 
   */
  async vendorV2_oracle_getUSDPrice_OFFCHAIN(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getUSDPrice",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener el balance de un token en USD desde el oráculo
   * @param addr                          Dirección de la billetera
   * @returns 
   */
  async vendorV2_oracle_getTotalUSDBalance_OFFCHAIN(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getTotalUSDBalance",
      callType: "call",
      params: [addr],
      urlABI: this.vendorV2ABI,
    });
  }

  /** ====================================================================
   *                   VENDOR V2 Limit METHODS
   ===================================================================== */

  async vendorV2_limit_getMintCounter_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getMintCounter",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_limit_getMintLimit_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "getMintLimit",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_limit_changeLimit(value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "changeLimit",
      params: [value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Whitelist METHODS
   ===================================================================== */

  async vendorV2_whitelist_MAIN_TOKEN_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "MAIN_TOKEN",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_whitelist_setMainToken(value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "setMainToken",
      params: [value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_whitelist_tokensList_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "tokensList",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_whitelist_addToken(
    addr: string, 
    oracle: string,
    oracleDecimals: string, 
    active: boolean, 
    native: boolean
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "addToken",
      params: [
        addr,
        oracle,
        oracleDecimals,
        active,
        native
      ],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  async vendorV2_whitelist_updateToken(
    id: number,
    ot: number,
    addr: string, 
    decimals: string,
    active: boolean,
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "updateToken",
      params: [
        id,
        ot,
        addr,
        decimals,
        active,
      ],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Withdraw METHODS
   ===================================================================== */

  /**
   * @dev Retirar fondos en crypto de la billetera del contrato
   * @param value                   Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async vendorV2_withdraw_withdrawOwner(value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "withdrawOwner",
      params: [value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Retirar fondos en tokens de la billetera del contrato
   * @param token                     Dirección del token
   * @param value                     Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async vendorV2_withdraw_withdrawToken(token: string, value: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "withdrawToken",
      params: [token, value],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }
  
  /**
   * @dev Distribuir fondos en crypto de la billetera del contrato entre los socios
   * 
   * Requerimientos:
   * - Solo un administrador puede distribuir fondos
   * 
   * @returns 
   */
  async vendorV2_withdraw_auxWithdrawOwner() {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "auxWithdrawOwner",
      params: null,
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Distribuir fondos en tokens de la billetera del contrato entre los socios
   * @param token                       Dirección del token
   * 
   * Requerimientos:
   * - Solo un administrador puede distribuir fondos
   * 
   * @returns 
   */
  async vendorV2_withdraw_auxWithdrawToken(token: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "auxWithdrawToken",
      params: [token],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   VENDOR V2 Sales METHODS
   ===================================================================== */

  /**
   * @dev Obtener el número de ventas registradas en la plataforma
   * 
   * @returns 
   */
  async vendorV2_sales_countSale_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "countSale",
      callType: "call",
      params: null,
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Obtener registro de una venta a través del índice
   * @param idx                         Índice
   * 
   * @returns 
   */
  async vendorV2_sales_listAllSales_OFFCHAIN(idx: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "listAllSales",
      callType: "call",
      params: [idx],
      urlABI: this.vendorV2ABI,
    });
  }
  
  /**
   * @dev Obtener cantidad de ventas de un usuario a través del código de usuario
   * @param code                        Código de usuario
   * 
   * @returns 
   */
  async vendorV2_sales_countSales_OFFCHAIN(code: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.swap,
      method: "countSales",
      callType: "call",
      params: [code],
      urlABI: this.vendorV2ABI,
    });
  }


  /** ====================================================================
   *                   Vendor Buy METHODS
   ===================================================================== */

  /**
   * @dev Comprar tokens de la plataforma
   * @param ot                        Tipo de operación
   * @param amount                    Cantidad de tokens a comprar
   * @param token                     Dirección del token con el que se va a comprar
   * @param refCode                   Código de referido
   * @param value                     Cantidad de ETH a enviar (WEI) en caso de ser compra con cripto
   * 
   * @returns 
   */
  async vendorV2_vendorBuy_buyWithCode(
    ot: any,
    amount: string,
    token: string,
    refCode: string,
    value: string = '0'
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "buyWithCode",
      params: [
        [ot, amount, token, refCode]
      ],
      callType: "send",
      optionals: { value: value },
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Comprar tokens de la plataforma de manera anónima
   * @param amount                Cantidad de tokens a comprar
   * @param token                 Dirección del token con el que se va a comprar
   * @param value                 Cantidad de ETH a enviar (WEI) en caso de ser compra con cripto
   * @returns 
   */
  async vendorV2_vendorBuy_buyAnonymous(
    amount: string,
    token: string,
    value: string = '0'
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "buyAnonymous",
      params: [amount, token],
      callType: "send",
      optionals: { value: value },
      urlABI: this.vendorV2ABI,
    });
  }

  /**
   * @dev Vender tokens de la plataforma
   * @param ot                        Tipo de operación
   * @param amount                    Cantidad de tokens a vender
   * @param token                     Dirección del token a recibir
   * @param refCode                   Código de referido
   * 
   * @returns 
   */
  async vendorV2_vendorSell_sellToken(
    ot: any,
    amount: string,
    token: string,
    refCode: string
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.swap,
      method: "sellToken",
      params: [
        [ot, amount, token, refCode]
      ],
      callType: "send",
      urlABI: this.vendorV2ABI,
    });
  }

  
  /** ====================================================================
   *                   STAKE Administered METHODS
   ===================================================================== */

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como usuario administrativo
   * en la plataforma de manera OFFLINE. 
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async stake_administered_isUser_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Establecer una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async stake_administered_addUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Remover una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async stake_administered_removeUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como administrador
   * en la plataforma de manera OFFLINE.
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async stake_administered_isAdmin_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Establecer una billetera como administrador en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async stake_administered_addAdmin(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Remover una billetera como administrador en la plataforma
   * 
   * @returns 
   */
  async stake_administered_renounceAdmin() {
    const [addr] = this.accounts;
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "renounceAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Staked METHODS
   ===================================================================== */

  /**
   * @dev Registrar opción de stake en la plataforma
   * @param rewardRate                    Porcentaje de recompensa
   * @param rewardPerMonth                Recompensa por mes
   * @param days                          Días de stake
   * @param status                        Estado de la opción
   * 
   * Requerimientos:
   * - Solo un usuario administrativo puede registrar opciones de stake
   * 
   * @returns 
   */
  async stake_staked_storeStakeOption(
    days: any,
    rewardRate: any,
    status: any
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "storeStakeOption",
      params: [
        days,
        rewardRate,
        status
      ],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Actualizar opción de stake en la plataforma
   * @param ot                  Tipo de operación
   * @param idx                 Índice de la opción
   * @param val                 Nuevo valor
   * @param bool                Nuevo valor booleano
   * 
   * Requerimientos:
   * - Solo un usuario administrativo puede actualizar opciones de stake
   * 
   * @returns 
   */
  async stake_staked_updateStakeOption(ot: any, idx: any, val: any, bool: any) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "updateStakeOption",
      params: [ot, idx, val, bool],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener lista de opciones de stake
   * 
   * @returns 
   */
  async stake_staked_stakeList_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "stakeList",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener opción de stake a través del índice
   * @param idx             Índice
   * @returns 
   */
  async stake_staked_stakeOptions_OFFCHAIN(idx: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "stakeOptions",
      callType: "call",
      params: [idx],
      urlABI: this.stakeABI,
    });
  }

  async stake_staked_getStakeOption_OFFCHAIN(idx: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "getStakeOption",
      callType: "call",
      params: [idx],
      urlABI: this.stakeABI,
    });
  }

  async stake_staked_stakeOptionResumen_OFFCHAIN(idx: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "stakeOptionResumen",
      callType: "call",
      params: [idx],
      urlABI: this.stakeABI,
    });
  }

  async stake_staked_getStakeOptionTimelinePaginated_OFFCHAIN(idx: any, page: any, size: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "getStakeOptionTimelinePaginated",
      callType: "call",
      params: [idx, page, size],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener total de opciones de stake
   * 
   * @returns 
   */
  async stake_staked_stakeCount_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "_stakeCount",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener struct de una opción de stake a través del índice
   * @param idx                 Índice
   * 
   * @returns 
   */
  async stake_staked_Stake_OFFCHAIN(idx: number) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "_Stake",
      callType: "call",
      params: [idx],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener valor minimo de stake
   * 
   * @returns 
   */
  async stake_staked_minStaked_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "minStaked",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Cambiar valor minimo de stake
   * @param val                   Nuevo valor
   * 
   * Requerimientos:
   * - Solo un usuario administrativo puede cambiar el valor minimo de stake
   * 
   * @returns 
   */
  async stake_staked_changeMinStaked(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "changeMinStaked",
      params: [val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener valor de días de stake
   * @param days              Días de stake
   * 
   * @returns 
   */
  async stake_staked_getDays_OFFCHAIN(days: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "getDays",
      callType: "call",
      params: [days],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Reveritr stake de una billetera
   * @param idx                 Índice del registro de stake
   * @param addr                Dirección de billetera del usuario
   * 
   * Requerimientos:
   * - Solo un usuario administrativo puede revertir stake
   * 
   * @returns 
   */
  async stake_staked_revertUserStake(idx: string, addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "revertUserStake",
      params: [idx, addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Reveritr stake de una billetera y enviar fondos a otra billetera
   * @param idx                 Índice del registro de stake
   * @param user                Dirección de billetera del usuario
   * @param to                  Dirección de billetera a enviar fondos
   * 
   * Requerimientos:
   * - Solo un usuario administrativo puede revertir stake
   * 
   * @returns 
   */
  async stake_staked_revertUserStakeAsAdmin(idx: string, user: string, to: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "revertUserStakeAsAdmin",
      params: [idx, user, to],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /** ====================================================================
   *                   STAKE Stakes METHODS
   ===================================================================== */

  /**
   * @dev Realizar stake en la plataforma
   * @param val                     Cantidad de fondos a staked
   * @param idx                     Índice de la opción de stake
   * 
   * @returns 
   */
  async stake_stakes_stake(val: string, idx: number) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "stake",
      params: [val, idx],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }
  
  /**
   * @dev Realizar stake en la plataforma de manera administrativa
   * @param amount                  Cantidad de fondos a staked
   * @param idx                     Índice de la opción de stake
   * @param user                    Dirección de billetera del usuario
   * @param owner                   Dirección de billetera de quien recibe los fondos
   * 
   * Requerimientos:
   * - Solo un administrador puede realizar stake de manera administrativa
   * 
   * @returns 
   */
  async stake_stakes_stakeAsAdmin(
    amount: string,
    idx: number,
    user: string,
    owner: string
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "stakeAsAdmin",
      params: [
        amount,
        idx,
        user,
        owner
      ],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Reclamar recompensas de stake en la plataforma
   * @param val                       Cantidad de fondos a reclamar
   * @param idx                       Índice de la opción de stake
   * 
   * @returns 
   */
  async stake_stakes_withdrawStake(idx: number) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawStake",
      params: [idx],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Stakeable METHODS
   ===================================================================== */

  /**
   * @dev Obtener resumen de registros de stake del un usuario
   * @param addr                    Dirección de billetera
   * @returns 
   */
  async stake_stakeable_stakeHolderResumen_OFFCHAIN(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "stakeHolderResumen",
      callType: "call",
      params: [addr],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener registros de stake de una billetera de manera paginada
   * @param addr                    Dirección de billetera
   * @param page                    Página
   * @param size                    Tamaño de página
   * @returns 
   */
  async stake_stakeable_stakeHolderRecordsPaginated_OFFCHAIN(addr: string, page: any, size: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "stakeHolderRecordsPaginated",
      callType: "call",
      params: [addr, page, size],
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener evaluación de registro de stake
   * @param sId                   ID del registro de stake
   * @param addr                  Dirección de billetera
   * @returns 
   */
  async stake_stakeable_calculateStakeRecordReward_OFFCHAIN(sId: any, addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "calculateStakeRecordReward",
      callType: "call",
      params: [sId, addr],
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Withdraw METHODS
   ===================================================================== */

  /**
   * @dev Retirar fondos en crypto de la billetera del contrato
   * @param val                         Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async stake_withdraw_withdrawCrypto(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawCrypto",
      params: [val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Retirar fondos en tokens de la billetera del contrato
   * @param token                         Dirección del token
   * @param val                           Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async stake_withdraw_withdrawToken(token: string, val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawToken",
      params: [token, val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Limit METHODS
   ===================================================================== */

  async stake_limit_getStakeCounter_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "getStakeCounter",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  async stake_limit_getStakeLimit_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "getStakeLimit",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Cambiar limit de minteo de token en la plataforma
   * @param val                   Nuevo valor
   * 
   * Requerimientos:
   * - Solo un administrativo puede cambiar el limit de minteo de token
   * 
   * @returns 
   */
  async stake_limit_changeStakeLimit(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "changeStakeLimit",
      params: [val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Limit METHODS
   ===================================================================== */

  /**
   * @dev Obtener token principal de la plataforma
   * 
   * @returns 
   */
  async stake_mainToken_MAIN_TOKEN_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "MAIN_TOKEN",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Cambiar token principal de la plataforma
   * @param addr                  Dirección del token
   * 
   * Requerimientos:
   * - Solo un administrativo puede cambiar el token principal
   * 
   * @returns 
   */
  async stake_mainToken_setMainToken(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "setMainToken",
      params: [addr],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *                   STAKE Config METHODS
   ===================================================================== */

  /**
   * @dev Obtener estado de stake en la plataforma
   * 
   * @returns 
   */
  async stake_config_isStakeActive_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "isStakeActive",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Cambiar estado de stake en la plataforma
   * @param status            Nuevo estado
   * @returns 
   */
  async stake_config_setStakeActive(status: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "setStakeActive",
      params: [status],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Obtener estado de retiros stake en la plataforma
   * 
   * @returns 
   */
  async stake_config_isStakeWithdrawActive_OFFCHAIN() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.stake,
      method: "isStakeWithdrawActive",
      callType: "call",
      params: null,
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Cambiar estado de retiros stake en la plataforma
   * @param status            Nuevo estado
   * @returns 
   */
  async stake_config_setStakeWithdrawActive(status: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "setStakeWithdrawActive",
      params: [status],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }




  /** ====================================================================
   *            PARTNER DISTRIBUTION Administered METHODS
   ===================================================================== */

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como usuario administrativo
   * en la plataforma de manera OFFLINE. 
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async partnerDistribution_administered_isUser_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Establecer una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async partnerDistribution_administered_addUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Remover una billetera como usuario administrativo en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async partnerDistribution_administered_removeUser(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada como administrador
   * en la plataforma de manera OFFLINE.
   * @param addr                          Dirección de billetera
   * 
   * @returns {Promise<boolean>}
   */
  async partnerDistribution_administered_isAdmin_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Establecer una billetera como administrador en la plataforma
   * @param addr                          Dirección de billetera
   * 
   * @returns 
   */
  async partnerDistribution_administered_addAdmin(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Remover una billetera como administrador en la plataforma
   * 
   * @returns 
   */
  async partnerDistribution_administered_renounceAdmin() {
    const [addr] = this.accounts;
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "renounceAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }


  /** ====================================================================
   *               PARTNER DISTRIBUTION Withdraw METHODS
   ===================================================================== */

  /**
   * @dev Retirar fondos en crypto de la billetera del contrato
   * @param val                         Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async partnerDistribution_withdraw_withdrawCrypto(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawCrypto",
      params: [val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }

  /**
   * @dev Retirar fondos en tokens de la billetera del contrato
   * @param token                         Dirección del token
   * @param val                           Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async partnerDistribution_withdraw_withdrawToken(token: string, val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.stake,
      method: "withdrawToken",
      params: [token, val],
      callType: "send",
      urlABI: this.stakeABI,
    });
  }


  /** ====================================================================
   *               PARTNER DISTRIBUTION Blacklist METHODS
   ===================================================================== */

  /**
   * @dev Verificar si una billetera ya se encuentra registrada en la blacklist
   * @param addr                      Dirección de billetera
   * 
   * @returns
   */
  async partnerDistribution_blacklist_isBlackList_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "isBlackList",
      callType: "call",
      params: [addr],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Retirar fondos en crypto de la billetera del contrato
   * @param val                         Cantidad de fondos a retirar
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async partnerDistribution_blacklist_setAddressBlackList(addr: string, status: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "setAddressBlackList",
      params: [addr, status],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }


  /** ====================================================================
   *               PARTNER DISTRIBUTION Sanction METHODS
   ===================================================================== */

  /**
   * @dev Obtener si la verificación de sanciones está activa
   * @returns 
   */
  async partnerDistribution_sanction_stateSanctions_OFFCHAIN(): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "stateSanctions",
      callType: "call",
      params: null,
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Obtener contrato de sanciones
   * @returns 
   */
  async partnerDistribution_sanction_SANCTIONS_CONTRACT_OFFCHAIN(): Promise<string> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "SANCTIONS_CONTRACT",
      callType: "call",
      params: null,
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Verificar si una billetera ya se encuentra registrada en la lista de sanciones
   * @param addr                            Dirección de billetera
   * @returns 
   */
  async partnerDistribution_sanction_Sanctions_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "Sanctions",
      callType: "call",
      params: [addr],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Establecer contrato de sanciones
   * @param addr                            Dirección de contrato
   * @returns 
   */
  async partnerDistribution_sanction_setAddressSanctions(addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "setAddressSanctions",
      params: [addr],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Establecer estado de verificación de sanciones
   * @param bool                            Nuevo estado
   * @returns 
   */
  async partnerDistribution_sanction_setStateSanctions(bool: boolean) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "setStateSanctions",
      params: [bool],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }




  /** ====================================================================
   *               PARTNER DISTRIBUTION Deposit METHODS
   ===================================================================== */

  /**
   * @dev Obtener total de registros de depósitos
   * @returns 
   */
  async partnerDistribution_deposit_getDepositRecordCount_OFFCHAIN(): Promise<number> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "getDepositRecordCount",
      callType: "call",
      params: null,
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Obtener total de registros de depósitos ejecutados
   * @returns 
   */
  async partnerDistribution_deposit_getDepositRecordExecutedCount_OFFCHAIN(): Promise<number> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "getDepositRecordExecutedCount",
      callType: "call",
      params: null,
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Obtener registro de depósito por índice
   * @param idx                                 Índice
   * 
   * @returns 
   */
  async partnerDistribution_deposit_depositRecords_OFFCHAIN(idx: string): Promise<number> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "depositRecords",
      callType: "call",
      params: [idx],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Obtener registro de depósito paginado
   * @param page                                  Página
   * @param size                                  Tamaño de página
   * 
   * @returns 
   */
  async partnerDistribution_deposit_getDepositRecordPaginated_OFFCHAIN(page: any, size: any) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "getDepositRecordPaginated",
      callType: "call",
      params: [page, size],
      urlABI: this.partnerDistributionABI,
    });
  }

  /**
   * @dev Obtener registros de depósitos pendientes de ejecución limitados
   * @param size                                    Tamaño de página
   * 
   * @returns 
   */
  async partnerDistribution_deposit_getUnexecutedDeposits_OFFCHAIN(size: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.contract.partnerDistribution,
      method: "getUnexecutedDeposits",
      callType: "call",
      params: [size],
      urlABI: this.partnerDistributionABI,
    });
  }




  /** ====================================================================
   *               PARTNER DISTRIBUTION Deposit METHODS
   ===================================================================== */

  /**
   * @dev Ejecutar depósitos pendientes con un límite de registros
   * @param val                           Cantidad de registros a ejecutar
   * @returns 
   */
  async partnerDistribution_distribution_runDistribution(val: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contract.partnerDistribution,
      method: "runDistribution",
      params: [val],
      callType: "send",
      urlABI: this.partnerDistributionABI,
    });
  }








  

  /** ====================================================================
   *  Méthodo genérico para llamadas al SC personalizado de manera OFFLINE
   ===================================================================== */
  async getHttpWeb3Provider() {
    const url = `${environment.chain.rpc}`;
    // console.log('url', url);
    const provider = new Web3.providers.HttpProvider(url);
    return provider;
  }

  async getContractInstance(params: any) {
    try {
      const { provider = await this.getHttpWeb3Provider(), contractAddress } =
        params;
      let { abi = null } = params;

      if (!abi) {
        const abiParsed: any = await this.abiService.getABIByUrl(
          this.vendorV2ABI
        );
        abi = Object.values(abiParsed);
      }

      const web3 = new Web3(provider);
      const instance = new web3.eth.Contract(abi, contractAddress, {});
      return instance;
    } catch (err) {
      console.log("Error on Web3Service@getContractInstance", err);
      throw err;
    }
  }

  async callDinamyContractOffLine(data: any) {
    try {
      const {
        contractAddress,
        method,
        params = null,
        urlABI = this.vendorV2ABI,
      } = data;

      // console.log('callDinamyContractOffLine', method);

      /** Construir Provider HTTP */
      const provider = await this.getHttpWeb3Provider();

      // Cargar ABI del contrato
      const contractABI: any = await this.abiService.getABIByUrl(urlABI);
      // console.log('contractABI', contractABI);

      // cargamos la abi de contracto secundarios con el metodo que necesitamos
      const uToken = await this.getContractInstance({
        provider,
        contractAddress: contractAddress,
        abi: Object.values(contractABI),
      });

      const contractMethod = !params
        ? uToken.methods[method]()
        : uToken.methods[method](...params);

      const result = await contractMethod["call"]();
      return result;
    } catch (err) {
      console.log("params", data);
      console.log("Error on Web3Service@callDinamyContractOffLine", err);
      throw err;
    }
  }

  /** ===============================================================
   *               Méthodo genérico para llamadas al SC
   * ================================================================
   * @param method
   * @param params
   * @param callType        'call' and 'send'
   */
  async calculateAndCall(
    method: any,
    params?: any,
    callType = "send",
    optionals: any = {}
  ) {
    try {
      const contractMethod = !params
        ? this.uToken.methods[method]()
        : this.uToken.methods[method](...params);

      if (callType === "send") {
        const [account] = this.accounts;
        optionals.from = account;

        const gasFee = await contractMethod.estimateGas(optionals);


        const gasPriceData: any = await this.bscGasSrv.getGasPrice();
        // console.log("gasPriceData", gasPriceData);

        optionals.gasPrice = toGwei(gasPriceData.result.FastGasPrice);
        optionals.gas = gasFee;
      }

      const result = await contractMethod[callType](optionals);

      return result;
    } catch (err: any) {
      // this.sweetAlertSrv.showError('Transacción fallida');
      console.log("Error on ContractService@calculateAndCall", err);
      throw new Error(err);
    }
  }

  /** ===============================================================
   *       Méthodo genérico para llamadas al SC personalizado
   * ================================================================
   * @param data
   * @param data.contractAddress
   * @param data.method
   * @param data.params
   * @param data.callType           'call' / 'send'
   * @param data.optionals
   * @param data.urlABI
   */
  async calculateAndCallCustomABI(data: any) {
    const {
      contractAddress,
      method,
      params = null,
      callType = "send",
      optionals = {},
      urlABI = this.erc20ABI,
    } = data;

    try {
      // Cargar ABI del contrato
      const contractABI: any = await this.abiService.getABIByUrl(urlABI);

      // cargamos la abi de contracto secundarios con el metodo que necesitamos
      const uToken = this.getAbiContract(
        [contractABI[method]],
        contractAddress
      );

      const contractMethod = !params
        ? uToken.methods[method]()
        : uToken.methods[method](...params);

        if (callType === "send") {
          const [account] = this.accounts;
          optionals.from = account;
  
          const gasFee = await contractMethod.estimateGas(optionals);
          // console.log("gas", gasFee);
  
          /**
           * TODO: se modifica gas temporalmente mientras se solventa bug de la red de BNB
           */
          // const gasPriceData: any = await this.bscGasSrv.getGasPrice();
          // console.log("gasPriceData", gasPriceData);
  
          // optionals.gasPrice = toGwei(gasPriceData.result.FastGasPrice);
          optionals.gasPrice = toGwei("3");
  
          optionals.gas = gasFee;
        }

      const result = await contractMethod[callType](optionals);

      return result;
    } catch (err: any) {
      console.log({ params: data });
      console.log("Error on ContractService@calculateAndCallCustomABI", err);
      throw new Error(err);
    }
  }

  /**
   * Obteber nueva instancia WEB3 de un SC a través del ABI ingresado
   * @param token_abi             ABI Cargado
   * @param token_address         Dirección del SC
   * @returns
   */
  getAbiContract(token_abi: any, token_address: any) {
    let uToken: any = new this.web3js.eth.Contract(token_abi, token_address);
    return uToken;
  }


}

export function checkCodevailable(service: Web3Service): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(service.swap_userCodeExist(control.value)).pipe(
      // tap((result) => console.log(result)),
      map((data) => {
        return data ? { userCodeExist: true } : null;
      })
    );
  };
}

export function checkCodevailableEmail(service: Web3Service): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(service.swap_userCodeExist(control.value)).pipe(
      // tap((result) => console.log(result)),
      map((data) => {
        return data ? { userWalletExist: false } : null;
      })
    );
  };
}

/**
 * Validat si el código de usuario ya se encuentra registrado
 * @param service
 * @returns
 */
export function checkWalletAddressvailable(
  service: Web3Service
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(service.swap_userWalletExist(control.value)).pipe(
      // tap((result) => console.log(result)),
      map((data) => {
        return data ? { userWalletExist: true } : null;
      })
    );
  };
}

/**
 * Validar si el código de usuario existe y esta activo
 * @param service
 * @returns
 */
export function checkReferredCodeBlockChain(
  service: Web3Service
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(service.swap_getUserByCode(control.value)).pipe(
      // tap((result) => console.log('mainred_users_getUserByCode', result)),
      map((data) => {
        return data.active ? null : { referredCodeAvailable: true };
      })
      // tap((result) => console.log('checkReferredCodeBlockChain', result)),
    );
  };
}
