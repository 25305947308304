import { Injectable } from '@angular/core';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class MetamaskAddTokenService {

  constructor() { }

  async addToken(add: string, sym: string, dcm: number, logo: string) {
    try {

      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: add, // The address that the token is at.
            symbol: sym, // A ticker symbol or shorthand, up to 5 chars.
            decimals: dcm, // The number of decimals in the token
            image: logo, // A string url of the token logo
          },
        },
      });

      console.log({wasAdded});

      if (!wasAdded) { return; }

      // return await this.sweetAlertSrv.showSuccess(`${environment.mainToken.symbol} was added`);

    } catch (error) {
      throw error;
    }
  }
}
