import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { AbiService } from './abi.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StakeContractService {

  public abi = '/assets/abi/Stake.json';

  public contractAddress = environment.contract.stake;

  constructor(
    private web3Srv: Web3Service,
    private abiService: AbiService,
  ) {
    // this.parseAbi();
  }

  async parseAbi(){
    const abi = await this.abiService.parseABI(this.abi);
    console.log('abi', abi);
  }

  async administered_isAdmin_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addAdmin(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_isUser_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_removeUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      params: null,
      callType: "send",
      urlABI: this.abi,
    });
  }

  async stakeable_stakeHolderResumen_OFFCHAIN(addr: string): Promise<StakeHoldResumen> {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "stakeHolderResumen",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }
  
  async stakeable_stakeHolderRecordsPaginated_OFFCHAIN(addr: string, page: any, size: any) : Promise<StakeHoldRecord[]> {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "stakeHolderRecordsPaginated",
      callType: "call",
      params: [addr, page, size],
      urlABI: this.abi,
    });
  }

  async stakeable_calculateStakeRecordReward_OFFCHAIN(sId: string, addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "calculateStakeRecordReward",
      callType: "call",
      params: [sId, addr],
      urlABI: this.abi,
    });
  }

  async staked_getStakeOption_OFFCHAIN(sId: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getStakeOption",
      callType: "call",
      params: [sId],
      urlABI: this.abi,
    });
  }

  async stake_withdrawStake(idx: number) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawStake",
      params: [idx],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /**
   * @dev Retirar fondos en crypto de la billetera del contrato
   * @param val                         Cantidad de fondos a retirar
   * @param addr                        Dirección de la billetera
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async withdraw_withdrawCrypto(val: string, addr: string) {
    return this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawCrypto",
      params: [val, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /**
   * @dev Retirar fondos en tokens de la billetera del contrato
   * @param token                         Dirección del token
   * @param val                           Cantidad de fondos a retirar
   * @param addr                          Dirección de la billetera
   * 
   * Requerimientos:
   * - Solo un administrador puede retirar fondos
   * 
   * @returns 
   */
  async withdraw_withdrawERC20(token: string, val: string, addr: string) {
    return this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawERC20",
      params: [token, val, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

}

export interface StakeHoldResumen {
  claimed: string;
  pending: string;
  staked: string;
  totalRecords: string;
}

export interface StakeHoldRecord {
  amount: string;
  claimable: string;
  claimedAt: string;
  owner: string;
  reverted: boolean;
  revertedAt: string;
  rewardHistory: RewardHistoryRecord[];
  sinceBlock: string;
  stakeId: string;
  status: boolean;
  token: string;
  untilBlock: string;
  user: string;
}

export interface RewardHistoryRecord {
  endTime: string;
  reward: string;
  rewardRate: string;
  startTime: string;
}
