<section class="landing-counters">
  <div class="container">
    <div class="row justify-content-center">
      <div
        *ngFor="let counter of data"
        class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2"
      >
        <div class="counter-item">
          <div class="counter-item-number">
            <h3>{{ counter.number }}</h3>
            <hr />
          </div>

          <div class="counter-item-title">
            <h4>{{ counter.title }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
