import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { saveBlobAsExcelFile } from 'src/app/helpers/saveBlobAsExcelFile.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private apiSrv: ApiService,
    private spinner: NgxSpinnerService
  ) { }

  /**
   * @dev Obtener el historial de swaps
   * @returns 
   */
  async getSwapHistory(filter: any = {}){
    try {
      await this.spinner.show();
      const url = this.apiSrv.fullApiURL + '/report/purchases/swap-history';
      const snapshot = await this.apiSrv._get(url, filter, { responseType: 'blob' });
      return saveBlobAsExcelFile(snapshot, 'swap-history');

    } catch (err) {
      console.log('Error on ReportService.getSwapHistory', err);
      throw err;

    } finally {
      this.spinner.hide();
    }
  }

  /**
   * @dev Obtener la lista de usuarios registrados en la plataforma
   * @returns 
   */
  async getAllUsersList(filter: any = {}){
    try {
      await this.spinner.show();
      const url = this.apiSrv.fullApiURL + '/report/users/all-users';
      const snapshot = await this.apiSrv._get(url, filter, { responseType: 'blob' });
      return saveBlobAsExcelFile(snapshot, 'all-users');

    } catch (err) {
      console.log('Error on ReportService.getAllUsersList', err);
      throw err;

    } finally {
      this.spinner.hide();
    }
  }

  async getContractAccessRoles(contract: string){
    try {
      await this.spinner.show();
      const url = this.apiSrv.fullApiURL + '/report/administered/get-access-roles';
      const snapshot = await this.apiSrv._get(url, {contract}, { responseType: 'blob' });
      return saveBlobAsExcelFile(snapshot, `access-roles-${contract}-${moment().valueOf()}`);

    } catch (err) {
      console.log('Error on ReportService.getContractAccessRoles', err);
      throw err;

    } finally {
      this.spinner.hide();
    }
  }

  async getContractBlacklistRecords(contract: string){
    try {
      await this.spinner.show();
      const url = this.apiSrv.fullApiURL + '/report/blacklist/get-records';
      const snapshot = await this.apiSrv._get(url, {contract}, { responseType: 'blob' });
      return saveBlobAsExcelFile(snapshot, `blacklist-records-${contract}-${moment().valueOf()}`);

    } catch (err) {
      console.log('Error on ReportService.getContractBlacklistRecords', err);
      throw err;

    } finally {
      this.spinner.hide();
    }
  }

  async getContractPartnerDepositRecords(contract: string){
    try {
      await this.spinner.show();
      const url = this.apiSrv.fullApiURL + '/report/partners/get-records';
      const snapshot = await this.apiSrv._get(url, {contract}, { responseType: 'blob' });
      return saveBlobAsExcelFile(snapshot, `partners-deposit-records-${contract}-${moment().valueOf()}`);

    } catch (err) {
      console.log('Error on ReportService.getContractPartnerDepositRecords', err);
      throw err;

    } finally {
      this.spinner.hide();
    }
  }

}
