import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PublicRoutingModule } from "./public-routing.module";
// import { HomeDashboardComponent } from "./dashboard/home-dashboard/home-dashboard.component";
import { SharedModule } from "../shared/shared.module";
// import { ReferralsComponent } from "../shared/referrals/referrals.component";
// import { RefersComponent } from "./dashboard/refers/refers.component";
// import { SwapComponent } from "./dashboard/swap/swap.component";
// import { StakingComponent } from "./dashboard/staking/staking.component";

import { HttpClientModule, HttpClient } from "@angular/common/http";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { GlobalSalesComponent } from "./dashboard/home-dashboard/global-sales/global-sales.component";
// import { TotalUserComponent } from "./dashboard/home-dashboard/total-user/total-user.component";
import { TranslatePipe } from "@ngx-translate/core";
import { QRCodeModule } from "angularx-qrcode";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
// import { TutorialsComponent } from "./dashboard/tutorials/tutorials.component";
// import { LandingComponent } from "./landing/landing.component";
// import { FeaturesLandingComponent } from "./landing/components/features-landing/features-landing.component";
// import { CountersLandingComponent } from "./landing/components/counters-landing/counters-landing.component";
// import { ProductsLandingComponent } from "./landing/components/products-landing/products-landing.component";
// import { RoadmapLandingComponent } from "./landing/components/roadmap-landing/roadmap-landing.component";
// import { NewsLandingComponent } from "./landing/components/news-landing/news-landing.component";
// import { StartLandingComponent } from "./landing/components/start-landing/start-landing.component";
// import { FooterLandingComponent } from "./landing/components/footer-landing/footer-landing.component";
// import { HeaderLandingComponent } from "./landing/components/header-landing/header-landing.component";
// import { EcosystemLandingComponent } from "./landing/components/ecosystem-landing/ecosystem-landing.component";
// import { SwapPComponent } from './swap-p/swap-p.component';
export function playerFactory() {
  return player;
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    // HomeDashboardComponent,
    // // ReferralsComponent,
    // RefersComponent,
    // SwapComponent,
    // StakingComponent,
    // GlobalSalesComponent,
    // TotalUserComponent,
    // TutorialsComponent,
    // SwapPComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslateModule,
    QRCodeModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [TranslatePipe],
})
export class PublicModule {}
