import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Web3UtilsPipe } from './web3-utils.pipe';
import { BalancePipe } from './balance.pipe';
import { TruncateWalletAddressPipe } from './truncate-wallet-address.pipe';
import { FilterPipe } from './filter.pipe';
import { Erc20Pipe } from './erc20.pipe';
import { ChainlinkOraclePipe } from './chainlink-oracle.pipe';
import { CustomNumberPipe } from './custom-number.pipe';



@NgModule({
  declarations: [
    Web3UtilsPipe,
    BalancePipe,
    TruncateWalletAddressPipe,
    FilterPipe,
    Erc20Pipe,
    ChainlinkOraclePipe,
    CustomNumberPipe,
  ],
  exports: [
    Web3UtilsPipe,
    BalancePipe,
    TruncateWalletAddressPipe,
    FilterPipe,
    Erc20Pipe,
    ChainlinkOraclePipe,
    CustomNumberPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
