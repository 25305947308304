import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mode-color",
  templateUrl: "./mode-color.component.html",
  styleUrls: ["./mode-color.component.css"],
})
export class ModeColorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    // this.checkColor();
  }

  // TODO: revisar funcion para leer del localstorage
  // checkColor() {
  //   let checkChangeColor: any = document.getElementById("changeColor");
  //   let changeColor = localStorage.getItem("changeColor");

  //   if (changeColor === "dark") {
  //     document.body.classList?.add("dark");
  //     checkChangeColor.checked = false;
  //   } else if (changeColor === "white") {
  //     document.body.classList?.add("white");
  //     checkChangeColor.checked = true;
  //   } else {
  //     document.body.classList?.add("dark");
  //     checkChangeColor.checked = false;
  //   }
  // }

  modeColor() {
    const checkbox: any = document.getElementById("changeColor");

    if (checkbox.checked) {
      document.body.classList?.add("white");
      document.body.classList?.remove("dark");
      localStorage.setItem("changeColor", "white");
    } else {
      document.body.classList?.add("dark");
      document.body.classList?.remove("white");
      localStorage.setItem("changeColor", "dark");
    }
  }
}
