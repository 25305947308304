import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from "@angular/forms";
import { addressIsValid } from "./utils";
import { Web3Service } from "../services/contract/web3.service";
import { SwapUserContractService } from "../services/contract/swap-user-contract.service";
// import { UserService } from "../services/firebase/user.service";
// import { Observable } from "rxjs";
// import { ProfileService } from "../services/firebase/profile.service";
// import { WalletService } from "../services/firebase/wallet.service";
// import BigNumber from "bignumber.js";

/**
 * Validar si una dirección es correcta
 * @param control 
 * @returns
 */
export function cmIsValidAddress(control: AbstractControl){
    return (addressIsValid(control.value)) ? null : { isValidAddress: true };
}


/** ====================================================================
 *                   VENDOR V2 Users Validations
 ===================================================================== */

/**
 * @dev Válidar si la wallet está disponible
 * @param service 
 * @returns 
 */
export function cmIsWalletAvailable(service: SwapUserContractService): AsyncValidatorFn {
  return async(control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
        const find = await service.user_userWalletExist_OFFCHAIN(`${control.value}`);
        // console.log('cmIsWalletAvailable', find);
        return (find) ? { walletNotAvailable: true } : null;
        
    } catch (err) {
        console.log('Error on cmIsWalletAvailable', err);
        return { walletNotAvailable: true }
    }
  }
}

/**
 * @dev Válidar si el nickname está disponible
 * @param service 
 * @returns 
 */
export function cmIsCodeAvailable(service: SwapUserContractService): AsyncValidatorFn {
  return async(control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
        const find = await service.user_userCodeExist_OFFCHAIN(`${control.value}`.trim().toLowerCase());
        // console.log('cmIsCodeAvailable', find);
        return (find) ? { codeNotAvailable: true } : null;
        
    } catch (err) {
        console.log('Error on cmIsCodeAvailable', err);
        return { codeNotAvailable: true }
    }
  }
}

/**
 * @dev Válidar si el código de referido está disponible
 * @param service 
 * @returns 
 */
export function cmIsReferredCodeAvailableAdmin(service: SwapUserContractService): AsyncValidatorFn {
  return async(control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
        const [
          userCounter,
          find
        ] = await Promise.all([
          service.user_userListCount_OFFCHAIN(),
          service.user_getUserByCode_OFFCHAIN(`${control.value}`.trim().toLowerCase())
        ]);

        // console.log({userCounter, find});

        /** No existen usuarios registrados aún */
        if(userCounter === 0) return null;

        /** Ya hay usuarios registrados - Ejecutar verificación */
        return (!find.active) ? { referredCodeNotAvailable: true } : null;
        
    } catch (err) {
        console.log('Error on cmIsReferredCodeAvailable', err);
        return { referredCodeNotAvailable: true }
    }
  }
}

export function cmIsReferredCodeAvailable(service: SwapUserContractService): AsyncValidatorFn {
  return async(control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
      const find = await service.user_getUserByCode_OFFCHAIN(`${control.value}`.trim().toLowerCase());
      return (!find.active) ? { referredCodeNotAvailable: true } : null;
        
    } catch (err) {
      console.log('Error on cmIsReferredCodeAvailable', err);
      return { referredCodeNotAvailable: true }
    }
  }
}

/**
 * @dev Válidar si el nickname está registrado
 * @param service 
 * @returns 
 */
export function cmIsCodeStored(service: SwapUserContractService): AsyncValidatorFn {
  return async(control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
      const find = await service.user_userCodeExist_OFFCHAIN(`${control.value}`.trim().toLowerCase());
      return (find) ? null : { codeNotStored: true };
        
    } catch (err) {
      console.log('Error on cmIsCodeStored', err);
      return { codeNotStored: true }
    }
  }
}



// /**
//  * Válidar si el valor es múltiplo de otro
//  * @param value         Valor a comparar
//  * @returns 
//  */
// export function cmMultipleOf(value: number) {
//   return (control: FormControl): ValidationErrors | null => {
//     return (control.value % value !== 0) ? { multipleOf: true } : null;
//   };
// }

// /**
//  * Válidar si ya existe un usuario registrado con la wallet
//  * @param service 
//  * @returns 
//  */
// export function checkUserStoredByWallet(service: UserService): AsyncValidatorFn {
//   return async(control: AbstractControl): Promise<ValidationErrors | null> => {
//     try {
//         const find = await service.getByIdPromise(`${control.value}`);
//         return (find) ? { userStoredByWallet: true } : null;
        
//     } catch (err) {
//         console.log('Error on checkUserStoredByWallet', err);
//         return { userStoredByWallet: true }
//     }
//   }
// }

// /**
//  * Válidar si ya existe un usuario registrado con el código
//  * @param service 
//  * @returns 
//  */
// export function checkUserStoredByCode(service: UserService): AsyncValidatorFn {
//   return async(control: AbstractControl): Promise<ValidationErrors | null> => {
//     try {
//         const find = await service.getDynamicToPromise([
//             {field: 'nickname', condition: '==', value: control.value}
//         ]);
//         // console.log('checkUserStoredByCode', find);
//         return (find.length > 0) ? { userStoredByCode: true } : null;
        
//     } catch (err) {
//         console.log('Error on checkUserStoredByCode', err);
//         return { userStoredByCode: true }
//     }
//   }
// }

// /**
//  * Válidar si el código de referido está disponible
//  * @param service 
//  * @returns 
//  */
// export function checkReferredCodeAvailable(service: UserService): AsyncValidatorFn {
//   return async(control: AbstractControl): Promise<ValidationErrors | null> => {
//     try {
//         const find = await service.getDynamicToPromise([
//             {field: 'nickname', condition: '==', value: control.value}
//         ]);
//         // console.log('checkReferredCodeAvailable', find);
//         return (find.length > 0) ? null: { referredCodeAvailable: true };
        
//     } catch (err) {
//         console.log('Error on checkReferredCodeAvailable', err);
//         return { userStoredByCodet: true }
//     }
//   }
// }

// /**
//  * Válidar si el slug del perfil está disponible
//  * @param service 
//  * @returns 
//  */
// export function checkRoleSlug(service: ProfileService): AsyncValidatorFn {
//   return async(control: AbstractControl): Promise<ValidationErrors | null> => {
//     try {
//         const find = await service.getDynamicToPromise(service.profileCollection, [
//             {field: 'slug', condition: '==', value: control.value}
//         ]);
//         // console.log('checkRoleSlug', find);
//         return (find.length > 0) ? { roleSlugExist: true } : null;
        
//     } catch (err) {
//         console.log('Error on checkRoleSlug', err);
//         return { roleSlugExist: true }
//     }
//   }
// }

// /**
//  * Válidar si el monto del campo excede el balance de usuario en su wallet de USDT
//  * @param uid                 UID del usuario (addr)
//  * @param service             Servicio de wallet
//  * @returns
//  */
// export function checkUsdtWalletBalance(uid: string, service: WalletService): AsyncValidatorFn {
//   return async (control: AbstractControl): Promise<ValidationErrors | null> => {
//     try {
//       const amount = `${control.value}`.trim();
//       // console.log('checkUsdtWalletBalance', amount);

//       const find = await service.getByIdPromise(uid);
//       // console.log('checkUsdtWalletBalance', find);

//       /** Si no se encuentra registro de wallet */
//       if (!find) { return { usdtWalletBalanceExceded: true }; }

//       const balance = find.usdt || 0;
//       // console.log('checkUsdtWalletBalance', balance);

//       // console.log({
//       //   amount,
//       //   balance,
//       //   rule: new BigNumber(amount).isGreaterThan(balance)
//       // });

//       /** Si se consigue registro válidar balance */
//       return (new BigNumber(amount).isGreaterThan(balance))
//         ? { usdtWalletBalanceExceded: true }
//         : null;

//     } catch (err) {
//       console.log('Error on checkUsdtWalletBalance', err);
//       return { usdtWalletBalanceExceded: true }
//     }
//   }
// }