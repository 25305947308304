<table class="table d-md-table d-block">
    <!-- El thead se oculta en pantallas de menos de 768px -->
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Columna 1</th>
            <th scope="col">Columna 2</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="row" data-title="#">1</td>
            <td data-title="Columna 1">Dato 1-1</td>
            <td data-title="Columna 2">Dato 1-2</td>
        </tr>
        <tr>
            <th scope="row" data-title="#">2</th>
            <td data-title="Columna 1">Dato 2-1</td>
            <td data-title="Columna 2">Dato 2-2</td>
        </tr>
        <!-- Más filas según sea necesario -->
    </tbody>
</table>