import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { toWei } from 'src/app/helpers/utils';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { environment } from 'src/environments/environment';
import { Web3Service } from '../../services/contract/web3.service';

@Component({
  selector: 'app-my-stakes-cards',
  templateUrl: './my-stakes-cards.component.html',
  styleUrls: ['./my-stakes-cards.component.css']
})
export class MyStakesCardsComponent implements OnInit {
  @Input() myItem: any;

  public form: FormGroup;
  public vm = {
    amount: [
      {type: "required", message: "Amount is required"},
      {type: "min", message: "Amount must be greater than 0"},
    ]
  };

  public submit = false;

  constructor(
    private fb: FormBuilder,
    private alertStepsSrv: Sweetalert2stepsService,
    private contractSrv: Web3Service
  ) {
    this.form = this.fb.group({
      amount: ["", [Validators.required, Validators.min(1)]]
    })
  }

  ngOnInit(): void {
    console.log('my Item5655  ', this.myItem);
  }

  get f(){ return this.form.controls; }

  async onSubmit(){
    try {
      this.submit = true;

      if (this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }

      const amount = toWei(this.form.get('amount')?.value);

      const result = await this.alertStepsSrv.showStepsGeneral({
        actionMessage: `Retirar del stake?`,
        contractParams: { method: 'stake_claim', params: [amount, this.myItem.stakeId]}
      });


      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Stake realizado exitosamente`)
        .then((result) => { 
          this.form.reset(); 
          this.submit = false;
        });
      }
      
    } catch (err) {
      console.log('Error on StakeCardItemComponent.onSubmit()', err);
      return;
    }
  }




}
