<div class="ecosystem-landing" id="ecosystem">
  <div class="container position-relative" style="z-index: 5">
    <div class="row">
      <div class="col-12 col-xl-8">
        <div class="d-none">
          <img src="assets/img/ecosystem-img.png" class="img-fluid" alt="" />
        </div>
        <div class="container-img-big">
          <div class="circle-blue">
            <img src="assets/img/point-blue.png" class="img-fluid" alt="" />
          </div>
          <div class="rectangulo-one"></div>
          <div class="dash-one">
            <img
              src="assets/img/dash-one.png"
              class="img-fluid dash-img"
              alt=""
            />
          </div>
          <div class="dash-two">
            <img
              src="assets/img/dash-two.png"
              class="img-fluid dash-img"
              alt=""
            />
          </div>
          <div class="dash-three">
            <img
              src="assets/img/dash-three.png"
              class="img-fluid dash-img"
              alt=""
            />
          </div>
          <div class="circle-yellow">
            <img src="assets/img/circle-yellow.png" class="img-fluid" alt="" />
          </div>
          <div class="rectangulo-two"></div>
        </div>
      </div>

      <div class="col-12 col-xl-4 mt-5 pt-5 mt-xl-0">
        <h4>Ecosystem</h4>
        <p>
          Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit, sed quia non numquam eius modi tempora
          incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit anim id est laborum.
        </p>
        <button class="btn-deg">See more</button>
      </div>
    </div>
  </div>
</div>
