import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /** URL de la API */
  public apiURL = `${environment.API_URL}`;

  /** Prefijo de la API */
  public apiPrefix = '/api/v1';

  /** URL completa de la API */
  public fullApiURL = `${this.apiURL}${this.apiPrefix}`;

  constructor(
    private http: HttpClient
  ) { }

  async _get(path: string, params: any = {}, options: any = {}){
    try {
      /** Construir parametros de la URI */
      const paramsParsed = new HttpParams({fromObject: params});
      // console.log('data', data);
      // console.log('params', params.toString());

      const apiLink = (Object.keys(params).length > 0) ? `${path}?${paramsParsed.toString()}` : `${path}`;
      // console.log('apiLink', apiLink);
      
      /** Ejecutar petición */
      return await lastValueFrom(this.http.get(apiLink, options));

    } catch (err) {
      console.log('Error on ApiService.get', err);
      throw err;
    }
  }

  async _post(path: string, data: any) {
    try {
      // console.log('data', data);
      return await lastValueFrom(this.http.post(path, data));
      
    } catch (err) {
      console.log('Error on ApiService.post', err);
      throw err;
    }
  }

  async post(path: string, data: any) {
    const snapshot: any =  await this._post(`${this.fullApiURL}${path}`, data);
    return snapshot.results;
  }

  async get(path: string, data: any = {}){
    const snapshot: any =  await this._get(`${this.fullApiURL}${path}`, data);
    return snapshot.results;
  }

}
