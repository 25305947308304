import { Component, OnInit } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-news-landing",
  templateUrl: "./news-landing.component.html",
  styleUrls: ["./news-landing.component.css"],
})
export class NewsLandingComponent implements OnInit {
  options: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };
  data: any = [
    {
      img: "assets/img/poster-video.png",
      title: "Name to new",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
    {
      img: "assets/img/poster-video.png",
      title: "Name to new",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
    {
      img: "assets/img/poster-video.png",
      title: "Name to new",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
  ];

  constructor() {}

  ngOnInit() {}

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
