import { Component, OnInit } from "@angular/core";
import { Web3Service } from "src/app/services/contract/web3.service";

@Component({
  selector: 'app-btn-connect',
  templateUrl: './btn-connect.component.html',
  styleUrls: ['./btn-connect.component.css']
})
export class BtnConnectComponent implements OnInit {

  constructor(
    private web3Srv: Web3Service,
  ) {   }

  ngOnInit(): void { }

  async connectAccountMetamask() {
    return this.web3Srv.connectAccountMetaMask();
  }

  async connectAccountWalletConnect() {
    return this.web3Srv.connectAccountWalletConnect();
  }

  async toggleSelect($event:any){
    const connect = $event.target.parentElement
    // console.log(connect)
    connect?.classList.toggle("show");
  }

}
