import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from '../services/contract/web3.service';

@Pipe({
  name: 'erc20'
})
export class Erc20Pipe implements PipeTransform {

  constructor(
    private contractSrv: Web3Service,
  ) { }

  async transform(address: string, field: string): Promise<any> {


    if (environment.contract.addressdead == address) {
      return "BNB"
    }


    return await this.contractSrv.calculateAndCallCustomABI({
      contractAddress: address,
      method: field,
      callType: 'call',
      optionals: null,
      urlABI: this.contractSrv.erc20ABI
    });
  }

}
