import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/app/services/firebase/user.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { ClipboardService } from 'ngx-clipboard';
import { HistoryComponent } from '../history/history.component';
@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.css']
})
export class MyTeamComponent implements OnInit, OnChanges {

  /** Nivel a consultar */
  @Input() level: any;

  /** Código del usuario Host */
  @Input() hostCode: any;

  /** Listado de miembros */
  public members$!: Observable<any[]>;

  /** Modal para ver historial del usuario */
  @ViewChild('modalUserHistory') modalUserHistory!: HistoryComponent;


  @Input() account: any;

  @Input() item: any;

  public record: any;

  constructor(
    private userSrv: UserService,
    private sweetAlert2Srv: Sweetalert2Service,
    private _clipboardSrv: ClipboardService,
  ) { }

  ngOnInit(): void {
    this.loadData();

    // this.members$ = this.userSrv.getById(this.account)
    // .pipe(

    //   map((userDoc: any) => {
    //     const {
    //       level = null,
    //       hasPackage = false,
    //       active = false,
    //       myCode = null
    //     } = userDoc;

    //     return { level, hasPackage, myCode, active};
    //   }),
    //   switchMap((user) => {

    //     if(!user.active  || !user.hasPackage){
    //       return of([]);
    //     }

    //     const nextLevel = Number(user.level) + Number(this.item);

    //     // console.log("next", nextLevel)
    //     return this.userSrv.getDynamic([
    //       { field: 'level', condition: '==', value: nextLevel },
    //       { field: 'hasPackage', condition: '==', value: true },
    //       { field: 'seedUpArr', condition: 'array-contains-any', value: [user.myCode] },
    //     ], {})

    //   }),
    //   // tap((data) => console.log('data', data, "wallet", this.account))
    // )

    // this.members$.subscribe(res => console.log(res));

   
  }

  ngOnChanges(changes: SimpleChanges){
    const { level, hostCode } = changes;

    if(level && level.currentValue){
      // console.log("level", level.currentValue);
      this.level = Number(level.currentValue);
    }

    if(hostCode && hostCode.currentValue){
      // console.log("hostCode", hostCode.currentValue);
      this.hostCode = hostCode.currentValue;
    }

    /** Actualizar data */
    this.loadData();

    // this.members$ = this.userSrv.getById(this.account)
    // .pipe(
    //   map((userDoc: any) => {
    //     const {
    //       level = null,
    //       hasPackage = false,
    //       active = false,
    //       myCode = null
    //     } = userDoc;

    //     return { level, hasPackage, myCode, active};
    //   }),
    //   switchMap((user) => {

    //     if(!user.active  || !user.hasPackage){
    //       return of([]);
    //     }

    //     const nextLevel = Number(user.level) + Number(this.item);
    //     // console.log("user", user.level)
    //     // console.log("item", this.item)
    //     // console.log("user", user)



    //     // console.log("next", nextLevel)
    //     return this.userSrv.getDynamic([
    //       { field: 'level', condition: '==', value: nextLevel },
    //       { field: 'hasPackage', condition: '==', value: true },
    //       { field: 'seedUpArr', condition: 'array-contains-any', value: [user.myCode] },
    //     ], {})

    //   }),
    //   // tap((data) => console.log('data', data, "wallet", this.account))
    // )

    // this.members$.subscribe(res => console.log(res));
  }

  /**
   * @dev Cargar listado de miembros segun la configuración
   */
  loadData(){
    // console.log({level: this.level, hostCode: this.hostCode});

    /**
     * - Si no hay nivel configurado
     * - Si no hay código de host configurado
     */
    if(!this.level || !this.hostCode){
      this.members$ = of([]);
      return;
    }

    this.members$ = this.userSrv.getDynamic([
      { field: 'level', condition: '==', value: this.level },
      { field: 'hasPackage', condition: '==', value: true },
      { field: 'seedUpArr', condition: 'array-contains-any', value: [this.hostCode] },
    ], {});
  }


  showHistory(memberDoc: any){
    // this.record = obj;
    this.modalUserHistory.showModal(memberDoc);
  }
  
  /**
   * @dev Copiar al portapapeles
   * @param id 
   */
  copy(id: string) {
    this._clipboardSrv.copy(id);
    this.sweetAlert2Srv.showToast('Copied to clipboard successfully', 'success');
  }

}
