import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-features-landing",
  templateUrl: "./features-landing.component.html",
  styleUrls: ["./features-landing.component.css"],
})
export class FeaturesLandingComponent implements OnInit {
  data: any = [
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
    {
      icon: "assets/img/icon-heart.png",
      title: "Feature Name",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.",
    },
  ];
  constructor() {}

  ngOnInit() {}
}
