import { Component, OnInit } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-footer-landing",
  templateUrl: "./footer-landing.component.html",
  styleUrls: ["./footer-landing.component.css"],
})
export class FooterLandingComponent implements OnInit {
  options: AnimationOptions = {
    path: "/assets/img/lottie/Loaderblanco.json",
  };
  options1: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };

  constructor() {}

  ngOnInit() {}
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
