import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UsersManagementService {

  public allUsers: any = null;

  public allUsers$= new BehaviorSubject<any>(this.allUsers);

  constructor(
    private userService: UserService
  ) {
    this.setAllUsers();
   }

  setAllUsers(){
    const res = this.userService.getAll().then(response => {
      this.allUsers$.next(response);
      // console.log(response)
      this.allUsers = response;
    });
  }
  get getallUsers$():Observable<any>{
    return this.allUsers$.asObservable();
  }

  getAllUsers(){
    return this.allUsers;
  }

  returnedAllUsers(){
    if(this.allUsers){
      return this.allUsers;
    } else {
      this.setAllUsers();
      return this.allUsers;
    }
  }
}
