import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription, catchError, combineLatest, from, map, of, switchMap } from "rxjs";
import { SwapUserContractService } from "src/app/services/contract/swap-user-contract.service";
import { Web3Service } from "src/app/services/contract/web3.service";

@Component({
  selector: "app-sidebar-dashboard",
  templateUrl: "./sidebar-dashboard.component.html",
  styleUrls: ["./sidebar-dashboard.component.css"],
})
export class SidebarDashboardComponent implements OnInit, OnDestroy {

  /** Es usuario registrado en la plataforma */
  public isUserRed: boolean = false;

  /** Es administrador de la plataforma */
  public isAdmin: boolean = false;

  /** Es usuario administrativo de la plataforma */
  public isUserAdm: boolean = false;

  /** Cuenta conectada */
  public account!: string;

  private sub$!: Subscription;

  constructor(
    private web3Srv: Web3Service,
    private swapUserContractSrv: SwapUserContractService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Obtiene el estado de la cuenta
    this.sub$ = this.web3Srv.accountStatus$
    .pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap((data: any) => {

        if(!data){ 
          throw new Error('ERR_NO_ACCOUNT');
        }

        return combineLatest([
          of(data),
          from(this.swapUserContractSrv.administered_isUser_OFFCHAIN(data)),
          from(this.swapUserContractSrv.administered_isAdmin_OFFCHAIN(data)),
          from(this.swapUserContractSrv.user_userWalletExist_OFFCHAIN(data))
        ]);
      }),
      map(([addr, isUser, isAdmin, isUserNetwork]) => {

        return {
          addr,
          isUser,
          isAdmin,
          isUserNetwork
        }
      }),
      catchError((err) => {
        console.log("Error on SidebarDashboardComponent", err);
        return of({
          addr: null,
          isUserNetwork: false,
          isUser: false,
          isAdmin: false,
        })
      })
    )
    .subscribe((data: any) => {
      // console.log('data', data);
      this.account = data.addr;
      this.isUserRed = data.isUserNetwork;
      this.isUserAdm = data.isUser;
      this.isAdmin = data.isAdmin;
    });

    // Verifica si existe una sesión activa
    this.web3Srv.checkAlreadyConnected();
  }

  ngOnDestroy(): void {
    if(this.sub$) { this.sub$.unsubscribe(); }
  }

  async logOut(){ 
    this.web3Srv.logout(false);
    window.location.href = '/landing';
    return;
  }
}
