import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { HomeDashboardComponent } from "./pages/dashboard/home-dashboard/home-dashboard.component";
// import { RefersComponent } from "./pages/dashboard/refers/refers.component";
// import { StakingComponent } from "./pages/dashboard/staking/staking.component";
// import { SwapComponent } from "./pages/dashboard/swap/swap.component";
// import { HomeComponent } from "./pages/home/home.component";
import { AdminLayoutComponent } from "./shared/admin-layout/admin-layout.component";
// import { RegisterComponent } from './pages/dashboard/register/register.component';
import { PublicLayoutComponent } from "./shared/public-layout/public-layout.component";
import { LandingComponent } from "./pages-old/landing/landing.component";
import { SwapPComponent } from "./pages-old/swap-p/swap-p.component";
import { PagesLayoutComponent } from "./shared/pages-layout/pages-layout.component";

const routes: Routes = [
  // {
  //   path: "",
  //   component: PublicLayoutComponent,
  //   loadChildren: () =>
  //     import("./pages/public.module").then((m) => m.PublicModule),
  // },
  // {
  //   path: "swap-p",
  //   component: SwapPComponent,
  // },
  // {
  //   path: "landing",
  //   component: LandingComponent,
  //   loadChildren: () =>
  //     import("./pages/public.module").then((m) => m.PublicModule),
  // },
  {
    path: "pages",
    component: PagesLayoutComponent,
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "admin",
    component: AdminLayoutComponent,
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () => import('./out/out-pages.module').then(m => m.OutPagesModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
