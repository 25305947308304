<div class="item-stakelist">

    <div class="header-is d-flex justify-content-between align-items-center">

        <div class="text-end d-flex aling-items-center lh-1">
            <span class="title fw-bold">
                REMITT
                <br>
                <span class="text-small">
                    {{"Stake and Earn" | translate}}
                </span>
            </span>
        </div>
        <div>
            <span class="h1 placeholder-glow">
                <span class="placeholder placeholder-lg col-2 mb-2" style="width: 5rem;"></span>
            </span>
        </div>

        <div class="icon-l placeholder-glow">
            <span class="placeholder p-5 col-12" style="border-radius: 50%"></span>
        </div>

    </div>

    <div class="body-is">
        <div class="text-center fw-bold placeholder-glow">
            <span class="placeholder placeholder-lg col-6 mb-2"></span>
        </div>
        <div class="labelPercent">&nbsp;</div>
    </div>

    <div class="text-small">
        <div class="text-small placeholder-glow">
            <span class="placeholder col-12 mb-1"></span>
        </div>
        <div class="text-small placeholder-glow">
            <span class="placeholder col-12 mb-1"></span>
        </div>
        <div class="text-small placeholder-glow">
            <span class="placeholder col-12 mb-1"></span>
        </div>

        <div class="d-flex mt-1 align-items-center justify-content-between">
            <div class="placeholder-glow w-100">
                <span class="placeholder mb-0 placeholder-lg col-12" style="height: 38px;"></span>
            </div>
        </div>
    </div>

    <div class="sprd-neon"></div>

    <div class="text-small placeholder-glow">
        <span class="placeholder col-12 mb-2"></span>
    </div>

    <app-button-three [disabled]="true">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </app-button-three>

</div>
