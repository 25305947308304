import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HistoryComponent } from '../history/history.component';
import { UserService } from 'src/app/services/firebase/user.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-my-team-by-custom-filter',
  templateUrl: './my-team-by-custom-filter.component.html',
  styleUrls: ['./my-team-by-custom-filter.component.css']
})
export class MyTeamByCustomFilterComponent implements OnInit, OnChanges {

  /** Filtro de consulta */
  @Input() filterRules: any = null;

  /** Listado de miembros */
  public members$!: Observable<any[]>;

  /** Modal para ver historial del usuario */
  @ViewChild('modalUserHistory') modalUserHistory!: HistoryComponent;

  constructor(
    private userSrv: UserService,
    private sweetAlert2Srv: Sweetalert2Service,
    private _clipboardSrv: ClipboardService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges){
    const { filterRules } = changes;

    if(filterRules && filterRules.currentValue){ this.filterRules = filterRules.currentValue; }

    /** Actualizar data */
    this.loadData();
  }

  /**
   * @dev Cargar listado de miembros segun la configuración
   */
  loadData(){

    /** Si no hay reglas definidas */
    if(!this.filterRules){
      this.members$ = of([]);
      return;
    }

    // console.log("filterRules", this.filterRules);
    const { 
      where = [], 
      startAt = null, 
      endAt = null, 
      orderBy = [] 
    } = this.filterRules;

    this.members$ = this.userSrv.getDynamic(
      where,
      {
        startAt: startAt,
        endAt: endAt,
        orderBy: orderBy
      });
  }


  showHistory(memberDoc: any){
    // this.record = obj;
    this.modalUserHistory.showModal(memberDoc);
  }
  
  /**
   * @dev Copiar al portapapeles
   * @param id 
   */
  copy(id: string) {
    this._clipboardSrv.copy(id);
    this.sweetAlert2Srv.showToast('Copied to clipboard successfully', 'success');
  }

}
