import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-counters-landing",
  templateUrl: "./counters-landing.component.html",
  styleUrls: ["./counters-landing.component.css"],
})
export class CountersLandingComponent implements OnInit {
  data: any = [
    {
      number: "1200",
      title: "Lorem ipsum",
    },
    {
      number: "378",
      title: "Lorem ipsum",
    },
    {
      number: "197",
      title: "Lorem ipsum",
    },
    {
      number: "2570",
      title: "Lorem ipsum",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
