<div class="section-header-des d-flex justify-content-between">

  <div class="logo-header">
    <img class="white" src="assets/img/logo-trans-2.png" alt="">
    <img class="dark" src="assets/img/logo-trans-white.png" alt="">
  </div>

  <div class="d-flex justify-content-center align-items-center">

    <div class="modeColorL">
      <app-mode-color></app-mode-color>
    </div>
    <div class="btn-languaje d-flex languaje-c" id="languaje">
      <div [ngClass]="{active: currentLanguage == 'es'}" class="option" (click)="changeLanguage('en')">
        <img src="assets/img/languaje-es.png" alt="">
      </div>
      <div [ngClass]="{active: currentLanguage == 'en'}" class="option" (click)="changeLanguage('es')">
        <img src="assets/img/languaje-en.png" alt="">
      </div>
    </div>
    <div class="btn-home d-none d-lg-flex">
      <a href="http://remitt.io/" target="_blank"> 
        {{ "landing.product" | translate }}
      </a>
    </div>

    <div class="select-connec d-none d-lg-flex">
      <div class="btn-one btn-condash">
        {{ "landing.conect" | translate }}
      </div>
      <div class="select-options">
        <div class="select-c">
          <div class="my-3">{{ "landing.howConect" | translate }}</div>
          <div class="btn-conec-option d-flex align-items-center" (click)="connectAccountMetamask()">
            <img src="assets/img/metamask.svg" alt="">
            <span class="ps-2">Metamask</span>
          </div>
          <div class="btn-conec-option d-flex align-items-center" (click)="connectAccountWalletConnect()">
            <img src="assets/img/walletConnect.svg" alt="">
            <span class="ps-2">WalletConnect</span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>