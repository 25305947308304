import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';
import { CommonService } from 'src/app/services/common.service';
import { Observable, catchError, from, map, of } from 'rxjs';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { UserService } from 'src/app/services/firebase/user.service';


@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent  implements OnInit{

  @Input() account: any;


  /** TODO: habilitar */
  public myCode: any = 'code0'

  public userDoc$!: Observable<any>;

  constructor (
    private _clipboardService: ClipboardService,
    private sweetAlert2Srv: Sweetalert2Service,
    private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService,
    private commonService: CommonService,
    private web3Srv: Web3Service,
    private userSrv: UserService,
  ) {
    
  }
  ngOnInit(): void {
    console.log('account', this.account);

    /** Obtenre documento de usuario */
    this.userDoc$ = this.userSrv.getById(this.account)
    .pipe(
      map((res: any) => ({ exist: true, ...res })),
      catchError((err) => of({ exist: false}))
    );
    // this.userBlockChainDoc$ = from(this.web3Srv.vendor_user)
    // this.translateSrv.loadLocalLanguage();
    
    // this.commonService.getMyCode$.subscribe(
    //   (myCode: any) => (this.myCode = myCode)
    // );
  }

  invitationLink(code: string){
    // return `${environment.urlWeb}/referidos/${code}`
    return `${environment.urlWeb}/landing?ref=${code}`
  }

  copy(code: string) {
    const messageA = this.translatePipe.transform('home-dashboard.qr.copied');
    const messageB = this.translatePipe.transform('home-dashboard.qr.success');
    const link = this.invitationLink(code.trim().toLowerCase());
    this._clipboardService.copy(link);
    this.sweetAlert2Srv.showToast(messageA, messageB);
  }

 
}