<div class="news-landing">
  <div class="container">
    <div class="point"></div>
    <div class="point two"></div>
    <div class="point three"></div>
    <div class="news-lading-container-logo">
      <div class="news-landing-logo">
        <ng-lottie
          class="animation-logo"
          [options]="options"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 d-flex justify-content-center">
        <div class="container-titles">
          <h3>Latest News</h3>
          <p class="text-center">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of data">
        <div class="card-news">
          <div class="card-news-img">
            <img class="img-fluid" src="{{ item.img }}" alt="" />
          </div>

          <div class="card-news-description">
            <p>{{ item.title }}</p>
            <span>
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 d-flex justify-content-center mt-4">
        <button class="btn-green">See All</button>
      </div>
    </div>
  </div>
</div>
