import { Component, Input, OnInit } from '@angular/core';

type CardType = 'admin' | 'light' | 'dark';

@Component({
  selector: 'app-card-admin-dashboard',
  templateUrl: './card-admin-dashboard.component.html',
  styleUrls: ['./card-admin-dashboard.component.css']
})
export class CardAdminDashboardComponent implements OnInit {

  @Input() type: CardType = 'dark';

  @Input() extendClass: string = '';

  public cardOptions: { [key in CardType]: string } = {
    'admin': 'card-dashboard d-flex align-items-center',
    'light': 'card-dashboard card-dashboardTwo',
    'dark': 'card-dashboard',
  };

  public currentCard: string = this.cardOptions[this.type];

  constructor() { }

  ngOnInit(): void {
    // Actualizar la clase cuando el tipo cambie
    this.currentCard = this.cardOptions[this.type];
  }

}
