import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { Web3Service } from '../contract/web3.service';
import { PurchasesService } from './purchases.service';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class PurchaseManagementService {

  public globalValue: number[] = [0,0,0];

  public globlaCommissions: number[] = [0,0,0];

  public totalUsers: number[] = [0,0,0];

  public allPurchases: any = null;

  public allPurchases$= new BehaviorSubject<any>(this.allPurchases);

  public globalValue$= new BehaviorSubject<any>(this.globalValue);

  public globlaCommissions$= new BehaviorSubject<any>(this.globlaCommissions);



  

  constructor(
    private web3Srv: Web3Service,
    private purchasesService: PurchasesService,
    ) { 
    //   this.allPurchases = this.purchasesService.getAll().then(response => {
    //    this.allPurchases = response
    // })
    this.setAllPurchases();
    }

    setAllPurchases(){
      this.purchasesService.getAll().then(response => {
        this.allPurchases$.next(response);
        this.allPurchases = response
      });
    }
    get getallPurchases$():Observable<any>{
      return this.allPurchases$.asObservable();
    }

    returnAllPurchases(){
      return this.allPurchases;
    }


  
   
}
