<td scope="row" class="text-center" title="#">
    <!-- {{item.idx}} -->
    <span>{{index}}</span>
</td>
<td scope="row" class="text-center" title="{{'Option' | translate}}">
    <ng-container *ngIf="(stakeOptionDocument); else renderLoaderSpinner">
        <span class="">
            <!-- {{stakeOptionDocument.day}}D - {{stakeOptionDocument.rewardRate}}% -->
            {{stakeOptionDocument.day}}D
        </span>
    </ng-container>
</td>
<!-- <td class="text-center" title="{{'Wallet' | translate}}">
    <span class="">
        {{item.user | truncateWalletAddress}}
    </span>
</td> -->
<td class="text-center" title="{{'Amount' | translate}}">
    <span> {{item.amount | web3Utils:'fromWei'}} {{mainToken.symbol | uppercase}}</span>
</td>
<td class="text-center" title="{{'Earn' | translate}}">
    <span>
        <ng-container *ngIf="(earnTimer$ | async) as data; else renderLoaderSpinner">
            {{data.reward | number:'1.5-5'}} {{mainToken.symbol | uppercase}}
        </ng-container>
    </span>    
</td>
<td class="text-center" title="{{'Start' | translate}}">
    <span class="">
        {{item.sinceBlock * 1000 | date:'medium'}}
    </span>
</td>
<td class="text-center" title="{{'End' | translate}}">
    <ng-container *ngIf="(this.countdown$ | async) as info">

        <ng-container *ngIf="info.canClaim">
            <span class="">
                {{item.untilBlock * 1000 | date:'medium'}}
            </span>
        </ng-container>

        <ng-container *ngIf="!info.canClaim">
            <span class="">
                <ng-container *ngIf="info.days > 0">
                    {{info.days}}D
                </ng-container>
    
                <ng-container *ngIf="info.hours > 0">
                    {{info.hours}}H
                </ng-container>
    
                <ng-container *ngIf="info.minutes > 0">
                    {{info.minutes}}M
                </ng-container>
    
                <!-- <ng-container *ngIf="info.seconds > 0">
                    {{info.seconds}}S
                </ng-container> -->
            </span>
        </ng-container>

    </ng-container>
    <!-- {{(this.countdown$ | async).time | date:'medium'}} -->
</td>

<!-- <td class="text-center">
    {{item.rewardRate | web3Utils:'fromWei'}}%
</td> -->

<!-- <td class="text-center">
    {{item.rewardPerMonth | web3Utils:'fromWei'}}%
</td> -->

<!-- <td class="text-center">
    {{item.claimable | web3Utils:'fromWei'}} {{mainToken.symbol | uppercase}}
</td> -->

<!-- <td class="text-center">
        {{item.idx}}
    </td> -->
<td class="text-center" title="{{'Claim' | translate}}">
    <span>
        <ng-container 
        *ngIf="(countdown$ | async) as info; else renderNoClaimableButton"
        [ngTemplateOutlet]="renderCheck" [ngTemplateOutletContext]="{info: info}">
        </ng-container>
    </span>
</td>


<ng-template #renderClaimableButton>
    <button class="btn btn-sm btn-success" type="button" (click)="onSubmit()">
        Claim
    </button>
</ng-template>

<ng-template #renderCheck let-info="info">
    <ng-container *ngIf="(info.canClaim); then renderClaimableButton; else renderNoClaimableButton"></ng-container>
</ng-template>

<ng-template #renderNoClaimableButton>
    <button class="btn btn-sm btn-secondary" type="button" disabled>
        Claim
    </button>
</ng-template>

<ng-template #renderLoaderSpinner>
    <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</ng-template>