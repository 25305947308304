<div
  class="modal fade"
  id="supportModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="modalStakeAddLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title color-four font-bold" id="modalStakeAddLabel">

          {{ "home-dashboard.support.support" | translate }}
        </h5>
        <button type="button" class="btn-close" (click)="hide()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="color-four text-center mb-3">
           
            {{ "home-dashboard.support.message" | translate }}
          </div>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
              <label class="mb-2 color-four">
                
                {{ "home-dashboard.support.email" | translate }}
              </label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                placeholder="e-mail@e-mail.com"
              />
              
            </div>
            <ng-container *ngIf="submitted">
              <ng-container *ngFor="let err of vm.email">
                <div
                  class="form-text text-danger"
                  *ngIf="f['email'].hasError(err.type)"
                >
                  {{ err.message | translate }}
                </div>
              </ng-container>
            </ng-container>

            <div class="mb-3">
              <label class="color-four mb-2">
               
                {{ "home-dashboard.team.wallet" | translate }}
              </label>
              
                <input
                  type="text"
                  class="form-control"
                  formControlName="wallet"
                  class="form-control"
                  placeholder="0x..."
                />
              
            </div>

            <ng-container *ngIf="submitted">
              <ng-container *ngFor="let err of vm.wallet">
                <div
                  class="form-text text-danger"
                  *ngIf="f['wallet'].hasError(err.type)"
                >
                  {{ err.message | translate }}
                </div>
              </ng-container>
            </ng-container>
            <div class="mb-3">
              <label class="color-four mb-2">
               
                Hash
              </label>
              <input
                type="text"
                class="form-control"
                formControlName="hash"
              />
            </div>
            

            <div class="mb-3">
                <label class="color-four mb-2">
                  
                  {{ "home-dashboard.refers.code" | translate }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="code"
                />
              </div>
              <ng-container *ngIf="submitted">
                <ng-container *ngFor="let err of vm.code">
                  <div
                    class="form-text text-danger"
                    *ngIf="f['code'].hasError(err.type)"
                  >
                    {{ err.message | translate }}
                  </div>
                </ng-container>
              </ng-container>

              <div class="mb-3">
                <label class="color-four mb-2">
                  
                  {{ "home-dashboard.support.subject" | translate }}

                </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="subject"
                />
              </div>
              <ng-container *ngIf="submitted">
                <ng-container *ngFor="let err of vm.subject">
                  <div
                    class="form-text text-danger"
                    *ngIf="f['subject'].hasError(err.type)"
                  >
                    {{ err.message | translate }}
                  </div>
                </ng-container>
              </ng-container>

              <div class="mb-3">
                <label class="color-four mb-2">
                  {{ "home-dashboard.support.description" | translate }}

                </label>
                <textarea
                  type="text"
                  class="form-control"
                  formControlName="description"
                ></textarea>
              </div>
              <ng-container *ngIf="submitted">
                <ng-container *ngFor="let err of vm.description">
                  <div
                    class="form-text text-danger"
                    *ngIf="f['description'].hasError(err.type)"
                  >
                    {{ err.message | translate }}
                  </div>
                </ng-container>
              </ng-container>
            <div class="d-flex justify-content-center mt-4">
              <button type="submit" class="btn-one register">
                {{ "home-dashboard.support.send" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
