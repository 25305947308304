import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Web3Service } from "src/app/services/contract/web3.service";
import { BsModalService } from "src/app/services/bs-modal.service";
import { CustomTranslateService } from "src/app/services/custom-translate.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { environment } from "src/environments/environment";
import { cmIsCodeAvailable, cmIsWalletAvailable } from "src/app/helpers/custom-validations.helper";
import { SwapUserContractService } from "src/app/services/contract/swap-user-contract.service";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "app-register-modal",
  templateUrl: "./register-modal.component.html",
  styleUrls: ["./register-modal.component.css"],
})
export class RegisterModalComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() showCloseButton = true;

  /** Identificador de la modal */
  public mId = "registerModal";

  /** Objeto de la modal */
  private mi: any;

  public form: FormGroup;
  public vm: any = {
    code: [
      { type: "required", message: "is required" },
      { type: "codeNotAvailable", message: "the code is not available" },
      { type: "minlength", message: "min length is 3" },
      { type: "pattern", message: "invalid params" },
    ],
    wallet: [
      { type: "required", message: "is requiered" },
      { type: "walletNotAvailable", message: "Wallet is not available" },
    ],
    referedBy: [
      { type: "required", message: "is required" },
    ],
  };
  public submitted = false;

  public showSubmitLoaderButton = false;

  public GENESIS_CODE = environment.GENESIS_CODE;

  constructor(
    public fb: FormBuilder,
    public web3Srv: Web3Service,
    private alertStepsSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service,
    public translateSrv: CustomTranslateService,
    private bsModalSrv: BsModalService,
    private contractSrv: SwapUserContractService,
    private translatePipe: TranslatePipe
  ) {
    this.form = fb.group({
      code: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(/^[a-zA-Z0-9]+$/),
        ],
        [
          // checkCodevailable(this.web3Srv)
          cmIsCodeAvailable(this.contractSrv)
        ],
      ],
      wallet: [
        "",
        [ Validators.required ],
        [ cmIsWalletAvailable(this.contractSrv) ]
      ],
      referedBy: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9]+$/)
        ],
        [
          // cmIsReferredCodeAvailable(this.contractSrv)
        ]
      ],
    });
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { showCloseButton } = changes;
    if (showCloseButton) { this.showCloseButton = showCloseButton.currentValue; }
  }

  ngAfterViewInit(): void {
    this.mi = this.bsModalSrv.buildModal(this.mId);
  }

  get f() { return this.form.controls; }

  /**
   * @dev Actualizar configuración de la modal
   * @param opts 
   */
  public updateModalInstance(opts: any = {}) {
    const { backdrop = true, keyboard = true, focus = true} = opts;
    this.mi._config.backdrop = backdrop;
    this.mi._config.keyboard = keyboard;
    this.mi._config.focus = focus;
  }

  /**
   * Válidar el código de referido antes de aplicar al formulario
   * @param code 
   * @returns 
   */
  async checkReferredCode(code: string){
    try {
      const find = await  this.contractSrv.user_getUserByCode_OFFCHAIN(code);
      this.form.patchValue({ referedBy: (find.active) ? code : this.GENESIS_CODE });
      return;
       
    } catch (err) {
      console.log('Error on RegisterModalComponent.checkReferredCode', err);
      return;
    }
  }

  async show() {
    this.mi.show();

    try {
      this.showSubmitLoaderButton = true;
      this.form.disable();

      /**
       * - Obtener el código de génesis
       * - Establecer el código de génesis en el formulario
       */
      const genesisCode = await this.contractSrv.config_getGenesisCode_OFFCHAIN();
      this.GENESIS_CODE = genesisCode;

      /** Obtener el código de referido desde el LocalStorage */
      const code = localStorage.getItem('referredCode');

      /** Realizar válidación del código de referido */
      if (code) {
        await this.checkReferredCode(code);
      } else {
        this.form.patchValue({ referedBy: environment.GENESIS_CODE });
      }

      /** Establecer wallet conectada */
      this.form.patchValue({ wallet: this.web3Srv.accounts[0] });

      this.form.enable();
      this.showSubmitLoaderButton = false;
      return;

      
    } catch (err) {
      console.log('Error on RegisterModalComponent.show', err);
      return;
    }
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;
    console.log('_data', _data);

    if (!this.form.valid) { return; }

    try {

      const msjA = await this.translatePipe.transform('You will be registered with the code');
      const msjB = await this.translatePipe.transform('Are you sure?');


      const result = await this.alertStepsSrv.showStepsGeneral({
        service: this.contractSrv,
        askMessage: `${msjA}: ${_data.code}. ${msjB}`,
        method: 'user_addUserRed',
        params: [
          `${_data.code}`.trim().toLowerCase(),
          `${_data.referedBy}`.trim().toLowerCase()
        ]
      });

      if(!result.status){
        this.sweetAlert2Srv.showError(result.data.message);
        return 
      }

      /** Ocultar modal */
      this.hide();

      /** Eliminar código de referido del LocalStorage */
      localStorage.removeItem('referredCode');

      /** Mostrar mensaje de éxito */
      const msjC = await this.translatePipe.transform('You have been registered successfully')
      this.sweetAlert2Srv.showSuccess(msjC)
      .then(() => {
        window.location.href = '/pages/dashboard';
      });
      return;

    } catch (err) {
      console.log("Error on RegisterModalComponent.addUserRed", err);
      return;
    }
  }

  hide() {
    this.mi.hide();
    this.showCloseButton = true;
    this.form.patchValue({
      code: "",
      referedBy: "",
      wallet: ""
    });
  }

}
