<div class="modal fade" id="swapModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalStakeAddLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title color-four font-bold" id="modalStakeAddLabel">
          {{ "home-dashboard.swap-modal2.text" | translate}}
        </h5>
        <button type="button" class="btn-close" (click)="hide()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <ng-container *ngIf="tokenList as tokens">
            <ng-container *ngFor="let item of tokens">
              <ng-container *ngIf="item.type >2">
                <div class="crytop-swap-item d-flex align-items-center p-3" (click)="setPair(item)">
                  <img src="{{ item.imgToken2 }}" />

                  <div class="ps-2">
                    <p class="mb-0">
                      {{ item.token2 }}
                    </p>
                    <ng-container *ngIf="item.type != 3">
                      <span (click)="addtoken(item)"> {{ "home-dashboard.swap-modal1.importar" | translate}} </span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>