import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, Subscription, catchError, map, of, switchMap } from 'rxjs';
import { SwapUserContractService } from 'src/app/services/contract/swap-user-contract.service';
import { Web3Service } from 'src/app/services/contract/web3.service';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  styleUrls: ['./pages-layout.component.css']
})
export class PagesLayoutComponent implements OnInit, OnDestroy {

  public options: AnimationOptions = { path: '/assets/img/lottie/loading.json' };

  public dataStatus$!: Observable<any>;
  public dataStatus: any;

  private sub$!: Subscription;

  constructor(
    private web3Srv: Web3Service,
    private swapUserContractSrv: SwapUserContractService,
    private router: Router
  ) { }

  ngOnInit(): void {
    /** Obtiene el estado de la cuenta */
    // this.dataStatus$ = 

    this.sub$ = this.web3Srv.accountStatus$
    .pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),

      switchMap(async(addr: any) => {
        if(!addr) { 
          return {exist: false, addr: null, ref: null};
        }

        const ref = localStorage.getItem('referredCode') || null;
        const exist = await this.swapUserContractSrv.user_userWalletExist_OFFCHAIN(addr);

        return {exist, addr, ref};
      }),
      catchError((err) => of({
        exist: false,
        addr: null,
        ref: null
      }))
    )
    .subscribe((data: any) => {
      // console.log('dataStatus$', data);

      if(!data.exist && data.ref) { 
        // console.log('dataStatus$ - redirect');
        this.router.navigate(['/pages/referrals']); 
      }

      this.dataStatus = data;
    });

    /** Verificar si existe una sesión activa */
    this.web3Srv.checkAlreadyConnected();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  async launch() { return this.web3Srv.launchAskConnectionType(); }

}
