<div class="dropdown mx-3">
  <button
    class="btn-one dropdown-toggle"
    type="button"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    (click)="toggleButtonLenguage()"
  >
    <span>
      {{ "home-dashboard.support.support" | translate }}
    </span>
  </button>
  <ul
    class="dropdown-menu"
    id="dropdown-menu2"
    aria-labelledby="dropdownMenuButton1"
  >
    <li (click)="supportModal.show(); toggleButtonLenguage()">
      {{ "home-dashboard.support.email" | translate }}
    </li>
    <li (click)="toggleButtonLenguage()">
      <a target="_blank" href="https://web.whatsapp.com/"> WhatApp </a>
    </li>
  </ul>
</div>

<app-support-email></app-support-email>
