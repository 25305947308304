import { Component, OnInit } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-landing",
  templateUrl: "./header-landing.component.html",
  styleUrls: ["./header-landing.component.css"],
})
export class HeaderLandingComponent implements OnInit {
  options: AnimationOptions = {
    path: "/assets/img/lottie/Loaderblanco.json",
  };
  options1: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };

  options2: AnimationOptions = {
    path: "/assets/img/lottie/378.json",
  };

  constructor(private router: Router) {}

  ngOnInit() {
    let checkChangeColor: any = document.getElementById("changeColor");
    let changeColor = localStorage.getItem("changeColor");
    if (changeColor === "dark") {
      document.body.classList?.add("dark");
      checkChangeColor.checked = false;
    } else if (changeColor === "white") {
      document.body.classList?.add("white");
      checkChangeColor.checked = true;
    } else {
      document.body.classList?.add("dark");
      checkChangeColor.checked = false;
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  modeColor() {
    const checkbox: any = document.getElementById("changeColor");

    if (checkbox.checked) {
      document.body.classList?.add("white");
      document.body.classList?.remove("dark");
      localStorage.setItem("changeColor", "white");
    } else {
      document.body.classList?.add("dark");
      document.body.classList?.remove("white");
      localStorage.setItem("changeColor", "dark");
    }
  }

  closeMenu() {
    let menu = document.getElementById("navbarSupportedContent");
    menu?.classList.toggle("show");
  }

  scrollToElement(element: any, page: string): void {
    this.router.navigate(["/", page]).then(
      (nav) => {
        console.log(nav);
        // true if navigation is successful

        setTimeout(() => {
          let menuItem = document.querySelector(element);
          menuItem?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1);
      },
      (err) => {
        console.log(err); // when there's an error
      }
    );
  }
}
