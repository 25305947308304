import { Component, OnInit, ViewChild } from "@angular/core";
import { SupportEmailComponent } from "../support-email/support-email.component";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.css"],
})
export class SupportComponent implements OnInit {
  @ViewChild(SupportEmailComponent) public supportModal!: SupportEmailComponent;

  constructor() {}

  ngOnInit(): void {}

  toggleButtonLenguage() {
    let list: any = document.getElementById("dropdown-menu2");
    list.classList.toggle("show");
  }
}
