<div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title color-four font-bold">
          {{"Choose a token to exchange" | translate }}
        </h5>
        <button type="button" class="btn-close" (click)="hide()"></button>
      </div>

      <div class="modal-body">
        <div class="mb-3">
          <ng-container *ngIf="tokenList as tokens">
            <div class="crytop-swap-item d-flex align-items-center p-3"
              *ngFor="let item of tokens | slice : 0 : 4; let i = index" (click)="setPair(item)">
              <div class="icon-mnd"><img src="{{ item.imgToken1 }}" /></div>
              <div class="ps-2">
                <p class="mb-0">{{ item.token1 }}</p>
                <ng-container *ngIf="item.type != 0">
                  <span (click)="addtoken(item)">
                    {{ "home-dashboard.swap-modal1.importar" | translate}}
                  </span>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- <div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalStakeAddLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header">
        <div class="modal-title font-bold" id="modalStakeAddLabel">
          Choose a token to exchange
        </div>
        <button type="button" class="btn-close" (click)="hide()"></button>
      </div>

      <div class="modal-body">
        <div class="mb-3">

          <div class="text-center mb-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, voluptates?
          </div>

        </div>
      </div>
    </div>
  </div>
</div> -->
