import { Component, OnInit } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
})
export class LandingComponent implements OnInit {
  options: AnimationOptions = {
    path: "/assets/img/lottie/Loaderblanco.json",
  };
  options1: AnimationOptions = {
    path: "/assets/img/lottie/LoaderColor.json",
  };

  options2: AnimationOptions = {
    path: "/assets/img/lottie/378.json",
  };

  constructor() {}

  ngOnInit() {
    let html: any = document.querySelector("html");
    html.style.overflow = "hidden";
    let loading = document.querySelector(".loading");
    window.onload = () => {
      html.style.overflow = "";
      loading?.classList.add("d-none");
    };
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
