<app-sidebar-dashboard></app-sidebar-dashboard>

<div class="section-template">
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <app-navbar-dashboard></app-navbar-dashboard>
          <ng-container
            *ngIf="(dataStatus && dataStatus.addr); then renderConected; else renderNoConected"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderConected>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #renderNoConected>
  <div class="card-dashboard card-dashboard-no mt-3 d-flex flex-column justify-content-between align-items-center">
    <ng-lottie class="mt-3 animation-loading" [options]="options"></ng-lottie>
    <div>
      <div class="title-no text-center fw-bold">
        Before you start
      </div>
      <div class="text text-center fw-bold mb-3">you need to connect your wallet</div>
      <button class="btn-three fw-bold p-3 px-5" (click)="launch()">
        Connect wallet
      </button>
    </div>    
  </div>
</ng-template>