<section class="landing-features">
  <div class="container">
    <div class="row">
      <div *ngFor="let feature of data" class="col-12 col-md-6 col-lg-4">
        <div class="feature-item">
          <div class="feature-item-icon">
            <img
              class="img-fluid"
              src="{{ feature.icon }}"
              alt="icono corazon"
            />
          </div>

          <div class="feature-item-description">
            <h3>{{ feature.title }}</h3>
            <p>
              {{ feature.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
