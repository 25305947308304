<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" href="#">
      <div class="logo logo-white align-items-center">
        <img src="assets/img/logo-gray-l.png" alt="" />
        <ng-lottie
          class="animation-logo"
          [options]="options1"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </div>
      <div class="logo logo-dark align-items-center">
        <img src="assets/img/logo-white-l.png" alt="" />
        <ng-lottie
          class="animation-logo"
          [options]="options"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="closeMenu()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            (click)="closeMenu(); scrollToElement('#about', 'landing')"
          >
            About us
            <div class="line-menu"></div>
          </a>
        </li>
        <li
          class="nav-item"
          (click)="closeMenu(); scrollToElement('#home', 'landing')"
        >
          <a class="nav-link">Home </a>
        </li>
        <li
          class="nav-item"
          (click)="closeMenu(); scrollToElement('#ecosystem', 'landing')"
        >
          <a class="nav-link">Ecosystem</a>
        </li>
        <li
          class="nav-item"
          (click)="closeMenu(); scrollToElement('#products', 'landing')"
        >
          <a class="nav-link">Products</a>
        </li>
        <li
          class="nav-item"
          (click)="closeMenu(); scrollToElement('#roadmap', 'landing')"
        >
          <a class="nav-link">Road map</a>
        </li>
      </ul>
      <div
        class="d-flex flex-column flex-md-row justify-content-center align-items-center"
      >
        <div class="">
          <label class="switch mb-4 mb-md-0" (click)="modeColor()">
            <input id="changeColor" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
        <button class="btn-deg">Connect Wallet</button>
      </div>
    </div>
  </div>
</nav>
