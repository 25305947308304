import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, from, lastValueFrom, map, Observable, of, tap } from 'rxjs';
import { handlerArrayResult, handlerObjectResult } from '../../helpers/model.helper';
import { CommonService } from '../common.service';
import { environment } from 'src/environments/environment';
import firebase from "firebase/app";


@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  public collection = [environment.prefix, 'users'].join('_');

  constructor(
    public afs: AngularFirestore,
  ) { }

  /**
   * 
   * @returns 
   */

  getUserlocal() {
    let user: any = localStorage.getItem('user');
    return JSON.parse(user);
  }

  setUserlocal(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * Registrar
   * @param data 
   * @returns 
   */
  async store(data: any) {
    const snapshot = await this.afs.collection(this.collection).add(data);
    return snapshot.id;
  }


  /**
   * Actualizar
   * @param docId 
   * @param data 
   * @returns 
   */
  async update(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  async updateCounter(docId: string, field: string, value: number) {
    return await this.afs.collection(this.collection).doc(docId)
    .update({ [field]: firebase.firestore.FieldValue.increment(value) });
  }


  /**
   * Obtener a través del identificador
   * @param docId 
   * @returns 
   */
  getById(docId: string) {
    return this.afs.collection(this.collection).doc(docId).valueChanges()
  }


  /**
   * 
   * @param docId 
   * @returns 
   */
  async getByIdPromise(docId: string) {
    // console.log('docId', docId);

    const snapshot = await this.afs.collection(this.collection).doc(docId).get().toPromise();
    // console.log('snapshot', snapshot);

    return await handlerObjectResult(snapshot);
  }

  async getByNicknamePromise(code: string) {
    const snapshot = await this.afs.collection(this.collection
      ,(ref) => ref.where('nickname', '==', code).limit(1))
    .get().toPromise();
    const result = await handlerArrayResult(snapshot);
    return (result.length > 0) ? result.pop() : null;
  }

  async getByReferredCodePromise(code: string) {
    // console.log('code', code);
    const snapshot = await this.afs.collection(this.collection
      ,(ref) => ref.where('code', '==', code).limit(1))
    .get().toPromise();
    const result = await handlerArrayResult(snapshot);
    return (result.length > 0) ? result.pop() : null;
  }


  /**
   * Obtener código de quien refiere a traves de su wallet
   * @param addr 
   * @returns 
   */
  async getReferredByWalletPromise(addr: string) {
    return await this.getByIdPromise(addr);
  }

  /**
   * @dev Obtener total de usuarios registrados
   * TODO: revisar la efectividad de este método
   * @returns 
   */
  async getTotalUsers() {
    const snapshot = await this.afs.collection(this.collection).ref.get();
    return snapshot.size;
  }

  /**
   * Obtener listado completo
   * @returns 
   */
  async getAll() {
    const snapshot = await this.afs.collection(this.collection).ref.get();
    return await handlerArrayResult(snapshot);
  }

  getDynamicCount(where: any[] = []): Observable<number> {
    return this.afs.collection(this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }
        return query;
      }
    )
    .valueChanges()
    .pipe( map((data) => data.length) );
  }

  /**
   * Obtener listado dinamico
   * @param where 
   * @param where.field 
   * @param where.condition
   * @param where.value
   * @param opts 
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   * @param opts.startAt
   * @param opts.endAt
   * @param opts.limit
   * 
   * @returns 
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      startAfter = null,
      endAt = null,
      endBefore = null,
      orderBy = [],
      limit = null,
      limitToLast = null,
    } = opts;

    return this.afs.collection(this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }

        for (const order of orderBy) { query = query.orderBy(order.field, order.order); }

        if (startAt) { query = query.startAt(startAt); }

        if (typeof startAfter === 'string') { query = query.startAfter(startAfter); }

        if (Array.isArray(startAfter)) { query = query.startAfter(...startAfter); }

        if (typeof endAt === 'string') { query = query.endAt(endAt); }

        if (Array.isArray(endAt)) { query = query.endAt(...endAt); }

        if (endBefore) { query = query.endBefore(endBefore); }

        if (limit) { query = query.limit(limit); }

        if (limitToLast) { query = query.limitToLast(limitToLast); }

        return query;
      }
    ).valueChanges({ idField });
  }

  async getDynamicToPromise(where: any[] = [], opts: any = {}): Promise<any[]> {
    try {
      const {
        idField = "_id",
        startAt = null,
        endAt = null,
        orderBy = [],
      } = opts;

      const snapshot = await this.afs
        .collection(this.collection, (ref) => {
          let query: Query = ref;
          for (const row of where) {
            query = query.where(row.field, row.condition, row.value);
          }

          for (const order of orderBy) {
            query = query.orderBy(order.field, order.order);
          }

          if (startAt) {
            query = query.startAt(startAt);
          }

          if (endAt) {
            query = query.endAt(endAt);
          }

          return query;
        })
        .get()
        .toPromise();

      return await handlerArrayResult(snapshot, { idField });
    } catch (err) {
      console.log("Error on UserService.getDynamicToPromise", err);
      return [];
    }
  }

}

/**
 * Validat si el código de usuario ya se encuentra registrado
 * @param service 
 * @returns 
 */
export function checkWalletAddressRegistered(service: UserService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(service.getByIdPromise(control.value))
    .pipe(
      map((userDoc: any) => {
        return (!userDoc?.active) ? { walletAddressRegistered: true } : null;
      }),
      catchError((err) => of({ walletAddressRegistered: true }))
    )
  }
}