import { Component, OnInit, OnChanges, SimpleChanges, Input, OnDestroy } from "@angular/core";
import { Event, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Observable, Subscription, catchError, distinctUntilChanged, filter, from, interval, map, of, shareReplay, startWith, switchMap, tap } from "rxjs";
import { fromWei } from "src/app/helpers/utils";
import { CommonService } from "src/app/services/common.service";
import { SwapUserContractService } from "src/app/services/contract/swap-user-contract.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { UserService } from "src/app/services/firebase/user.service";
import { environment } from "src/environments/environment";

type WidgetKey = 'dashboard' | 'staking' | 'referrals' | 'swap' | 'tutorials' | 'marketing';
const segmentDefault: WidgetKey = 'dashboard';

@Component({
  selector: "app-header-tb",
  templateUrl: "./header-tb.component.html",
  styleUrls: ["./header-tb.component.css"],
})
export class HeaderTbComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public account: any;

  /** Observable que verifica si el usuario existe en la red */
  public isUserNetwork$!: Observable<boolean>;

  /** Total en USD de swap realizados */
  public totalSwap$ = of({total: 0});

  /** Total en USD de comisiones pagadas */
  public totalCommisions$ = of({total: 0});

  /** Nro de usuarios registrados */
  public totalUsers$ = of({total: 0});

  private sub$!: Subscription;

  /** =============================================== */

  @Input() public myWallet: any;

  public title: WidgetKey = "dashboard"; // Usar el tipo definido para title

  public widgetRules: { [key in WidgetKey]: { comission: boolean; balance: boolean; referrals: boolean } } = {
    "dashboard": { comission: false, balance: false, referrals: false },
    "staking": { comission: true, balance: true, referrals: true },
    "referrals": { comission: true, balance: true, referrals: true },
    "swap": { comission: true, balance: true, referrals: true },
    "tutorials": { comission: false, balance: false, referrals: false },
    "marketing": { comission: false, balance: false, referrals: false },
  };

  public showWidgets = this.widgetRules[this.title]; // Esto ya no debería causar un error

  public canShowWidgets = false;

  constructor(
    private router: Router,
    private web3Srv: Web3Service,
    private commonService: CommonService,
    private userSrv: UserService,
    private swapUserContractSrv: SwapUserContractService,
  ) { }

  ngOnInit(): void {
    /** Al cargar componente disparar función de verificar ruta */
    this.checkEventRouterChange(this.router.url);

    /** Escuchar evento de cambio de rutas */
    this.sub$ = this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof NavigationEnd)
    )
    .subscribe((event: any) => {
      const { url } = event;
      /** Verificar si la ruta actual es una ruta de widgets */
      this.checkEventRouterChange(url);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { panelTitle, account } = changes;
    // if (panelTitle && panelTitle.currentValue){ }

    if(account && account.currentValue){
      this.account = account.currentValue;
      // console.log('account', this.account);

      /** Verificar si el usuario existe en la red */
      this.isUserNetwork$ = from(this.swapUserContractSrv.user_userWalletExist_OFFCHAIN(this.account))
      .pipe( 
        catchError((err) => of(false)),
      );

      /** Crear observable buscando documento de usuario */
      const userDoc$ = this.userSrv.getById(this.account)
      .pipe(
        shareReplay(1)
      );

      this.totalSwap$ = userDoc$
      .pipe(
        map((data: any) => ({total: (data) ? data.globalBalance.totalVolumen : 0})),
        catchError((err) => of({total: 0}))
      );

      this.totalCommisions$ = userDoc$
      .pipe(
        map((data: any) => ({total: (data) ? data.balance.lifeTimeEarnings : 0})),
        catchError((err) => of({total: 0}))
      );

      this.totalUsers$ = userDoc$
      .pipe(
        map((data: any) => ({total: (data) ? data.balance.totalReferrals : 0})),
        catchError((err) => of({total: 0}))
      );
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  checkEventRouterChange(url: string){
    // console.log('checkEventRouterChange - url: ', url);

    const segment = (url.split("/")[2] || 'dashboard') as WidgetKey;
    // console.log('segment', segment);
    this.title = segment;
    // console.log('title', this.title);

    this.showWidgets = this.widgetRules[this.title] || this.widgetRules[segmentDefault];
    // console.log('showWidgets', this.showWidgets);

    this.canShowWidgets = Object.values(this.showWidgets).reduce((acc, val) => acc || val, false);
    // console.log('canShowWidgets', this.canShowWidgets);

    /** Verificar si la ruta actual es una ruta de widgets */
    // this.showWidgets = this.exculeWidgets.includes(url) ? false : true;

//   if (event.url === "/dashboard") {
      //     this.title = "main-panel";
      //     this.balance = false;
      //     // console.log(this.title);
      //   } else if (event.url === "/referidos") {
      //     this.title = "referidos";
      //     this.balance = true;
      //   } else if (event.url === "/swap") {
      //     this.title = "swap";
      //     this.balance = true;
      //   } else if (event.url === "/staking") {
      //     this.title = "staking";
      //     this.balance = true;
      //   } else if (event.url === "/tutorials") {
      //     this.title = "tutorials";
      //     this.balance = true;
      //   } else if (
      //     event.url === "/admin" ||
      //     event.url === "/admin/swap" ||
      //     event.url === "/admin/stake" ||
      //     event.url === "/admin/userSwap"
      //   ) {
      //     this.title = "administracion";
      //     this.balance = true;
      //   } else {
      //     this.title = "main-panel";
      //   }
      //   this.commissions = this.commonService.getComisions();
      //   this.myUsers = this.commonService.getUsers();
  }

}
