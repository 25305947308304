import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { HomeDashboardComponent } from "./dashboard/home-dashboard/home-dashboard.component";
// import { RefersComponent } from "./dashboard/refers/refers.component";
// import { StakingComponent } from "./dashboard/staking/staking.component";
// import { SwapComponent } from "./dashboard/swap/swap.component";
// import { TutorialsComponent } from "./dashboard/tutorials/tutorials.component";
// import { LandingComponent } from "./landing/landing.component";

const routes: Routes = [
  // {
  //   path: "dashboard",
  //   component: HomeDashboardComponent,
  // },
  // {
  //   path: "referidos",
  //   component: RefersComponent,
  // },
  // {
  //   path: "referidos/:code",
  //   component: RefersComponent,
  // },
  // {
  //   path: "swap",
  //   component: SwapComponent,
  // },
  // {
  //   path: "staking",
  //   component: StakingComponent,
  // },
  // {
  //   path: "tutorials",
  //   component: TutorialsComponent,
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
