<div class="products-landing" id="products">
  <div class="product-one">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4">
          <h3 class="title">Product #1</h3>
          <p class="text">
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quia non numquam eius modi tempora
            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <img class="img-fluid" src="assets/img/hands.png" alt="manos" />
        </div>
      </div>
    </div>
  </div>

  <div class="product-two">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4">
          <img class="img-fluid" src="assets/img/dolares.png" alt="manos" />
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <h3 class="title">Product #2</h3>
          <p class="text">
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quia non numquam eius modi tempora
            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="product-three">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4">
          <h3 class="title">Product #3</h3>
          <p class="text">
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quia non numquam eius modi tempora
            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <img class="img-fluid" src="assets/img/coins.png" alt="manos" />
        </div>
      </div>
    </div>
  </div>
</div>
