<section class="sidebar">
  <div class="c-sidebar">
    <div class="logo-s d-none d-xl-block">
      <img src="assets/img/icon-logo-white.svg" alt="" />
    </div>
    
    <div class="item-s" *ngIf="isUserRed" routerLink="/pages/dashboard" routerLinkActive="active">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="35.57" height="35.558" viewBox="0 0 35.57 35.558">
          <path id="home-Regular_1_"
            d="M237.588,1021.487l-9.121-7.333a8.724,8.724,0,0,0-10.864,0l-9.121,7.333a8.61,8.61,0,0,0-3.232,6.742v10.93a8.671,8.671,0,0,0,8.665,8.656h5.016v-8.656a4.1,4.1,0,0,1,8.209,0v8.656h5.016a8.669,8.669,0,0,0,8.665-8.656v-10.93A8.61,8.61,0,0,0,237.588,1021.487Zm.5,17.672a5.931,5.931,0,0,1-5.928,5.919h-2.28v-5.919a6.84,6.84,0,0,0-13.681,0v5.919h-2.28a5.931,5.931,0,0,1-5.928-5.919v-10.93a5.887,5.887,0,0,1,2.209-4.61l9.121-7.333a5.975,5.975,0,0,1,7.437,0l9.121,7.333a5.887,5.887,0,0,1,2.209,4.61v10.93Z"
            transform="translate(-205.25 -1012.257)" fill="#fffffb" />
        </svg>
      </div>
    </div>

    <div class="item-s" routerLink="/pages/staking" routerLinkActive="active">
      <div class="icon">
        <svg id="Grupo_52" data-name="Grupo 52" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" width="39.002" height="38.559" viewBox="0 0 39.002 38.559">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectángulo_111" data-name="Rectángulo 111" width="39.002" height="38.559" fill="#fff" />
            </clipPath>
          </defs>
          <g id="Grupo_52-2" data-name="Grupo 52" clip-path="url(#clip-path)">
            <path id="Trazado_77" data-name="Trazado 77"
              d="M0,31.007V7.55a2.661,2.661,0,0,0,.071-.29A8.282,8.282,0,0,1,8.26.014q11.231-.028,22.462,0a8.271,8.271,0,0,1,8.266,8.261q.029,11,0,22a8.271,8.271,0,0,1-8.264,8.264q-11.231.032-22.462,0A8.271,8.271,0,0,1,.214,32.082C.132,31.726.071,31.365,0,31.007M32.036,14.69a1.249,1.249,0,0,0,.03-.166c0-2.081.009-4.162-.009-6.243a1.514,1.514,0,0,0-.238-.744,1.434,1.434,0,0,0-1.35-.611q-10.945.011-21.89,0c-.114,0-.229,0-.343,0a1.306,1.306,0,0,0-1.274,1.1c-.064.738-.015,1.485-.015,2.256H8.718a1.254,1.254,0,0,1,1.4,1.41q0,2.265,0,4.53a1.27,1.27,0,0,1-1.457,1.438c-.568,0-1.135,0-1.72,0v3.211c.6,0,1.181,0,1.762,0a1.261,1.261,0,0,1,1.414,1.4q0,2.246,0,4.492c0,1.036-.448,1.482-1.484,1.482H6.925c0,.64,0,1.222,0,1.805a1.392,1.392,0,0,0,1.544,1.558q11.021,0,22.042,0a1.393,1.393,0,0,0,1.556-1.546q0-2.912,0-5.825c0-.121-.014-.242-.023-.388-.72,0-1.4,0-2.087,0a1.244,1.244,0,0,1-1.379-1.394q0-3.2,0-6.4a1.235,1.235,0,0,1,1.385-1.385c.683,0,1.366,0,2.072,0M2.437,28.281c0,.695-.005,1.353,0,2.012a5.852,5.852,0,0,0,5.793,5.812q11.27.026,22.541,0A5.826,5.826,0,0,0,36.55,30.32q.026-11.042,0-22.084a5.827,5.827,0,0,0-5.777-5.785q-11.27-.025-22.541,0A5.664,5.664,0,0,0,4.275,4.056c-1.839,1.712-1.991,3.91-1.8,6.206h2.01c0-.615,0-1.2,0-1.781A3.809,3.809,0,0,1,8.456,4.5q11.042-.007,22.084,0A3.805,3.805,0,0,1,34.5,8.483q0,10.794,0,21.589a3.809,3.809,0,0,1-4,3.99q-11,.005-22.008,0a3.818,3.818,0,0,1-4.008-4.022c0-.58,0-1.16,0-1.758ZM7.671,12.757H2.457v2.452H7.671Zm0,10.592H2.459V25.8H7.673ZM4.462,17.692H2.456v3.172H4.462Zm27.584-.541H31.035v4.254h1.011Z"
              transform="translate(0 0.001)" fill="#fff" />
            <path id="Trazado_78" data-name="Trazado 78"
              d="M120.118,116.234l2.246-.926c.115-1.1.478-1.557,1.261-1.546.763.01,1.15.511,1.21,1.543l2.261.936a1.32,1.32,0,0,1,1.28-.553,1.528,1.528,0,0,1,.692.354c.525.5.43,1.1-.234,1.95l.938,2.259c.089,0,.228-.006.366,0a1.209,1.209,0,0,1,1.169,1.211,1.166,1.166,0,0,1-1.159,1.207c-.394-.007-.487.156-.593.486-.194.6-.466,1.17-.718,1.784.677.922.74,1.494.192,2.008s-1.188.412-1.917-.235c-.736.277-1.446.53-2.141.821-.09.038-.136.263-.142.4a1.221,1.221,0,0,1-2.438.1c-.01-.151,0-.3,0-.406l-2.283-.939a1.3,1.3,0,0,1-1.333.545,1.468,1.468,0,0,1-.672-.392c-.491-.52-.377-1.135.277-1.9l-.935-2.243c-1.109-.141-1.55-.49-1.543-1.258s.46-1.108,1.544-1.222l.934-2.259a1.306,1.306,0,0,1-.55-1.305,1.5,1.5,0,0,1,.373-.682c.512-.509,1.122-.4,1.919.252m-.277,5.2a3.762,3.762,0,1,0,3.817-3.724,3.759,3.759,0,0,0-3.817,3.724"
              transform="translate(-104.106 -102.187)" fill="#fff" />
          </g>
        </svg>
      </div>
      <div class="label">
        {{ "home-dashboard.sidebar.staking" | translate }}
      </div>
    </div>

    <div class="item-s" routerLink="/pages/referrals" routerLinkActive="active">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="38.565" height="19.282" viewBox="0 0 38.565 19.282">
          <path id="groups_FILL0_wght400_GRAD0_opsz48"
            d="M0,355.282v-2.129a2.876,2.876,0,0,1,1.667-2.522,8.621,8.621,0,0,1,4.374-.973q.489,0,.94.02a8.577,8.577,0,0,1,.893.086,7.062,7.062,0,0,0-.482,1.413,6.8,6.8,0,0,0-.161,1.494v2.611Zm9.641,0v-2.611a4.175,4.175,0,0,1,.7-2.35,6.378,6.378,0,0,1,1.989-1.868,9.822,9.822,0,0,1,3.073-1.205,17.67,17.67,0,0,1,3.877-.4,17.869,17.869,0,0,1,3.917.4,9.822,9.822,0,0,1,3.073,1.205,6.156,6.156,0,0,1,1.968,1.868,4.264,4.264,0,0,1,.683,2.35v2.611Zm21.693,0v-2.611a7.69,7.69,0,0,0-.141-1.5,6.212,6.212,0,0,0-.462-1.4,8.053,8.053,0,0,1,.891-.089q.449-.02.917-.02a8.708,8.708,0,0,1,4.369.955,2.863,2.863,0,0,1,1.657,2.54v2.129Zm-19.282-2.41H26.513v-.241q0-1.486-2.029-2.43a12.376,12.376,0,0,0-5.2-.944,12.376,12.376,0,0,0-5.2.944q-2.029.944-2.029,2.471Zm-6.043-4.419a2.812,2.812,0,0,1,.017-5.624,2.812,2.812,0,0,1,2.812,2.829,2.689,2.689,0,0,1-.824,1.971A2.73,2.73,0,0,1,6.008,348.453Zm26.513,0a2.812,2.812,0,1,1,2.006-4.8,2.73,2.73,0,0,1,.824,2.006,2.689,2.689,0,0,1-.824,1.971A2.73,2.73,0,0,1,32.522,348.453Zm-13.239-2.812a4.821,4.821,0,1,1,0-9.641,4.821,4.821,0,1,1,0,9.641Zm.014-2.41a2.3,2.3,0,0,0,1.713-.7,2.371,2.371,0,0,0,.683-1.727,2.344,2.344,0,0,0-2.4-2.4,2.366,2.366,0,0,0-1.721.689,2.3,2.3,0,0,0-.7,1.707,2.391,2.391,0,0,0,2.424,2.424ZM19.282,352.872ZM19.282,340.821Z"
            transform="translate(0 -336)" fill="#fff" />
        </svg>
      </div>
      <div class="label">
        {{ "home-dashboard.sidebar.referidos" | translate }}
      </div>
    </div>

    <div class="item-s" routerLink="/pages/swap" routerLinkActive="active">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="38.565" height="38.565" viewBox="0 0 38.565 38.565">
          <path id="currency_exchange_FILL0_wght400_GRAD0_opsz48"
            d="M21.282,40.565a17.424,17.424,0,0,1-9.466-2.892,25.066,25.066,0,0,1-7.187-6.617v6H2V26.541H12.518v2.629h-6a21.621,21.621,0,0,0,6.376,6.07,15.245,15.245,0,0,0,8.392,2.7,16.136,16.136,0,0,0,6.464-1.315,16.7,16.7,0,0,0,8.874-8.874,16.136,16.136,0,0,0,1.315-6.464h2.629a18.72,18.72,0,0,1-1.512,7.494A19.431,19.431,0,0,1,28.776,39.053,18.72,18.72,0,0,1,21.282,40.565ZM20.012,33.86V31.493A8.165,8.165,0,0,1,16.7,29.806a9.176,9.176,0,0,1-2.257-3.177l2.235-.745a5,5,0,0,0,1.863,2.629,5.075,5.075,0,0,0,3.046.964,4.785,4.785,0,0,0,2.914-.855,2.67,2.67,0,0,0,1.161-2.257,3.144,3.144,0,0,0-1.1-2.432,15.219,15.219,0,0,0-4.032-2.213,12.739,12.739,0,0,1-3.944-2.366,4.534,4.534,0,0,1-1.315-3.418,4.554,4.554,0,0,1,1.315-3.287A5.835,5.835,0,0,1,20.1,10.984V8.749h2.41v2.235A5.953,5.953,0,0,1,25.4,12.036a6.236,6.236,0,0,1,1.972,2.41l-2.1,1.008a4.8,4.8,0,0,0-1.621-1.841A4.154,4.154,0,0,0,21.37,13a4.2,4.2,0,0,0-2.7.789,2.6,2.6,0,0,0-.986,2.147,2.629,2.629,0,0,0,1.139,2.235,18.807,18.807,0,0,0,3.681,1.884A12.22,12.22,0,0,1,26.8,22.729a5.208,5.208,0,0,1,1.271,3.637,5.064,5.064,0,0,1-.394,2.016,4.5,4.5,0,0,1-1.118,1.578,5.756,5.756,0,0,1-1.775,1.074,8.788,8.788,0,0,1-2.366.548V33.86ZM2,21.282a18.72,18.72,0,0,1,1.512-7.494A19.431,19.431,0,0,1,13.789,3.512,18.72,18.72,0,0,1,21.282,2a17.424,17.424,0,0,1,9.466,2.892,25.066,25.066,0,0,1,7.187,6.617v-6h2.629V16.024H30.047V13.394h6A21.706,21.706,0,0,0,29.7,7.325a15.2,15.2,0,0,0-8.414-2.7,16.136,16.136,0,0,0-6.464,1.315,16.7,16.7,0,0,0-8.874,8.874,16.136,16.136,0,0,0-1.315,6.464Z"
            transform="translate(-2 -2)" fill="#fff" />
        </svg>
      </div>
      <div class="label">{{ "home-dashboard.sidebar.swap" | translate }}</div>
    </div>

    <div class="item-s" *ngIf="isUserRed || isAdmin || isUserAdm" routerLink="/pages/tutorials" routerLinkActive="active">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="38.969" height="38.969" viewBox="0 0 38.969 38.969">
          <path id="play_circle_FILL0_wght400_GRAD0_opsz48"
            d="M94.759,203.765l13.006-8.281L94.759,187.2Zm4.725,11.2a18.853,18.853,0,0,1-7.55-1.534,19.631,19.631,0,0,1-10.4-10.4,19.463,19.463,0,0,1,0-15.149,19.472,19.472,0,0,1,4.189-6.186,19.973,19.973,0,0,1,6.211-4.165,19.463,19.463,0,0,1,15.149,0,19.454,19.454,0,0,1,10.351,10.351,19.463,19.463,0,0,1,0,15.149,19.973,19.973,0,0,1-4.165,6.211,19.472,19.472,0,0,1-6.186,4.189A18.973,18.973,0,0,1,99.484,214.969Zm0-2.923A16.548,16.548,0,1,0,87.769,207.2,15.937,15.937,0,0,0,99.484,212.046ZM99.484,195.484Z"
            transform="translate(-80 -176)" fill="#fff" />
        </svg>
      </div>
      <div class="label">
        {{ "home-dashboard.sidebar.tutorials" | translate }}
      </div>
    </div>

    <div class="item-s" *ngIf="account" routerLink="/pages/marketing" routerLinkActive="active">
      <div class="icon">
        <img src="assets/img/MK.svg" alt="">
      </div>
      <div class="label">
        {{ "home-dashboard.sidebar.marketing" | translate }}
      </div>
    </div>

    <div class="item-s" *ngIf="isAdmin || isUserAdm" routerLink="/admin" routerLinkActive="active">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
          <path id="database_FILL0_wght400_GRAD0_opsz48"
            d="M24,42a31.36,31.36,0,0,1-12.775-2.325Q6,37.35,6,34V14q0-3.3,5.275-5.65A31.147,31.147,0,0,1,24,6,31.147,31.147,0,0,1,36.725,8.35Q42,10.7,42,14V34q0,3.35-5.225,5.675A31.36,31.36,0,0,1,24,42Zm0-24.4a31.326,31.326,0,0,0,8.825-1.325Q37.35,14.95,38.65,13.3q-1.35-1.6-5.875-2.95A30.633,30.633,0,0,0,24,9a31.435,31.435,0,0,0-8.85,1.3q-4.45,1.3-5.85,3,1.4,1.75,5.8,3.025A31.827,31.827,0,0,0,24,17.6Zm-.05,10.7a39.4,39.4,0,0,0,4.2-.225,33.223,33.223,0,0,0,4.025-.675,30.456,30.456,0,0,0,3.675-1.1A21.281,21.281,0,0,0,39,24.85V17.1a23.706,23.706,0,0,1-3.2,1.45,29.978,29.978,0,0,1-3.7,1.1,38.508,38.508,0,0,1-4,.7,34.251,34.251,0,0,1-4.15.25,35.5,35.5,0,0,1-4.2-.25,36.777,36.777,0,0,1-4.025-.7,29.18,29.18,0,0,1-3.65-1.1A19.555,19.555,0,0,1,9,17.1v7.75a19.965,19.965,0,0,0,3.05,1.45,27.971,27.971,0,0,0,3.625,1.1,33.222,33.222,0,0,0,4.025.675,39.867,39.867,0,0,0,4.25.225ZM24,39a30.1,30.1,0,0,0,4.95-.425,31.522,31.522,0,0,0,4.675-1.125,19.413,19.413,0,0,0,3.6-1.55A3.63,3.63,0,0,0,39,34.15V27.9a19.692,19.692,0,0,1-3.15,1.425A33.787,33.787,0,0,1,32.175,30.4a33.359,33.359,0,0,1-4,.675,39.169,39.169,0,0,1-4.225.225,39.867,39.867,0,0,1-4.25-.225,33.223,33.223,0,0,1-4.025-.675,30.859,30.859,0,0,1-3.625-1.075A18.49,18.49,0,0,1,9,27.9v6.3a3.5,3.5,0,0,0,1.7,1.725,16.652,16.652,0,0,0,3.6,1.55,34.821,34.821,0,0,0,4.7,1.1A30.384,30.384,0,0,0,24,39Z"
            transform="translate(-6 -6)" fill="#fff" />
        </svg>
      </div>
      <div class="label">
        {{ "home-dashboard.sidebar.administracion" | translate }}
      </div>
    </div>

    <div class="item-s no-border  btn-logout" *ngIf="account" (click)="logOut()">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24.313" height="24.313" viewBox="0 0 24.313 24.313">
          <path id="logout_FILL0_wght400_GRAD0_opsz48_1_" data-name="logout_FILL0_wght400_GRAD0_opsz48 (1)"
            d="M8.026,30.313A2.077,2.077,0,0,1,6,28.287V8.026A2.077,2.077,0,0,1,8.026,6h9.827V8.026H8.026V28.287h9.827v2.026Zm16.412-6.247-1.452-1.452L26.43,19.17H14.611V17.144H26.362L22.918,13.7l1.452-1.452,5.943,5.943Z"
            transform="translate(30.313 30.313) rotate(180)" fill="#ffffff" />
        </svg>
        <div class="label">
          {{ "home-dashboard.sidebar.logout" | translate }}
        </div>
      </div>
    </div>

  </div>
</section>