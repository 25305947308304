<div class="modal fade modalRef" [id]="mId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <button type="button" class="btn-close" aria-label="Close" (click)="hide()"></button>

      <div class="modal-body">
        <div class="item-referals pb-3">
          <ng-container *ngIf="(item); then renderDocument; else renderNoDocument"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderDocument>
  <div class="header-r d-flex justify-content-between">
    <div>{{item.createdAt | date : "medium"}}</div>
    <div class="label-t">{{(item.status) ? 'Active': 'Inactive'}}</div>
  </div>

  <div class="img-avatr">
    <div class="avtar">
      <img src="assets/img/icon-avatar.png" alt="" />
    </div>
  </div>

  <div class="c-wallet d-flex justify-content-between align-items-center mb-3">
    <span class="fw-bold">{{ item.addr | truncateWalletAddress }}</span>
    <div class="icon" (click)="copy(item.addr)">
      <i class="bi bi-files"></i>
    </div>
  </div>

  <div class="text-info-r d-flex align-items-center mt-3 mb-2">
    <div class="label-code pe-2">Code:</div>
    <div class="label-txt px-3">{{item.nickname | uppercase}}</div>
    <div class="dec d-flex"></div>
  </div>

  <div class="text-info-r d-flex align-items-center">
    <div class="label-code pe-2">Sponsor:</div>
    <div class="label-txt px-3">{{item.referredBy | uppercase}}</div>
    <div class="dec d-flex"></div>
  </div>

  <div class="c-amount">
    <div class="label">Amount</div>
    <div class="fs-1 fw-bold">${{item.balance.swap | number:'1.2-2'}}</div>
  </div>
</ng-template>

<ng-template #renderNoDocument>
  <div class="header-r d-flex justify-content-center">
    <div>
      No records found
    </div>
  </div>
</ng-template>

<ng-template #noPurchases>
  <div class="fs-1 fw-bold">$0</div>
</ng-template>