<section class="d-none d-lg-block">
  <ul class="nav nav-tabs justify-content-between mt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
      <button class="nav-link active" id="staking-tab" data-bs-toggle="tab" data-bs-target="#staking-tab-pane" type="button" role="tab" aria-controls="staking-tab-pane" aria-selected="true">
          <div class="icon"><img src="assets/img/que-es-staking.svg" alt=""> </div>                
          {{"landing.itemTabs1" | translate }}
      </button>
      </li>
      <li class="nav-item" role="presentation">
      <button class="nav-link" id="funciona-tab" data-bs-toggle="tab" data-bs-target="#funciona-tab-pane" type="button" role="tab" aria-controls="funciona-tab-pane" aria-selected="false">
          <div class="icon"><img src="assets/img/como-funciona.svg" alt=""></div> {{"landing.itemTabs2" | translate }}
      </button>
      </li>
      <li class="nav-item" role="presentation">
      <button class="nav-link" id="ventajas-tab" data-bs-toggle="tab" data-bs-target="#ventajas-tab-pane" type="button" role="tab" aria-controls="ventajas-tab-pane" aria-selected="false">
          <div class="icon"><img src="assets/img/ventajas.svg" alt=""></div> {{"landing.itemTabs3" | translate }}
      </button>
      </li>
  </ul>
  <div class="reds d-flex d-md-none">
    
      <div class="item-red">
          <a href="https://t.me/remitt_io" target="_blank">
              <i class="bi bi-telegram"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.tiktok.com/@remitt.io" target="_blank">
              <i class="bi bi-tiktok"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.youtube.com/@remitt" target="_blank">
              <i class="bi bi-youtube"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.facebook.com/FintechRemitt" target="_blank">
              <i class="bi bi-facebook"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://twitter.com/Remitt_io" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
            </svg>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.instagram.com/remitt.io/" target="_blank">
              <i class="bi bi-instagram"></i>
          </a>
      </div>
    
  </div>
  <div class="d-flex d-lg-none justify-content-center">
      <div class="label-c" data-bs-toggle="modal" data-bs-target="#Terms">{{ "landing.Security" | translate}}</div>
      <!-- <div class="label-c">{{ "landing.Audits" | translate}}</div> -->
      <div class="label-c"><a href="mailto:info@remitt.io" target="_blank">{{ "landing.Support" | translate}}</a></div>
  </div>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="staking-tab-pane" role="tabpanel" aria-labelledby="staking-tab" tabindex="0">
      <div class="parrf">
        <p>
          {{ "landing.itemTabs1P" | translate}}
        </p>
        <div class="img-tabs-c">
          <img src="assets/img/staking-new.png" alt="">
        </div>
      </div>
      <div class="d-none d-lg-flex justify-content-center">
        <div class="label-c" data-bs-toggle="modal" data-bs-target="#Terms">{{ "landing.Security" | translate}}</div>
        <!-- <div class="label-c">{{ "landing.Audits" | translate}}</div> -->
        <div class="label-c"><a href="mailto:info@remitt.io" target="_blank">{{ "landing.Support" | translate}}</a></div>
      </div>
      <!-- <div class="d-flex justify-content-center my-3">
        <app-btn-connect></app-btn-connect>
      </div>               -->
    </div>
    <div class="tab-pane fade" id="funciona-tab-pane" role="tabpanel" aria-labelledby="funciona-tab" tabindex="0">
      <div class="parrf d-flex justify-content-between">
        <div class="item-t2">
          <p [innerHTML]="'landing.itemTabs2P1' | translate"></p>
          <div class="img-tabs-c">
            <img src="assets/img/funcionamiento-1.png" alt="">
          </div>
        </div>
        <div class="item-t2">
          <p [innerHTML]="'landing.itemTabs2P2' | translate"></p>
          <div class="img-tabs-c">
            <img src="assets/img/funcionamiento-2.png" alt="">
          </div>
        </div>
        <div class="item-t2">
          <p [innerHTML]="'landing.itemTabs2P3' | translate"></p>
          <div class="img-tabs-c">
            <img src="assets/img/funcionamiento-3.png" alt="">
          </div>
        </div>
        <!-- <p [innerHTML]="'landing.itemTabs2P1' | translate"></p>
        <p [innerHTML]="'landing.itemTabs2P2' | translate"></p>
        <p [innerHTML]="'landing.itemTabs2P3' | translate"></p> -->
        <!-- <div class="img-tabs-c">
          <img src="assets/img/funcionamiento.png" alt="">
        </div> -->
      </div>
      <div class="d-none d-lg-flex justify-content-center">
        <div class="label-c" data-bs-toggle="modal" data-bs-target="#Terms">{{ "landing.Security" | translate}}</div>
        <!-- <div class="label-c">{{ "landing.Audits" | translate}}</div> -->
        <div class="label-c"><a href="mailto:info@remitt.io" target="_blank">{{ "landing.Support" | translate}}</a></div>
      </div> 
      <!-- <div class="d-flex justify-content-center my-3">
        <app-btn-connect></app-btn-connect>
      </div>     -->
    </div>
    <div class="tab-pane fade" id="ventajas-tab-pane" role="tabpanel" aria-labelledby="ventajas-tab" tabindex="0">
      <div class="parrf">
        <p>
          {{ "landing.itemTabs3P" | translate}}
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P1" | translate}}</strong>
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P2" | translate}}</strong>
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P3" | translate}}</strong>
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P4" | translate}}</strong>
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P5" | translate}}</strong>
        </p>
        <p class="itempt">
          <span class="dec"></span>
          <strong>{{ "landing.itemTabs3P6" | translate}}</strong>
        </p>
        <p>
          {{ "landing.itemTabs3P7" | translate}}
        </p>
        <div class="img-tabs-c mt-3">
          <img src="assets/img/ventajas staking-new.png" alt="">
        </div>
      </div>
      <div class="d-none d-lg-flex justify-content-center">
        <div class="label-c" data-bs-toggle="modal" data-bs-target="#Terms">{{ "landing.Security" | translate}}</div>
        <!-- <div class="label-c">{{ "landing.Audits" | translate}}</div> -->
        <div class="label-c"><a href="mailto:info@remitt.io" target="_blank">{{ "landing.Support" | translate}}</a></div>
      </div> 
      <!-- <div class="d-flex justify-content-center my-3">
        <app-btn-connect></app-btn-connect>
      </div>     -->
    </div>
  </div>
</section>

<section class="d-block d-lg-none">
    <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              <div class="icon pe-1"><img src="assets/img/que-es-staking.svg" alt=""> </div>                
              {{ "landing.itemTabs1" | translate }}
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <div class="parrf">
                <p>
                  {{ "landing.itemTabs1P" | translate }}
                </p>
                <div class="img-tabs-c">
                  <img src="assets/img/staking-new.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              <div class="icon pe-1"><img src="assets/img/como-funciona.svg" alt=""></div>  {{ "landing.itemTabs2" | translate }}
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <div class="parrf">
                <div class="item-t2">
                  <p [innerHTML]="'landing.itemTabs2P1' | translate"></p>
                  <div class="img-tabs-c">
                    <img src="assets/img/funcionamiento-1.png" alt="">
                  </div>
                </div>
                <div class="item-t2">
                  <p [innerHTML]="'landing.itemTabs2P2' | translate"></p>
                  <div class="img-tabs-c">
                    <img src="assets/img/funcionamiento-2.png" alt="">
                  </div>
                </div>
                <div class="item-t2">
                  <p [innerHTML]="'landing.itemTabs2P3' | translate"></p>
                  <div class="img-tabs-c">
                    <img src="assets/img/funcionamiento-3.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              <div class="icon pe-1"><img src="assets/img/ventajas.svg" alt=""></div> {{ "landing.itemTabs3" | translate }}
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <div class="parrf">
                <p>
                  {{ "landing.itemTabs3P" | translate }}
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P1" | translate }}</strong>
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P2" | translate }}</strong>
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P3" | translate }}</strong>
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P4" | translate }}</strong>
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P5" | translate }}</strong>
                </p>
                <p class="itempt">
                  <span class="dec"></span>
                  <strong>{{ "landing.itemTabs3P6" | translate }}</strong>
                </p>
                <p>
                  {{ "landing.itemTabs3P7" | translate }}
                </p>
                <div class="img-tabs-c mt-3">
                  <img src="assets/img/ventajas staking-new.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    
    <div class="reds d-flex d-md-none">
      <div class="item-red">
        <a href="https://t.me/remitt_io" target="_blank">
            <i class="bi bi-telegram"></i>
        </a>
      </div>
      <div class="item-red">
          <a href="https://www.tiktok.com/@remitt.io" target="_blank">
              <i class="bi bi-tiktok"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.youtube.com/@remitt" target="_blank">
              <i class="bi bi-youtube"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.facebook.com/FintechRemitt" target="_blank">
              <i class="bi bi-facebook"></i>
          </a>
      </div>
      <div class="item-red">
          <a href="https://twitter.com/Remitt_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
            </svg>
          </a>
      </div>
      <div class="item-red">
          <a href="https://www.instagram.com/remitt.io/" target="_blank">
              <i class="bi bi-instagram"></i>
          </a>
      </div>
        <!-- <div class="item-red p-2">
            <a href=""><img src="assets/img/icon-telegram.png" alt=""></a>
        </div>
        <div class="item-red my-lg-2">
            <a href=""><img src="assets/img/x-twitter.svg" alt=""></a>
        </div>
        <div class="item-red">
            <a href=""><img src="assets/img/instagram.svg" alt=""></a>
        </div> -->
    </div>
    <div class="d-flex d-lg-none justify-content-center">
      <div class="label-c" data-bs-toggle="modal" data-bs-target="#Terms">{{ "landing.Security" | translate}}</div>
      <!-- <div class="label-c">{{ "landing.Audits" | translate}}</div> -->
      <div class="label-c"><a href="mailto:info@remitt.io" target="_blank">{{ "landing.Support" | translate}}</a></div>
    </div>
</section>



