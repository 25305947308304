import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { environment } from 'src/environments/environment';
import BigNumber from 'bignumber.js';
import { fromWei } from 'src/app/helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class SwapUserContractService {

  public abi = '/assets/abi/RemittSwapUsers.json';

  public contractAddress = environment.contract.swapUser;

  constructor(
    private web3Srv: Web3Service
  ) { }

  async administered_isAdmin_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addAdmin(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_isUser_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_removeUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      params: null,
      callType: "send",
      urlABI: this.abi,
    });
  }

  async config_getUserConfig_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getUserConfig",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_getGenesisCode_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getGenesisCode",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_setGenesisCode(code: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setGenesisCode",
      params: [code],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async user_userListCount_OFFCHAIN() {
    const snapshot = await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "userListCount",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
    return new BigNumber(snapshot).toNumber();
  }
  
  async user_addUserRed(nickname: string, referredBy: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUserRed",
      params: [nickname, referredBy],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async user_addUserRedAsAdmin(nickname: string, wallet: string, referredBy: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUserRedAsAdmin",
      params: [nickname, wallet, referredBy],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async user_updateUser(idx: string, ot: number, bool: boolean, val: string, code: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "updateUser",
      params: [idx, ot, bool, val, code],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async user_userCodeExist_OFFCHAIN(code: string): Promise<boolean> {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "userCodeExist",
      callType: "call",
      params: [code],
      urlABI: this.abi,
    });
  }

  async user_userWalletExist_OFFCHAIN(addr: string): Promise<boolean> {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "userWalletExist",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async user_getUserByCode_OFFCHAIN(code: string): Promise<User> {
    const snapshot = await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getUserByCode",
      callType: "call",
      params: [code],
      urlABI: this.abi,
    });

    return this._parseUser(snapshot);
  }

  async user_getUserByWallet_OFFCHAIN(addr: string): Promise<User> {
    const snapshot = await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getUserByWallet",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });

    return this._parseUser(snapshot);
  }

  _parseUser(snapshot: any): User {
    return {
      index: new BigNumber(snapshot.index).toNumber(),
      myCode: snapshot.myCode,
      userReferrer: snapshot.userReferrer,
      referredBy: snapshot.referredBy,
      active: snapshot.active,
      vip: snapshot.vip,
      discount: new BigNumber(snapshot.discount).toNumber(),
      commission: new BigNumber(snapshot.commission).toNumber(),
      swapLimit: new BigNumber(fromWei(snapshot.swapLimit)).toNumber(),
      timestamp: new BigNumber(snapshot.timestamp).toNumber(),
      updatedAt: new BigNumber(snapshot.updatedAt).toNumber(),
      level: new BigNumber(snapshot.level).toNumber(),
    }
  }

  async user_getTotalVipSwap_OFFCHAIN(idx: string | number): Promise<number> {
    const snapshot = await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getTotalVipSwap",
      callType: "call",
      params: [idx],
      urlABI: this.abi,
    });
    return new BigNumber(snapshot).toNumber();
  }

}

export interface User {
  index: number;
  myCode: string;
  userReferrer: string;
  referredBy: string;
  active: boolean;
  vip: boolean;
  discount: number;
  commission: number;
  swapLimit: number;
  timestamp: number;
  updatedAt: number;
  level: number;
}
