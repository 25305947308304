import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from './web3.service';
import { AbiService } from './abi.service';

@Injectable({
  providedIn: 'root'
})
export class SwapContractService {

  public abi = '/assets/abi/RemittSwap.json';

  public contractAddress = environment.contract.swapNew;

  constructor(
    private web3Srv: Web3Service,
    private abiService: AbiService,
  ) {
    // this.parseAbi();
  }

  async parseAbi(){
    const abi = await this.abiService.parseABI(this.abi);
    console.log('abi', abi);
  }

  async administered_isAdmin_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addAdmin(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_isUser_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_removeUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      params: null,
      callType: "send",
      urlABI: this.abi,
    });
  }

  async withdraw_withdrawCrypto(amount: string, to: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawCrypto",
      params: [amount, to],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async withdraw_withdrawERC20(token: string, amount: string, to: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawERC20",
      params: [token, amount, to],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async whitelist_tokensList_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "tokensList",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async whitelist_addToken(addr: string, oracle: string, status: boolean, isNative: boolean) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addToken",
      params: [addr, oracle, status, isNative],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async whitelist_updateToken(idx: number, ot: number, addr: string, status: boolean) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "updateToken",
      params: [idx, ot, addr, status],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async whitelist_parseUSDtoToken_OFFCHAIN(amount: string, token: string, isNative: boolean) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "parseUSDtoToken",
      callType: "call",
      params: [amount, token, isNative],
      urlABI: this.abi,
    });
  }
  
  async whitelist_getTotalUSDBalance(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getTotalUSDBalance",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async whitelist_getUSDPrice_OFFCHAIN(token: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getUSDPrice",
      callType: "call",
      params: [token],
      urlABI: this.abi,
    });
  }

  async mainToken_MAIN_TOKEN_OFFCHAIN(): Promise<string> {
    const snapshot =  await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "MAIN_TOKEN",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
    return  snapshot;
  }

  async mainToken_getPriceToken_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getPriceToken",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async user_getUserContractAddress_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getUserContractAddress",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async sale_getSaleContractAddress_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getSaleContractAddress",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async partner_getPartnerDistributionContractAddress_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getPartnerDistributionContractAddress",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_ADDRESS_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_ADDRESS",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_LIMIT_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_LIMIT",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_PERCENTAGE_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_PERCENTAGE",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_DEV_ADDRESS_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_DEV_ADDRESS",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_DEV_LIMIT_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_DEV_LIMIT",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_RESERVE_DEV_PERCENTAGE_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "RESERVE_DEV_PERCENTAGE",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_POOL_ADDRESS_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "POOL_ADDRESS",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_POOL_PERCENTAGE_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "POOL_PERCENTAGE",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_BINARY_INFINITY_ADDRESS_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "BINARY_INFINITY_ADDRESS",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async config_BINARY_INFINITY_PERCENTAGE_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "BINARY_INFINITY__PERCENTAGE",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async limit_getMintLimit_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getMintLimit",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async party_setPartyContract(ot: number, addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setPartyContract",
      params: [ot, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async party_setPartyValues(ot: number, addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setPartyValues",
      params: [ot, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async swap_buyWithCode(ot: number, atb: string, token: string, code: string, value: string = '0') {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "buyWithCode",
      params: [
        [ot, atb, token, code],
      ],
      callType: "send",
      optionals: { value },
      urlABI: this.abi,
    });
  }

  async swap_buyAnonymous(atb: string, token: string, value: string = '0') {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "buyAnonymous",
      params: [atb, token],
      callType: "send",
      optionals: { value },
      urlABI: this.abi,
    });
  }

}
