import { Component, Input, OnInit, SimpleChanges, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { BsModalService } from 'src/app/services/bs-modal.service';
import { PurchasesService } from 'src/app/services/firebase/purchases.service';
import { catchError, filter, from, map, Observable, of, tap } from 'rxjs';
import { UserService } from 'src/app/services/firebase/user.service';
import { PurchaseManagementService } from 'src/app/services/firebase/purchase-management.service';
import BigNumber from 'bignumber.js';
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { ClipboardService } from 'ngx-clipboard';
import { ReportPurchasesService } from 'src/app/services/report-purchases.service';
import { error } from 'console';

@Component({
  selector: 'app-history-from-team',
  templateUrl: './history-from-team.component.html',
  styleUrls: ['./history-from-team.component.css']
})
export class HistoryFromTeamComponent implements OnInit {
  
  private mh: any;

  public codeBalance$!: Observable<any>;

  // public salesList: any[] = [];

  @Input() obj: any;


  private result: any;
  // public history$!: Observable<any>;

  public walletToCopy: string = "";
  public amount: any;

  public myCode: any;

  @ViewChild('walletToCopy') walletToCopyInput!: ElementRef<HTMLInputElement>;

  constructor(
    private bsModalSrv: BsModalService,
    private purchasesService: PurchasesService,
    private userService: UserService,
    private purchasesManagement: PurchaseManagementService,
    private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService,
    private sweetAlert2Srv: Sweetalert2Service,
    private _clipboardService: ClipboardService,
    private renderer: Renderer2,
    private reportPurchasesService: ReportPurchasesService
  ) { }

  ngOnInit(): void {
  }

  getTotalPurchase(wallet: string, code: string | null = null){
    this.codeBalance$ = from(this.reportPurchasesService.getMyTotalPurchase(wallet, code))
    .pipe(
      map((data) => new BigNumber(data.totalAmount).toFixed(2)),
      catchError(err => of(0))
    )
  }

  ngOnChanges(changes: SimpleChanges){  
    if(this.obj){
      console.log("recivido", this.obj)
      this.getTotalPurchase(this.obj._id, this.obj.referredBy)
    }
  }

  copy(id: string) {
    const messageA = this.translatePipe.transform('home-dashboard.qr.copied');
    const messageB = this.translatePipe.transform('home-dashboard.qr.success');


    const cliper = document.createElement('div');
    cliper.textContent = id;
    document.body.appendChild(cliper);


    const range = document.createRange();
    range.selectNodeContents(cliper);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    document.execCommand('copy');


    document.body.removeChild(cliper);

    this.sweetAlert2Srv.showToast(messageA, messageB);
  }

}
