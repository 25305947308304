import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbiService } from './services/contract/abi.service';
import { Web3Service } from "./services/contract/web3.service";
import { CustomTranslateService } from './services/custom-translate.service';
import { readNavegatorLanguage } from './helpers/readNavegatorLanguage.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  authenticated: boolean = false;
  data: string[] | undefined;

  constructor(
    private abiService: AbiService,
    private web3Srv: Web3Service,
    public translateSrv: CustomTranslateService,
  ) {

    const cl = localStorage.getItem("language")
    console.log('cl', cl);

    /** No existe el idioma */
    if(!cl){
      this.translateSrv.changeLanguage('en');
    } else {
      this.translateSrv.loadLocalLanguage();
    }
  }

  ngOnInit(): void {
    /** Leer idioma del navegador */
    const browserLanguage = readNavegatorLanguage();
    console.log('browserLanguage', browserLanguage);
    this.translateSrv.changeLanguage(browserLanguage);

    // this.test();
  }

  async test(){
    let r = await this.abiService.parseABI('/assets/abi/RemittSwapPartnerDistribition.json');
    console.log(r);
  }

  modeColor(){
    document.body.classList?.toggle('dark')
  }


}
