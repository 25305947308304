<!-- <div class="flex flex-col justify-center items-center h-screen">
  <div class="mt-1 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
    Get into web3
  </div>
  <H2> {{ data }}</H2>

  <div class="flex flex-col p-3">
    <button class="bg-orange-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" (click)="Connect()" >
      Connect your wallet xxx
    </button>
  </div>

</div> -->
<router-outlet></router-outlet>

<ngx-spinner size="medium" type="triangle-skew-spin" [fullScreen]="true">
  <p style="color: white">
    {{ "home-dashboard.my-stakes.loading" | translate }}
  </p>
</ngx-spinner>
