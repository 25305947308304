import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, combineLatest, debounceTime, from, map, Observable, of, Subscription, switchMap } from "rxjs";
import { Web3Service } from "src/app/services/contract/web3.service";
import { CustomTranslateService } from "src/app/services/custom-translate.service";

@Component({
  selector: "app-header-dashboard",
  templateUrl: "./header-dashboard.component.html",
  styleUrls: ["./header-dashboard.component.css"],
})
export class HeaderDashboardComponent implements OnInit, OnDestroy {

  public dataStatus$!: Observable<any>;

  public currentLanguage = 'en';

  private sub$!: Subscription;

  constructor(
    private web3Srv: Web3Service,
    private router: Router,
    public translateSrv: CustomTranslateService,
  ) {
    this.currentLanguage = this.translateSrv.currentLanguage;
  }

  ngOnInit(): void {
    /** Escuchar sesión */
    this.sub$ = this.web3Srv.accountStatus$.pipe(
      debounceTime(1000),
      // tap((data) => console.log('data', data)),
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap((data: any) => {

        if(!data) { throw new Error('No hay cuenta'); }

        return combineLatest([
          of(data),
          from(this.web3Srv.vendorV2_users_userWalletExist_OFFCHAIN(data)),
          from(this.web3Srv.vendorV2_administered_isUser_OFFCHAIN(data)),
          from(this.web3Srv.vendorV2_administered_isAdmin_OFFCHAIN(data)),
        ]);
      }),
      map(([data, isUserNetwork, isUserSwap, isAdminSwap]) => {
        return {
          addr: data,
          isUserNetwork,
          isUserSwap,
          isAdminSwap,
        };
      }),
      catchError((err) => {
        console.log('err', err);
        return of({
          addr: null,
          isUserNetwork: false,
          isUserSwap: false,
          isAdminSwap: false,
        });
      })
    )
    .subscribe((data) => {
      console.log('data', data);

      const ot = [Number(data.isUserNetwork), Number(data.isUserSwap), Number(data.isAdminSwap)].reduce((a, b) => Number(a) * Number(b));
      console.log('ot', ot);

      // Obtener código de referido
      const referredCode =  localStorage.getItem("referredCode") || null;
      // console.log('referredCode', referredCode);

      // Ya es usuario
      if(ot === 1) {
        this.router.navigate([`/pages/dashboard`]);

      // No es usuario y viene con código de referido
      } else if (referredCode) {
        this.router.navigate([`/pages/referrals`]);

      // No es usuario y no tiene código de referido
      } else {
        this.router.navigate([`/pages/swap`]);
      }
    });

    /** Verificar si existe sesión iniciada */
    // this.web3Srv.checkAlreadyConnected();
  }
  
  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  async connectAccountMetamask() {
    return this.web3Srv.connectAccountMetaMask();
  }

  async connectAccountWalletConnect() {
    return this.web3Srv.connectAccountWalletConnect();
  }

  /**
   * Cambiar idioma de la aplicación
   * @param language              Idioma a cambiar
   */
  changeLanguage(language: string) {
    let list: any = document.getElementById("languaje");
    list.classList.toggle("show");
    this.translateSrv.changeLanguage(language);
    this.currentLanguage = language;
  }

}
