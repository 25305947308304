import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { fromWei } from "../helpers/utils";
import { Web3Service } from "./contract/web3.service";
import { TranslatePipe } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';
@Injectable({
  providedIn: "root",
})
export class Sweetalert2stepsService {
  public title = environment.projectName;



  constructor(private contractSrv: Web3Service,private translatePipe: TranslatePipe,
    public translateSrv: CustomTranslateService,) {}

  async call(method: string, params: any[] | null) {
    return !params
      ? await this.contractSrv[method]()
      : await this.contractSrv[method](...params);
  }

  /**
   * Mostrar alerta basica
   * @param message
   * @param type
   * @returns
   */
  async showBasicAlert(message: string, type: any = "success") {
    return await Swal.fire(this.title, message, type);
  }

  /**
   * Mostrar alerta con hash de la transacción
   * @param opts
   * @returns
   */
  async showAlertWithTxHash(opts: any) {
    const { transactionHash, icon = "success" } = opts;
    const messageTran = await this.translatePipe.transform('home-dashboard.global.tran');


    return await Swal.fire({
      title: this.title,
      icon,
      html:
        "<a href='" +
        environment.chain.blockExplorerUrls +
        "tx/" +
        transactionHash +
        `' target='_blank'>${messageTran}</a>`,
      confirmButtonText: "OK",
    });
  }

  /**
   * Seguir transacción con token externo
   * @param params
   * @param params.actionMessage
   * @param params.checkBalanceParams
   * @param params.checkBalanceParams.contract
   * @param params.checkBalanceParams.amount
   * @param params.approvedParams
   * @param params.contractParams
   * @param params.contractParams.method
   * @param params.contractParams.params
   * @returns
   */
  async showStepsWithApproved(params: any) {
    const {
      actionMessage = "Confirm",
      actionMessageHTML = "",
      checkBalanceParams,
      approvedParams,
      contractParams,
    } = params;
    const steps = ["1", "2", "3", "4"];
    // console.log('params', params);

    /**
     * Construir modal base
     */
    const messageYes = await this.translatePipe.transform('home-dashboard.global.yes');
    const  messageNo = await this.translatePipe.transform('home-dashboard.global.no');


    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: messageYes,
      showCancelButton: true,
      cancelButtonText:  messageNo,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: actionMessage,
      html: actionMessageHTML,
      currentProgressStep: 0,
    });

    // console.log({ask});

    if (!ask) {
      // console.log('Transacción Cancelada');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 0,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Validar balance del usuario
     */
    const [account] = this.contractSrv.accounts;
    const messageCheck = await this.translatePipe.transform('home-dashboard.global.check');

    const { value: checkUserBalance } = await Queue.fire({
      title: this.title,
      text: messageCheck,
      currentProgressStep: 1,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading(null);
          Queue.enableInput();

          const checkBalance = await this.contractSrv.checkUserBalanceERC20(
            checkBalanceParams.contract,
            checkBalanceParams.amount
          );
          if (checkBalance) {
            return Queue.clickConfirm();
          }

          return Queue.clickCancel();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsWithApproved#checkUserBalance",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });

    if (!checkUserBalance) {
      // console.log('fail user balance');
      const messageOut = await this.translatePipe.transform('home-dashboard.global.out');

      return {
        step: 1,
        data: { message: messageOut },
        status: false,
      };
    }

    /**
     * Aprovar manipulación de fondos del usuario hacia el SC
     */
    const messageApprove = await this.translatePipe.transform('home-dashboard.global.approve');

    const { value: approved } = await Queue.fire({
      title: this.title,
      text: messageApprove,
      currentProgressStep: 2,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        Queue.showLoading(null);

        try {
          const approve = await this.call("approve2", approvedParams);
          console.log({ approve });

          if (!approve) {
            return Queue.clickCancel();
          }

          return Queue.clickConfirm();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsWithApproved#approve",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });

    if (!approved) {
      // console.log('Aprobado Cancelado');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 2,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Solicitar firma en la transacción
     */
    let transactionRecord: any;
    const messageCon = await this.translatePipe.transform('home-dashboard.global.cTransaction');

    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: messageCon,
      currentProgressStep: 3,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading(null);
          Queue.enableInput();
          // Queue.clickConfirm();

          const transaction = await this.call(
            contractParams.method,
            contractParams.params
          );
          transactionRecord = transaction;

          console.log({ transaction });
          return Queue.clickConfirm();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsWithApproved#transaction",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });
    // console.log({transactionRecord, transactionStatus});

    if (!transactionStatus) {
      // console.log('transaction canceled');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 3,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Retornar resultado de la transacción
     */
    return { step: 3, data: transactionRecord, status: true };
  }

  /**
   * Seguir transacción con token nativo
   * @param params
   * @param params.actionMessage
   * @param params.checkBalanceParams
   * @param params.checkBalanceParams.amount
   * @param params.checkBalanceParams.token
   * @param params.checkBalanceParams.token.decimal
   * @param params.contractParams
   * @param params.contractParams.method
   * @param params.contractParams.params
   * @returns
   */
  async showStepsNative(params: any) {
    const {
      actionMessage = "Confirm",
      checkBalanceParams,
      contractParams,
    } = params;

    console.log({ params });

    const steps = ["1", "2", "3"];
    const messageYes = await this.translatePipe.transform('home-dashboard.global.yes');
    const messageNo = await this.translatePipe.transform('home-dashboard.global.no');


    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: messageYes,
      showCancelButton: true,
      cancelButtonText:  messageNo,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: actionMessage,
      currentProgressStep: 0,
    });

    // console.log({ask});

    if (!ask) {
      // console.log('Transacción Cancelada');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 0,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Validar balance del usuario
     */
    const [account] = this.contractSrv.accounts;
    const messageCheck = await this.translatePipe.transform('home-dashboard.global.check');

    const { value: checkUserBalance } = await Queue.fire({
      title: this.title,
      text: messageCheck,
      currentProgressStep: 1,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading(null);
          Queue.enableInput();

          const checkBalance = await this.contractSrv.checkUserBalanceNative(
            checkBalanceParams.amount
          );
          console.log("checkBalance", { checkBalance });
          if (checkBalance) {
            return Queue.clickConfirm();
          }

          return Queue.clickCancel();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsWithApproved#checkUserBalance",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });

    if (!checkUserBalance) {
      // console.log('fail user balance');
      const messageOut = await this.translatePipe.transform('home-dashboard.global.out');

      return {
        step: 1,
        data: { message: messageOut },
        status: false,
      };
    }

    /**
     * Solicitar firma en la transacción
     */
    const messageCon = await this.translatePipe.transform('home-dashboard.global.cTransaction');

    let transactionRecord: any;
    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: messageCon,
      currentProgressStep: 2,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading(null);
          Queue.enableInput();
          // Queue.clickConfirm();

          const transaction = await this.call(
            contractParams.method,
            contractParams.params
          );
          transactionRecord = transaction;

          console.log({ transaction });
          return Queue.clickConfirm();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsNative#transaction",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });
    // console.log({transactionRecord, transactionStatus});

    if (!transactionStatus) {
      // console.log('transaction canceled');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 2,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Retornar resultado de la transacción
     */
    return { step: 2, data: transactionRecord, status: true };
  }

  async showStepsGeneral(params: any) {
    const { askMessage, contractParams } = params;
    const steps = ["1", "2"];

    // console.log({ contractParams });
    const messageYes = await this.translatePipe.transform('home-dashboard.global.yes');
    const messageNo = await this.translatePipe.transform('home-dashboard.global.no');


    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText:  messageYes,
      showCancelButton: true,
      cancelButtonText:  messageNo,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: askMessage,
      currentProgressStep: 0,
    });

    // console.log({ask});

    if (!ask) {
      // console.log('Transacción Cancelada');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 0,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Solicitar firma en la transacción
     */
    const messageCon = await this.translatePipe.transform('home-dashboard.global.cTransaction');

    let transactionRecord: any;
    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: messageCon,
      currentProgressStep: 1,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading(null);
          Queue.enableInput();
          // Queue.clickConfirm();

          const transaction = await this.call(
            contractParams.method,
            contractParams.params
          );
          transactionRecord = transaction;

          console.log({ transaction });
          return Queue.clickConfirm();
        } catch (err) {
          console.log(
            "Error on Sweetalert2stepsService@showStepsGeneral#transaction",
            { err }
          );
          return Queue.clickCancel();
        }
      },
    });
    // console.log({transactionRecord, transactionStatus});

    if (!transactionStatus) {
      // console.log('transaction canceled');
      const messageCancel = await this.translatePipe.transform('home-dashboard.global.cancel');

      return {
        step: 1,
        data: { message: messageCancel },
        status: false,
      };
    }

    /**
     * Retornar resultado de la transacción
     */
    return { step: 1, data: transactionRecord, status: true };
  }
}
