<div class="select-connec-g" (click)="toggleSelect($event)">
  <div class="btn-one-g text-uppercase">
    {{"landing.conect" | translate}}
    <i class="bi bi-caret-down-fill"></i>
  </div>
  <div class="select-options">
    <div class="my-3">
      {{"landing.howConect" | translate}}
    </div>
    <div class="btn-conec-option d-none d-lg-flex align-items-center" (click)="connectAccountMetamask()">
      <img src="assets/img/metamask.svg" alt="">
      <span class="ps-2">Metamask</span>
    </div>
    <div class="btn-conec-option d-flex align-items-center" (click)="connectAccountWalletConnect()">
      <img src="assets/img/walletConnect.svg" alt="">
      <span class="ps-2">WalletConnect</span>
    </div>
  </div>
</div>