<!-- countdown.component.html -->
<div class="countdown mt-2 d-lg-flex justify-content-center align-items-center">
    <h1 class="title">
        {{"landing.launchDate" | translate }}
    </h1>
    <div class="c-countdown d-flex justify-content-center">
        <div class="c-item">
            <div class="item">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' aria-labelledby='title' role='graphic'>
                    <!-- <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stop-color="#2E90F5" />
                        <stop offset="100%" stop-color="#06F8BC" />
                    </linearGradient> -->
                    <circle cx="50" cy="50" r="40"></circle>
                    <circle class="colorBar" cx="50" cy="50" r="40"  [style.stroke-dasharray]="getDashArray()"
                        [style.stroke-dashoffset]="getDashOffsetDays()"></circle>
                </svg>
                <div class="text-c">
                    {{ countdown.days }}
                </div>
            </div>
            <div class="text-center text-small">{{"landing.days" | translate }}</div>
        </div>
        <div class="c-item">
            <div class="item">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' aria-labelledby='title' role='graphic'>
                    <!-- <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stop-color="#2E90F5" />
                        <stop offset="100%" stop-color="#06F8BC" />
                    </linearGradient> -->
                    <circle cx="50" cy="50" r="40"></circle>
                    <circle cx="50" cy="50" r="40" class="colorBar" [style.stroke-dasharray]="getDashArray()"
                        [style.stroke-dashoffset]="getDashOffsetHours()"></circle>
                </svg>
                <div class="text-c">
                    {{ countdown.hours }}
                </div>
            </div>
            <div class="text-center text-small">{{"landing.hours" | translate }}</div>
        </div>
        <div class="c-item">
            <div class="item">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' aria-labelledby='title' role='graphic'>
                    <!-- <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stop-color="#2E90F5" />
                        <stop offset="100%" stop-color="#06F8BC" />
                    </linearGradient> -->
                    <circle cx="50" cy="50" r="40"></circle>
                    <circle cx="50" cy="50" r="40" class="colorBar" [style.stroke-dasharray]="getDashArray()"
                        [style.stroke-dashoffset]="getDashOffsetMinutes()"></circle>
                </svg>
                <div class="text-c">
                    {{ countdown.minutes }}
                </div>
            </div>
            <div class="text-center text-small">{{"landing.minutes" | translate }}</div>
        </div>
        <div class="c-item">
            <div class="item">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' aria-labelledby='title' role='graphic'>
                    <!-- <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stop-color="#2E90F5" />
                        <stop offset="100%" stop-color="#06F8BC" />
                    </linearGradient> -->
                    <circle cx="50" cy="50" r="40"></circle>
                    <circle cx="50" cy="50" r="40" class="colorBar" [style.stroke-dasharray]="getDashArray()"
                        [style.stroke-dashoffset]="getDashOffsetSeconds()"></circle>
                </svg>
                <div class="text-c">
                    {{ countdown.seconds }}
                </div>
            </div>
            <div class="text-center text-small">{{"landing.seconds" | translate }}</div>
        </div>
    </div>
</div>