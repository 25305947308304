import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-stake-option-item-loader',
  templateUrl: './card-stake-option-item-loader.component.html',
  styleUrls: ['./card-stake-option-item-loader.component.css']
})
export class CardStakeOptionItemLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
