<div class="titleBalance d-flex">
  <div class="labelTitle d-flex align-items-center text-capitalize">
    {{ title | translate | titlecase }}
  </div>

  <div class="modo-color-container">
    <app-mode-color></app-mode-color>
  </div>

  <ng-container *ngIf="(isUserNetwork$ | async); then renderWidgets"></ng-container>

  <!-- <ng-container *ngIf="isUserRed == true">

    <ng-container *ngIf="title as title">

      <ng-container *ngIf="title != 'main-panel'">

        <div class="d-none d-xl-flex" *ngIf="balance == true">

          <div class="d-flex balanc-h align-items-center">
            <div class="item-b balanc-one d-flex align-items-center">
              <div class="icon d-flex align-items-center justify-content-center">
                <img src="assets/img/icon-generate.png" alt="" />
              </div>
              <div class="info d-flex justify-content-center flex-fill ms-2">
                <span class="fw-bold value">${{commissions}}</span>
              </div>
            </div>
          </div>

          <div class="d-flex balanc-h align-items-center">
            <div class="item-b balanc-two d-flex align-items-center">
              <div class="icon d-flex align-items-center justify-content-center">
                <img src="assets/img/comission.png" alt="" />
              </div>
              <div class="info d-flex justify-content-center flex-fill ms-2">
                <span class="fw-bold value"> {{ ( contract | balance:myWallet | async) }} </span>
              </div>
            </div>
          </div>

          <div class="d-flex balanc-h align-items-center">
            <div class="item-b balanc-three d-flex align-items-center">
              <div class="icon d-flex align-items-center justify-content-center">
                <img src="assets/img/total-user.png" alt="" />
              </div>
              <div class="info three d-flex justify-content-center flex-fill ms-2">
                <span class="fw-bold value circle">{{myUsers}}</span>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

    </ng-container>

  </ng-container> -->
</div>


<ng-template #renderWidgets>
  <div class="d-none d-xl-flex" *ngIf="canShowWidgets">

    <div class="d-flex balanc-h align-items-center" *ngIf="showWidgets.comission">
      <div class="item-b balanc-one d-flex align-items-center">
        <div class="icon d-flex align-items-center justify-content-center">
          <img class="img-dark" src="assets/img/icon-world.png" alt="" />
          <img class="img-white" src="assets/img/icon-world-white.png" alt="" />
        </div>
        <div class="info d-flex justify-content-center flex-fill ms-2">
          <span class="fw-bold value">
            <ng-container 
              *ngIf="(totalSwap$ | async) as data; else renderLoader"
              [ngTemplateOutlet]="renderTotal" [ngTemplateOutletContext]="{value: data.total}"
            ></ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="d-flex balanc-h align-items-center" *ngIf="showWidgets.balance">
      <div class="item-b balanc-two d-flex align-items-center">
        <div class="icon d-flex align-items-center justify-content-center">
          <img src="assets/img/comission.png" alt="" />
        </div>
        <div class="info d-flex justify-content-center flex-fill ms-2">
          <span class="fw-bold value">
            <ng-container 
              *ngIf="(totalCommisions$ | async) as data; else renderLoader"
              [ngTemplateOutlet]="renderTotal" [ngTemplateOutletContext]="{value: data.total}"
            ></ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="d-flex balanc-h align-items-center" *ngIf="showWidgets.referrals">
      <div class="item-b balanc-three d-flex align-items-center">
        <div class="icon d-flex align-items-center justify-content-center">
          <img src="assets/img/total-user.png" alt="" />
        </div>
        <div class="info three d-flex justify-content-center flex-fill ms-2">
          <span class="fw-bold value">
            <ng-container 
              *ngIf="(totalUsers$ | async) as data; else renderLoader"
              [ngTemplateOutlet]="renderTotal" [ngTemplateOutletContext]="{value: data.total}"
            ></ng-container>
          </span>
        </div>
      </div>
    </div>

  </div>
</ng-template>
<!-- ngTemplateOutlet]="outlet" [ngOutletContext]="context" -->
<ng-template #renderTotal let-value="value">
  {{value | customNumber:2}}
</ng-template>

<ng-template #renderLoader>
  <div class="spinner-border spinner-border-sm text-primary" role="status">
    <span class="visually-hidden">{{"loading" | translate}}</span>
  </div>
</ng-template>