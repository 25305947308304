import { Component, OnInit } from '@angular/core';
import { catchError, from, map, Observable, of } from 'rxjs';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomTranslateService } from 'src/app/services/custom-translate.service';
import { StakeContractService } from 'src/app/services/contract/stake-contract.service';

@Component({
  selector: 'app-my-stakes',
  templateUrl: './my-stakes.component.html',
  styleUrls: ['./my-stakes.component.css']
})
export class MyStakesComponent implements OnInit {

  public list$!: Observable<any[]>;
  public listLength: number = 0;
  public spinner = false;
  public symbol = environment.mainToken.symbol;
  public stateButton: any = [];
  public list: any;

  public account!: any;

  public stakeList$!: Observable<any[]>;

  constructor(
    private web3Srv: Web3Service,
    public translateSrv: CustomTranslateService,
    private stakeContractSrv: StakeContractService
  ) {
    this.account = this.web3Srv.accounts[0];
  }

  ngOnInit(): void {
    /** Cargar listado de mis stakes */
    this.loadData();
  }

  loadData(){
    this.stakeList$ = from(this.stakeContractSrv.stakeable_stakeHolderResumen_OFFCHAIN(this.account))
    .pipe(
      switchMap((data: any) => {
        if(Number(data.totalRecords) === 0) { return of([]); }
        return from(this.stakeContractSrv.stakeable_stakeHolderRecordsPaginated_OFFCHAIN(this.account, 0, Number(data.totalRecords)))
      }),
      map((data: any[]) => 
        data.map((row: any, index: number) => ({...row, idx: index}))
        .filter((row: any) => row.status > 0)
      ),
      catchError((err) => of([]))
    );
  }

  onClaimReward(e: any){ this.loadData(); }

}
