import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {

  private targetDate: Date = new Date('2024-03-01T00:00:00'); // Reemplaza con tu fecha objetivo
  countdown: any = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    percentage: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  };

  constructor() { }

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.updateCountdown(); // Actualiza el valor inicial

    setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }


  updateCountdown() {
    const currentTime = new Date().getTime();
    const difference = this.targetDate.getTime() - currentTime;

    if (difference > 0) {
      this.countdown.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.countdown.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.countdown.seconds = Math.floor((difference % (1000 * 60)) / 1000);

      this.countdown.percentage.days = (this.countdown.days / 365) * 100;
      this.countdown.percentage.hours = (this.countdown.hours / 24) * 100;
      this.countdown.percentage.minutes = (this.countdown.minutes / 60) * 100;
      this.countdown.percentage.seconds = (this.countdown.seconds / 60) * 100;
    } else {
      this.countdown = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        percentage: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      };

    }
  }
  
  // PARA SVG DIAS
  getDashOffsetDays() {
    const p = (1 - this.countdown.percentage.days / 100) * (2 * (22 / 7) * 40);
    return `${p}px`;
  }
  // PARA SVG HORAS
  getDashOffsetHours() {
    const p = (1 - this.countdown.percentage.hours / 100) * (2 * (22 / 7) * 40);
    return `${p}px`;
  }
  // PARA SVG HORAS
  getDashOffsetMinutes() {
    const p = (1 - this.countdown.percentage.minutes / 100) * (2 * (22 / 7) * 40);
    return `${p}px`;
  }
  // PARA SVG HORAS
  getDashOffsetSeconds() {
    const p = (1 - this.countdown.percentage.seconds / 100) * (2 * (22 / 7) * 40);
    return `${p}px`;
  }

  getDashArray() {
    const p = (2 * (22 / 7) * 40);
    return `${p}px`;
  }

}
