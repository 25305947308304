import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button-three',
  templateUrl: './button-three.component.html',
  styleUrls: ['./button-three.component.css']
})
export class ButtonThreeComponent implements OnInit, OnChanges {

  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled } = changes;

    if (disabled) { this.disabled = disabled.currentValue; }
  }

}
