import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, catchError, from, map, of } from 'rxjs';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-main-token-access-card',
  templateUrl: './main-token-access-card.component.html',
  styleUrls: ['./main-token-access-card.component.css']
})
export class MainTokenAccessCardComponent implements OnInit, OnChanges {

  @Input() contract: any;

  public roles$!: Observable<any>;

  constructor(
    private web3Srv: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
  ) { }

  ngOnInit(): void {
    // console.log('contract', this.contract);

    /** Si se recibe la dirección */
    if(this.contract){ this.loadRules(); }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { contract } = changes;

    if(contract && contract.currentValue){
      this.contract = contract.currentValue;
      // this.rules$ = this.contract.methods.rules().call()
      this.loadRules();
    }
  }

  loadRules(){
    this.roles$ = from(this.web3Srv.mainToken_administered_isUser_OFFCHAIN(this.contract))
    .pipe(
      map((isUser) => ({isUser})),
      catchError((err) => of({isUser: false}))
    );
  }

  async onSubmit() {
    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: "You are going to add this address as an user. Are you sure?",
        contractParams: {
          method: 'mainToken_administered_addUser',
          params: [ this.contract ]
        }
      });

      if(!result.status){
        this.alertStepsSrv.showBasicAlert(result.data.message,'error');
        return;
      }

      this.alertStepsSrv.showBasicAlert("The address has been added as an user",'success');
      this.loadRules();
      return;

    } catch (err) {
      console.log('Error on MainTokenAccessCardComponent@addAdmin', err);
      return;
    }
  }

}
