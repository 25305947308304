import * as moment from "moment";
import { fromWei } from "./utils";
import BigNumber from "bignumber.js";


export function calculateStakeCurrentReward(records: any[], currentDate = moment()){
    let reward = '0';

    for (const row of records) {
      const startTime = moment.unix(row.startTime);
      const endTime = moment.unix(row.endTime);
      // const rewardRate = fromBasicPoint(row.rewardRate);
      const totalPeriodReward = fromWei(row.reward);

      // la fecha actual es mayor a endTime
      if(currentDate.isAfter(endTime)) {
        // reward += totalPeriodReward;
        reward = new BigNumber(reward).plus(totalPeriodReward).toFixed();

      // la fecha actual esta dentro del rango
      } else {
        // Optimización aquí: calcular la duración total y el tiempo transcurrido en una sola línea
        const proportionOfPeriod = currentDate.diff(startTime, 'seconds') / endTime.diff(startTime, 'seconds');
        const periodReward = totalPeriodReward * proportionOfPeriod;
        // reward += periodReward;
        reward = new BigNumber(reward).plus(periodReward).toFixed();
      }
    }
    
    return reward;
}