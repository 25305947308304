import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {

  transform(value: string | number, decimals = 2): number {
    const numero = new BigNumber(value);
    const truncated = numero.decimalPlaces(decimals, BigNumber.ROUND_DOWN);
    return new BigNumber(truncated.toString()).toNumber();
  }

}
