import { Component, OnInit } from "@angular/core";
import { catchError, map, Observable, of, switchMap } from "rxjs";
import { SwapContractService } from "src/app/services/contract/swap-contract.service";
import { Web3Service } from "src/app/services/contract/web3.service";


@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.css"],
})
export class AdminLayoutComponent implements OnInit {

  public dataStatus$!: Observable<any>;

  constructor(
    private web3Srv: Web3Service,
    private swapContractSrv: SwapContractService,
  ) { }

  ngOnInit(): void {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (data: any) => {
        const snap = {addr: null, isAdmin: false, isUser: false};

        if(!data) { return snap; }

        const [
          isUser,
          isAdmin,
        ] = await Promise.all([
          this.swapContractSrv.administered_isUser_OFFCHAIN(data),
          this.swapContractSrv.administered_isAdmin_OFFCHAIN(data),
        ]);

        return {
          addr: data,
          isAdmin,
          isUser,
        };
      }),
      catchError((err) => {
        return of({
          addr: null,
          isAdmin: false,
          isUser: false
        })
      })
    );

    // Verificar si existe una sesión activa
    this.web3Srv.checkAlreadyConnected();
  }

  async launch() { return this.web3Srv.launchAskConnectionType(); }

}
