<div class="d-md-flex align-items-center">

  <div class="code-qr">
    <ng-container *ngIf="(userDoc$ | async) as info; else renderLoaderQr">
      <ng-container 
        *ngIf="info.exist"
        [ngTemplateOutlet]="renderQrCode" [ngTemplateOutletContext]="{code: info.nickname}"
      ></ng-container>
    </ng-container>
  </div>

  <div class="info d-flex justify-content-between flex-column">
    <div class="dec"></div>
    <div class="pe-md-5 text-medium">
      <div>
        {{ "qr-code" | translate }}
      </div>

      <ng-container *ngIf="(userDoc$ | async) as info; else renderLoaderButton">
        <ng-container 
          *ngIf="info.exist"
          [ngTemplateOutlet]="renderButton" [ngTemplateOutletContext]="{code: info.nickname}"
        ></ng-container>
      </ng-container>

    </div>
  </div>
</div>

<ng-template #renderButton let-code="code">
  <button (click)="copy(code)" class="btn-five fw-bold mt-3 p-3">
    {{ "home-dashboard.qr.invitation" | translate }}
  </button>
</ng-template>

<ng-template #renderQrCode let-code="code">
  <qrcode [qrdata]="invitationLink(code)" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
</ng-template>

<ng-template #renderLoaderButton>
  <button class="btn-five fw-bold mt-3 p-3" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Loading...
  </button>
</ng-template>

<ng-template #renderLoaderQr>
  <div style="width: 200px;">
    <div class="spinner-border text-light m-5" style="width: 3rem; height: 3rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
