import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-roadmap-landing",
  templateUrl: "./roadmap-landing.component.html",
  styleUrls: ["./roadmap-landing.component.css"],
})
export class RoadmapLandingComponent implements OnInit {
  data: any = [
    {
      class: "one",
      icon: "assets/img/icon-dolar.png",
      title: "Step - 01",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
      year: "2023",
      textTwo:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
    {
      class: "two",
      icon: "assets/img/icon-dolar.png",
      title: "Step - 02",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
      year: "2024",
      textTwo:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },

    {
      class: "three",
      icon: "assets/img/icon-dolar.png",
      title: "Step - 03",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
      year: "2025",
      textTwo:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
    {
      class: "four",
      icon: "assets/img/icon-dolar.png",
      title: "Step - 04",
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
      year: "2025",
      textTwo:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
